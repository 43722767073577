import axios from "axios";
import { API_URL } from "../configs/app";
import { setHeader } from "../utils/helpers";


export const getUserAddress = async () => {
    let header = await setHeader();
    let res = await axios.get(`${API_URL}/user/address/`, header)
    return res.data
}

export const removeUserAddress = async (id) => {
    let header = await setHeader();
    let res = await axios.post(`${API_URL}/user/remove-address/`, {id}, header)
    return res.data
}

export const getUser = async () => {
    let header = await setHeader();
    let res = await axios.get(`${API_URL}/user/`, header)
    return res.data
}

export const updateUser = async (data) => {
    let header = await setHeader();
    let res = await axios.post(`${API_URL}/user/update`, data, header)
    return res.data
}

export const forgotPassword = async (data) => {
    let res = await axios.post(`${API_URL}/user/forgot-password`, data)
    return res.data
}

export const checkOTP = async ({queryKey}) => {
    let res = await axios.get(`${API_URL}/user/check-otp/${queryKey[1]}/${queryKey[2]}`)
    return res.data
}

export const resetPassword = async (data) => {
    let res = await axios.post(`${API_URL}/user/reset-password`, data)
    return res.data
}