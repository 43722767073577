import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setDetails } from '../../store/slices/adminSlice';
import { cookieRemove } from '../../utils/cookie';

function Logout() {
const history = useNavigate();
let dispatch = useDispatch()
  const handleLogout = async () => {
    await cookieRemove('adminToken');
    dispatch(setDetails(null))
    history('/admin/login')
  }
  useEffect(() => {
    handleLogout();
  }, [])
  
  return <></>
}

export default Logout