import axios from "axios";
import { API_URL } from "../configs/app";


export const initializePayment = async data => {
    console.log("got here1")
    let res = await axios.post(`${API_URL}/payment/initialize`, data)
    return res.data
}

export const verifyPayment = async data => {
        const resp = await axios.post(`${API_URL}/payment/verify`, data)
        return resp.data
}

export const verifySquadPayment = async ({queryKey}) => {
    let response = await axios.get(`${API_URL}/payment/squad/verify/${queryKey[1]}`)
    return response.data
}

export const verifyBaniPayment = async ({queryKey}) => {
    let response = await axios.get(`${API_URL}/payment/bani/verify/${queryKey[1]}`)
    return response.data
}