import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useState } from "react";
import { Badge, Button, Form, Spinner } from "react-bootstrap";
import { getOutletOrders } from "../../api/admin.api";
import AdminLayout from "../../layouts/AdminLayout";
import {
  displayAmount,
  formatStatus,
  formatStatusBadge,
  makeTitleCase,
  parseTimeDate,
  showAlert,
} from "../../utils/helpers";
import moment from "moment";
import ViewOrderModal from "../../components/Modals/ViewOrderModal";
import { useSearchParams } from "react-router-dom";

const OutletOrders = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [orders, setOrders] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showViewModal, setShowViewModal] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  // const [period, setPeriod] = useState("today")
  const [period, setPeriod] = useState(searchParams.get("period") || "today")
  const [paymentStatus, setPaymentStatus] = useState("success")

  let {
    data,
    isLoading: isGettingOrders,
    status,
  } = useQuery(["outletOrders", page, limit, period, paymentStatus], getOutletOrders, {
    onSuccess: (data) => {
      if (!data.status)
        return showAlert({
          title: "Oops!",
          msg: typeof data.errors[0].msg == "string"
          ? data.errors[0].msg
          : data.errors[0].msg.message,
          icon: "error",
          btnText: "Close",
        });
      setOrders(data?.value?.orders);
    },
    onError: err => showAlert({
      title: "Error!",
      msg: "An internal error has occurred",
      icon: "error",
      btnText: "Close",
    })
  });
  const handleView = (order) => {
    setSelectedProduct(order);
    setShowViewModal(true);
  };
  const handlePagination = (type) => {
    // e.preventDefault()
    if (type === "prev") {
      setPage((prev) => Math.ceil(Math.max(prev - 1, 1)));
    } else if (type === "next") {
      setPage((prev) => prev + 1);
    } else {
      setPage(type);
    }
  };

  const handleClose = (type) => {
    // refetch();
    switch (type) {
      case "view":
        setShowViewModal(false);
        setSelectedProduct(null);
        break;
      default:
        return;
    }

  };

  const handlePeriodChange = (e) => {
    setPeriod(e.target.value)
  }
  return (<>
    <AdminLayout>
      <div className="container-fluid">
        {/* Page Heading */}
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Customers Orders</h1>
          <div className="d-flex">
          <Form.Group className="mb-3" controlId="formSelect">
            <Form.Select
              aria-label="filter-duration"
              onChange={handlePeriodChange}
              value={period}
              // style={{minHeight:100}}
            >
               {/* <option selected disabled value="">Filter</option> */}
              <option value="today">Today</option>
              <option value="yesterday">Yesterday</option>
              <option value="current_week">Current Week</option>
              <option value="last_week">Last Week</option>
              <option value="month">This Month</option>
              <option value="last_month">Last Month</option>
              <option value="custom">All</option>
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3 ml-3" controlId="formSelect">
            <Form.Select
              aria-label="filter-status"
              onChange={(e)=>setPaymentStatus(e.target.value)}
              value={paymentStatus}
              // style={{minHeight:100}}
            >
              <option value="success">Successful</option>
              <option value="pending">Pending</option>
            </Form.Select>
          </Form.Group>
          </div>
        </div>

        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary">All Orders</h6>
          </div>
          <div className="card-body">
            <div className="table-responsive">
            {status === "success" && data && (
                <div className="row my-3">
                  <div className="col-sm-12 col-md-5">
                    <div
                      className="dataTables_info"
                      id="dataTable_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing {limit * (page - 1) + 1} to{" "}
                      {page * limit < data?.value?.totalDocument
                        ? page * limit
                        : data?.value?.totalDocument}{" "}
                      of {data?.value?.totalDocument} orders
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-7">
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="dataTable_paginate"
                    >
                      <ul className="pagination" style={{float:"right"}}>
                        <li
                          className={`paginate_button page-item previous ${
                            page === 1 ? "disabled" : ""
                          }`}
                          id="dataTable_previous"
                        >
                          <a
                            href="#"
                            className="page-link"
                            onClick={() => handlePagination("prev")}
                          >
                            Previous
                          </a>
                        </li>
                        {[...Array(data?.value?.totalPages)].map(
                          (val, index) => (
                            <li
                              className={`paginate_button page-item  ${
                                page == index + 1 ? "active" : ""
                              }`}
                            >
                              <a
                                onClick={() => handlePagination(index + 1)}
                                className="page-link"
                              >
                                {index + 1}
                              </a>
                            </li>
                          )
                        )}
                        <li
                          className={`paginate_button page-item next ${
                            page * limit > data?.value?.totalDocument
                              ? "disabled"
                              : ""
                          }`}
                          id="dataTable_next"
                        >
                          <a
                            onClick={() => handlePagination("next")}
                            className="page-link"
                          >
                            Next
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
              <table
                className="table table-bordered center" style={{textAlign:"center"}}
                id="dataTable"
                width="100%"
                cellSpacing={0}
              >
                <thead>
                  <tr>
                    <th>Order#</th>
                    <th>Name</th>
                    <th>Items</th>
                    <th>Amount</th>
                    <th>Order Status</th>
                    <th>Created</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tfoot>
                  <tr>
                    <th>Order#</th>
                    <th>Name</th>
                    <th>Items</th>
                    <th>Amount</th>
                    <th>Order Status</th>
                    <th>Created</th>
                    <th>Action</th>
                  </tr>
                </tfoot>
                <tbody>
                  {isGettingOrders && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: 100,
                      }}
                    >
                      <Spinner animation="border" size="sm" />{" "}
                      Loading...
                    </div>
                  )}

                  {!isGettingOrders && orders.length > 0 && (
                    <>
                      {orders.map((order, index) => (
                        <tr
                          key={`${Math.floor(Math.random() * 10000)}-${index}`}
                        >
                          <td>{order?.orderNumber}</td>
                          <td>
                            {" "}
                            {order?.customer?.name
                              ? makeTitleCase(order?.customer?.name)
                              : ""}
                          </td>
                          <td>
                            {order?.items.length}{" "}
                            {order?.items.length > 1 ? "items" : "item"}
                          </td>
                          <td>
                            <strong>{displayAmount(order?.sumTotal)}</strong>
                            {order?.oid.substr(0,6) === "MOBILE" && <sup><i title="APP Purchase" className="fa fa-mobile-alt text-primary ml-1"></i></sup>}

{order?.hasBlackFridayOffer && <sup><i title="Black Friday" className="fa fa-rocket text-black text-dark ml-1"></i></sup>}

                          </td>
                  
                          <td>
                            <Badge
                              bg={formatStatusBadge(order?.orderStatus)}
                            >
                              {formatStatus(order?.orderStatus)}
                            </Badge>
                          </td>
                          <td>
                            {/* {new Date(order?.created).toDateString()}
                            {parseTimeDate(order?.created)} */}
                            {moment(order?.created).format(
                              "MMM Do YYYY, h:mm A"
                            )}
                          </td>
                          {/* <td>{new Date(order?.updated).toDateString()} {parseTimeDate(order?.updated)}</td> */}
                          <td
                            style={{
                              display: "flex",
                              aligItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Button
                              size="sm"
                              onClick={() => handleView(order)}
                              variant="outline-primary"
                            >
                              <i className="fas fa-eye"></i>
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                </tbody>
              </table>

              {status === "success" && data && (
                <div className="row">
                  <div className="col-sm-12 col-md-5">
                    <div
                      className="dataTables_info"
                      id="dataTable_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing {limit * (page - 1) + 1} to{" "}
                      {page * limit < data?.value?.totalDocument
                        ? page * limit
                        : data?.value?.totalDocument}{" "}
                      of {data?.value?.totalDocument} orders
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-7">
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="dataTable_paginate"
                    >
                      <ul className="pagination" style={{float:"right"}}>
                        <li
                          className={`paginate_button page-item previous ${
                            page === 1 ? "disabled" : ""
                          }`}
                          id="dataTable_previous"
                        >
                          <a
                            href="#"
                            className="page-link"
                            onClick={() => handlePagination("prev")}
                          >
                            Previous
                          </a>
                        </li>
                        {[...Array(data?.value?.totalPages)].map(
                          (val, index) => (
                            <li
                              className={`paginate_button page-item  ${
                                page == index + 1 ? "active" : ""
                              }`}
                            >
                              <a
                                onClick={() => handlePagination(index + 1)}
                                className="page-link"
                              >
                                {index + 1}
                              </a>
                            </li>
                          )
                        )}
                        <li
                          className={`paginate_button page-item next ${
                            page * limit > data?.value?.totalDocument
                              ? "disabled"
                              : ""
                          }`}
                          id="dataTable_next"
                        >
                          <a
                            onClick={() => handlePagination("next")}
                            className="page-link"
                          >
                            Next
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
     {selectedProduct !== null && (
        <ViewOrderModal
          order={selectedProduct}
          show={showViewModal}
          close={() => handleClose("view")}
        />
      )}
    </>
  );
};

export default OutletOrders;
