import { useMutation } from '@tanstack/react-query';
import React from 'react'
import { Modal, Row, Col, Button } from "react-bootstrap";
import { deleteProduct } from '../../api/admin.api';
import { displayAmount, makeTitleCase, showAlert } from '../../utils/helpers';

const DeleteProductModal = ({ show, close, product }) => {
    const {mutate, isLoading, } = useMutation(deleteProduct, {
        enabled:false.valueOf,
        onSuccess: data => {
            if (!data.status) {
                return showAlert({
                  title: "Error!",
                  msg:
                    typeof data.errors[0].msg == "string"
                      ? data.errors[0].msg
                      : data.errors[0].msg.message,
                  icon: "error",
                  btnText: "Close",
                });
              }
              close();
            return showAlert({
                title: "Delete Successful",
                msg: `${makeTitleCase(product?.name)} has been deleted successfully`,
                icon: "success",
                btnText: "Close",
              });
        },
        onError: err=> {
            return showAlert({
                title: "Error!",
                msg: "An internal error has occured",
                icon: "error",
                btnText: "Close",
              });
        }
    })
  return (
    <Modal show={show} onHide={close} keyboard={false} size={"lg"} centered>
      <Modal.Header closeButton>
        <Modal.Title>Are you sure you want to delete?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={4}>
            <div
              style={{
                backgroundImage: `url(${product.image_url})`,
                width: 200,
                height: 200,
                borderRaduis: 20,
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            ></div>
          </Col>
          <Col>
            <div>
              <h3>
                {product.is_promo && product.sale_price !== null ? (
                  <>
                    <span
                      style={{
                        textDecorationLine: "line-through",
                        color: "#ff000066",
                      }}
                    >
                      {displayAmount(product.product_price)}
                    </span>
                    <span style={{ fontWeight: "bold" }}>
                      {" "}
                      {displayAmount(product.sale_price)}
                    </span>
                  </>
                ) : (
                  displayAmount(product.product_price)
                )}
              </h3>
            </div>
            <p>{product.description}</p>
            <div>
              {product.available ? (
                <span style={{ color: "green" }}>On sale</span>
              ) : (
                <span style={{ color: "red" }}>Unavailable</span>
              )}
            </div>
            <div>Category: {makeTitleCase(product.category.toString())}</div>
            <div>Status: {product.status}</div>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={close}>
          Cancel
        </Button>
        <Button
          onClick={()=>mutate(product)}
          type="submit"
          variant="danger"
          disabled={isLoading}
        >
          {isLoading ? "Deleting..." : "Yes Delete it!"}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default DeleteProductModal