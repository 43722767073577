import axios from "axios";
import { API_URL } from "../configs/app";

export const getStates = async () => {
  let res = await axios.get(`${API_URL}/state/`);
  return res.data;
};

export const getOutlets = async () => {
  let res = await axios.get(`${API_URL}/outlet/`);
  return res.data;
};

export const getProductCategories = async () => {
  let res = await axios.get(`${API_URL}/product/category`);
  return res.data;
};

export const getOutletsByState = async ({ queryKey }) => {
  // if(queryKey[1]){
  let res = await axios.get(`${API_URL}/outlet/state/${queryKey[1]}`);
  // console.log(res.data)
  return res.data;
  // }
};

export const storeNotifToken = async (data) => {
  try {
    const res = await axios.post(`${API_URL}/notification/store`, data);
    return res.data;
  } catch (err) {
    return {
      error: true,
      msg: err,
    };
  }
};


export const getLocations = async () => {
  let res = await axios.get(`${API_URL}/outlet/all-locations`);
  return res.data;
};
