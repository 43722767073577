import React from 'react'
import { displayAmount, makeTitleCase } from '../../utils/helpers'

const MenuItem = ({item}) => {
  return (
    <div className="col-lg-4 col-md-6 col-sm-6 grid-item mb-30">
    <div className="shop_single white_bg" style={{paddingTop:5}}>
      <div className="content" style={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>
        <div><h3 className="title">{makeTitleCase(item?.name)}</h3>
        {item?.meta && <span style={{fontSize:16}}>{item?.meta}</span>}
        </div>
        <div className="s_bottom ul_li">
          <span style={{fontSize:18}} className="new">{displayAmount(item.amount,2)}</span>
        </div>
      </div>
    </div>
  </div>
  )
}

export default MenuItem