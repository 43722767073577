import { useMutation, useQuery } from "@tanstack/react-query";
import React, {useState} from "react";
import { Badge, Form, Spinner } from "react-bootstrap";
import { getAllCustomers, getAllUsers, getUsersSpend } from "../../api/admin.api";
import AdminLayout from "../../layouts/AdminLayout";
import { customExportToExcel, displayAmount, makeTitleCase, showAlert } from "../../utils/helpers";
import moment from "moment";
import { useEffect } from "react";
import { SITE_URL } from "../../configs/app";

const Customers = () => {
  const [users, setUsers] = useState(null)
  const [userSpend, setUserSpend] = useState([])
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [filter, setFilter] = useState("count")
  const [period, setPeriod] = useState("all_time");

  const {isLoading, status} = useQuery(['customers', page, limit, filter, period ], getAllCustomers, {
    onSuccess:data=>{
      
      if(!data.status)return showAlert({
        title: "Oops!",
        msg: typeof data.errors[0].msg == "string"
        ? data.errors[0].msg
        : data.errors[0].msg.message,
        icon: "error",
        btnText: "Close",
      });
      // console.log(data.value)
      setUsers(data?.value)
    },
    onError: err => showAlert({
      title: "Error!",
      msg: "An internal error has occurred",
      icon: "error",
      btnText: "Close",
    })
  })

  const handlePagination = (type) => {
    setUserSpend([])
    // e.preventDefault()
    if (type === "prev") {
      setPage((prev) => Math.ceil(Math.max(prev - 1, 1)));
    } else if (type === "next") {
      setPage((prev) => prev + 1);
    } else {
      setPage(type);
    }
  };

  const {mutate, isLoading:loadingUserSpend} = useMutation(getUsersSpend, {
    onSuccess:data=>{
      if(!data.status)return showAlert({
        title: "Oops!",
        msg: typeof data.errors[0].msg == "string"
        ? data.errors[0].msg
        : data.errors[0].msg.message,
        icon: "error",
        btnText: "Close",
      });
      setUserSpend(data.value)
    },
    onError: err => showAlert({
      title: "Error!",
      msg: "An internal error has occurred",
      icon: "error",
      btnText: "Close",
    })
  })

  useEffect(() => {
   if(users){
    mutate(users?.users)
   }
  }, [users])

  const calcOrder = id => userSpend.find(item=>item.id===id)
  

  return (
    <AdminLayout>
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Customers</h1>
          <div style={{display:"flex"}}>
          <Form.Group className="mb-3 mr-3" controlId="formSelect">
            <Form.Select
              aria-label="filter"
              onChange={(e)=>setFilter(e.target.value)}
              value={filter}
              // style={{minHeight:100}}
            >
               <option  selected disabled value="">Sort by</option>
              <option value="count">Number of orders</option>
              <option value="spend">Total Spend</option>
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formSelect">
            <Form.Select
              aria-label="filter-duration"
              onChange={(e)=> setPeriod(e.target.value)}
              value={period}
              // style={{minHeight:100}}
            >
               <option  selected disabled value="">Filter by Period</option>
              <option value="current_month">Current Month</option>
              <option value="last_month">Last Month</option>
              <option value="all_time">All Time</option>
            </Form.Select>
          </Form.Group>
        </div>
        </div>

        <div className="row">
          <div className="card shadow mb-4">
            <div className="card-header py-3"  style={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>
              <h6 className="m-0 font-weight-bold text-primary">Customers</h6>
              {status === "success" && users && 
              <button className="btn btn-sm btn-primary" 
              // onClick={()=>console.log(users)}
              onClick={()=>customExportToExcel({data:users.users, name:`customers_data_${new Date().getTime()}`})}
              >
              <i className="fa fa-file-excel"></i>{" "}
              Export
            </button>
            } 
            </div>
            <div className="card-body">
              <div className="table-responsive">
              {status === "success" && users && (
                <div className="row my-3">
                  <div className="col-sm-12 col-md-5">
                    <div
                      className="dataTables_info"
                      id="dataTable_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing {limit * (page - 1) + 1} to{" "}
                      {page * limit < users?.totalDocument
                        ? page * limit
                        : users?.totalDocument}{" "}
                      of {users?.totalDocument} customers
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-7">
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="dataTable_paginate"
                    >
                      <ul className="pagination" style={{float:"right", overflow:"scroll"}}>
                        <li
                          className={`paginate_button page-item previous ${
                            page === 1 ? "disabled" : ""
                          }`}
                          id="dataTable_previous"
                        >
                          <a
                            href="#"
                            className="page-link"
                            onClick={() => handlePagination("prev")}
                          >
                            Previous
                          </a>
                        </li>
                        {[...Array(users?.totalPages)].map(
                          (val, index) => {
                            if(!(index < (page - 5)) && !(index > (page + 5))) return (
                              <li
                                className={`paginate_button page-item  ${
                                  page == index + 1 ? "active" : ""
                                }`}
                              >
                                <a
                                  onClick={() => handlePagination(index + 1)}
                                  className="page-link"
                                >
                                  {index + 1}
                                </a>
                              </li>
                            )
                          }
                        )}
                        <li
                          className={`paginate_button page-item next ${
                            page * limit > users?.totalDocument
                              ? "disabled"
                              : ""
                          }`}
                          id="dataTable_next"
                        >
                          <a
                            onClick={() => handlePagination("next")}
                            className="page-link"
                          >
                            Next
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
                <table className="table table-hover table-stripped table-bordered table-center" style={{textAlign:"center"}}>
                <thead>
                  <tr>
                    <th>S/N</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Number of Orders</th>
                    <th>Total Spend</th>
                    {/* <th>Joined</th> */}
                    <th>Last Purchase</th>
                  </tr>
                </thead>
                <tfoot>
                  <tr>
                    <th>S/N</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Number of Orders</th>
                    <th>Total Spend</th>
                    {/* <th>Joined</th> */}
                    <th>Last Purchase</th>
                  </tr>
                </tfoot>

                    <tbody>
                    {isLoading && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: 100,
                      }}
                    >
                      <Spinner animation="border" size="sm" />{" "}
                      Loading...
                    </div>
                  )}
                   {!isLoading && users && users?.users.map((user, index)=>(
                    <tr key={Math.floor(Math.random()*9999)}>
                        <td>{(limit * (page - 1) + 1) + index }</td>
                        <td><a style={{color:"#062e99"}} href={`${SITE_URL}/admin/user-profile?phone=${user?.phone}`} target="_blank">{makeTitleCase(user?.name)} </a></td>
                        <td>{user?.email}</td>
                        <td>{user?.phone}</td>
                        <td>{user?.orderCount}</td>
                        <td>{displayAmount(user?.orderTotal)}</td>
                        {/* <td>{userSpend.length>0 && !loadingUserSpend?calcOrder(user._id).orderCount:<Spinner size="sm" animation="border"/>}</td>
                        <td>{userSpend.length && !loadingUserSpend>0?displayAmount(calcOrder(user._id).orderTotal):<Spinner size="sm" animation="border"/>}</td> */}
                        {/* <td style={{textAlign:"center"}} > <Badge
                              bg={user?.gender === "Male"?"primary":"info"}
                            >
                              {user?.gender}
                            </Badge>
                        </td> */}
                        {/* <td>
                        {moment(user?.created).format(
                              "MMM Do YYYY"
                            )}

                        </td> */}
                        <td>
                        {moment(user?.last_active).format(
                              "MMM Do YYYY"
                            )}

                        </td>
                    </tr>
                   ))}
                      
                    </tbody>
                </table>
                {status === "success" && users && (
                <div className="row my-3">
                  <div className="col-sm-12 col-md-5">
                    <div
                      className="dataTables_info"
                      id="dataTable_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing {limit * (page - 1) + 1} to{" "}
                      {page * limit < users?.totalDocument
                        ? page * limit
                        : users?.totalDocument}{" "}
                      of {users?.totalDocument} customers
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-7">
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="dataTable_paginate"
                    >
                      <ul className="pagination" style={{float:"right"}}>
                        <li
                          className={`paginate_button page-item previous ${
                            page === 1 ? "disabled" : ""
                          }`}
                          id="dataTable_previous"
                        >
                          <a
                            href="#"
                            className="page-link"
                            onClick={() => handlePagination("prev")}
                          >
                            Previous
                          </a>
                        </li>
                        {[...Array(users?.totalPages)].map(
                          (val, index) =>{
                            if(!(index < (page - 5)) && !(index > (page + 5))) return (
                              <li
                                className={`paginate_button page-item  ${
                                  page == index + 1 ? "active" : ""
                                }`}
                              >
                                <a
                                  onClick={() => handlePagination(index + 1)}
                                  className="page-link"
                                >
                                  {index + 1}
                                </a>
                              </li>
                            )
                          } 
                        )}
                        <li
                          className={`paginate_button page-item next ${
                            page * limit > users?.totalDocument
                              ? "disabled"
                              : ""
                          }`}
                          id="dataTable_next"
                        >
                          <a
                            onClick={() => handlePagination("next")}
                            className="page-link"
                          >
                            Next
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default Customers;
