import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useState, useEffect } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { createCouponCode } from "../../api/admin.api";
import {  makeTitleCase, showAlert } from "../../utils/helpers";

const CreateCouponModal = ({show, close }) => {
  const initialState = {};
  const [formdata, setFormdata] = useState(initialState);


  const handleChange = (field, value) => {
    if (field === "active") {
        return  setFormdata((prevState) => ({
              ...prevState,
              [field]: value.checked,
            }));
      }
    setFormdata((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const {mutate, isLoading} = useMutation(createCouponCode, {
    onSuccess: data=>{
        // console.log(data)
        if(!data.status){
            return showAlert({
                title: "Error!",
                msg:
                  typeof data.errors[0].msg == "string"
                    ? data.errors[0].msg
                    : data.errors[0].msg.message,
                icon: "error",
                btnText: "Close",
              });
        }
        close()
        setFormdata(initialState)
        return showAlert({
          title: "Coupon Created!",
          msg: `Coupon "${makeTitleCase(formdata.title)}" has been created successfully`,
          icon: "success",
          btnText: "Close",
        });
       
    },
    onError: err=> {
        return showAlert({
            title: "Error!",
            msg: "An internal error has occured",
            icon: "error",
            btnText: "Close",
          });
    }
  })

  const handleSubmit = (e) => {
    e.preventDefault();
    mutate(formdata);    
  };

  return (
    <Modal
      show={show}
      onHide={close}
      //   backdrop="static"
      keyboard={false}
      size={"md"}
    >
      <Modal.Header closeButton>
        <Modal.Title>Create a new Coupon Code</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicName">
            <Form.Label>Coupon Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Title for this coupon campaign"
              onChange={(e) => handleChange("title", e.target.value)}
              value={formdata.title}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicName">
            <Form.Label>Coupon Code</Form.Label>
            <Form.Control
              type="text"
              placeholder="welcome20    "
              onChange={(e) => handleChange("couponCode", e.target.value)}
              value={formdata.couponCode}
            />
          </Form.Group>

          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formSelect">
                <Form.Label>Coupon Type</Form.Label>
                <Form.Select
                  aria-label="Coupon Type"
                  onChange={(e) => handleChange("couponType", e.target.value)}
                  value={formdata.couponType}
                  style={{maxHeight:38}}
                >
                  <option disabled selected value={null}> Select Type  </option>
                  <option value={1}>Percentage of Purchase</option>
                  <option value={2}>Fixed Amount</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="formSelect">
                <Form.Label>Coupon Value</Form.Label>
                <Form.Control
                    type="number"
                    placeholder=""
                    onChange={(e) => handleChange("couponValue", e.target.value)}
                    value={formdata.couponValue}
                    disabled={!formdata?.couponType}
                    />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Check
                 type="switch"
                id="active-checkbox"
                label="Activate"
                checked={ formdata.active }
                onChange={(e) => handleChange("active", e.target)}
              />
               <Form.Text className="text-muted">
      <small>Switch ON to make this coupon code active immediately</small>
    </Form.Text> 
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formSelect">
                <Form.Label>Validity Type</Form.Label>
                <Form.Select
                  aria-label="Validity Type"
                  onChange={(e) => handleChange("validityType", e.target.value)}
                  value={formdata.validityType}
                  style={{maxHeight:38}}
                >
                  <option disabled selected value={null}> Select Validity </option>
                  <option value={1}>Usage Count</option>
                  <option value={2}>Fixed Date</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="formSelect">
                <Form.Label>Validity Value</Form.Label>
                <Form.Control
                    type={formdata?.validityType == 1?"number":formdata?.validityType==2?"date":"text"}
                    placeholder=""
                    disabled={!formdata?.validityType}
                    onChange={(e) => handleChange("validityValue", e.target.value)}
                    value={formdata.validityValue}
                    />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formPrice">
                <Form.Label>Minimum Spend</Form.Label>
                <Form.Control
                  type="number"
                  placeholder=""
                  onChange={(e) => handleChange("minimumSpend", e.target.value)}
                  value={formdata.minimumSpend}
                />
                  <Form.Text className="text-muted">
                  <small>Only purchases up to this amount will be able to use this coupon code</small>
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
     
        
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={close}>
          Close
        </Button>
        <Button
          onClick={handleSubmit}
          type="submit"
          variant="primary"
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              <Spinner animation="border" size="sm" color="#fff" />{" "}
              Creating...Plese Wait"
            </>
          ) : (
            "Create Coupon Code"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateCouponModal;
