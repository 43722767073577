import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userInfo:null,
  userRef: null
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    setUserRef: (state, action) => {
      state.userRef = action.payload;
    },
  },
});

export const {
    setUserInfo, setUserRef
} = userSlice.actions;
export default userSlice.reducer;
