import { useMutation, useQuery } from '@tanstack/react-query'
import React from 'react'
import { useState } from 'react'
import { Button, Form, Spinner } from 'react-bootstrap'
import { deleteCouponCode, getCouponCode, updateCoupon } from '../../api/admin.api'
import CreateCouponModal from '../../components/Modals/CreateCouponModal'
import AdminLayout from '../../layouts/AdminLayout'
import { displayAmount, makeTitleCase, showAlert } from '../../utils/helpers'
import moment from "moment";

const Coupon = () => {
    const [showCreateModal, setShowCreateModal] = useState(false)
    const [coupons, setCoupons] = useState([])
    const [formdata, setFormdata] = useState([])
    const [hasChanges, setHasChanges] = useState(false)
    const handleClose = (type) => {
        refetch()
        switch (type) {
          case "create": setShowCreateModal(false);
            break;
          default:
            return;
        }
      };


  const {isLoading:gettingCoupons, refetch} = useQuery(["coupons"], getCouponCode, {
    onSuccess: data=>{
        // console.log(data)
        if(!data.status){
            return showAlert({
                title: "Error!",
                msg: typeof data.errors[0].msg == "string"
                    ? data.errors[0].msg
                    : data.errors[0].msg.message,
                icon: "error",
                btnText: "Close",
              });
        }
        setFormdata(data?.value)  
        setCoupons(data?.value)  
    },
    onError: err=> {
        return showAlert({
            title: "Error!",
            msg: "An internal error has occured",
            icon: "error",
            btnText: "Close",
          });
    }
  })

  const {mutate} = useMutation(deleteCouponCode, {
    onSuccess: data=>{
        // console.log(data)
        if(!data.status){
            return showAlert({
                title: "Error!",
                msg: typeof data.errors[0].msg == "string"
                    ? data.errors[0].msg
                    : data.errors[0].msg.message,
                icon: "error",
                btnText: "Close",
              });
        }
        return showAlert({
            title: "Success!",
            msg: "Coupon has been deleted successfully",
            icon: "success",
            btnText: "Close",
          });  
    },
    onError: err=> {
        return showAlert({
            title: "Error!",
            msg: "An internal error has occured",
            icon: "error",
            btnText: "Close",
          });
    }
  })
  const handleDelete = (item) => {
    console.log(item)
    let conf = window.confirm("Are you sure");
    if(!conf)return;
    mutate(item._id)

  }
  const handleChange = (id, val)=> {
    let otherCoupon = formdata.filter(item=>item._id!==id)
    let coupon = formdata.find(item=>item._id===id);
    coupon = {
        ...coupon,
        active: val
    }
    setHasChanges(true)
   return  setFormdata([coupon, ...otherCoupon])
}

const {mutate:updateCouponStatus, isLoading:isUpdating, status} = useMutation(updateCoupon, {
    onSuccess: data=>{
        if(!data.status){
            return showAlert({
                title: "Error!",
                msg: typeof data.errors[0].msg == "string"
                    ? data.errors[0].msg
                    : data.errors[0].msg.message,
                icon: "error",
                btnText: "Close",
              });
        }
        return showAlert({
            title: "Success!",
            msg: "Update successful",
            icon: "success",
            btnText: "Close",
          });  
    },
    onError: err=> {
        return showAlert({
            title: "Error!",
            msg: "An internal error has occured",
            icon: "error",
            btnText: "Close",
          });
    }
})
const handleSubmit = (e) => {
    e.preventDefault()
    updateCouponStatus(formdata)

}
  return (
    <AdminLayout>
         <div className="container-fluid">
          <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 className="h3 mb-0 text-gray-800">Coupon Codes</h1>
            <div style={{display:"flex", justifyContent:"space-around"}}>
            <Button onClick={handleSubmit} disabled={!hasChanges || isUpdating} className="mr-3" variant='success' size='md'>{isUpdating?"Saving...":"Save Changes"}</Button>
            <Button onClick={()=>setShowCreateModal(true)} variant='primary' size='md'>Create New Coupon Code</Button>
            </div>
          </div>

        <div className="card shadow mb-4">
        <div className="card-body">
          <div className="table-responsive">

          <table
               className="table table-bordered text-center"
               id="dataTable"
               width="100%"
               cellSpacing={0}
             >
               <thead>
                 <tr>
                   <th>S/N</th>
                   <th>Title</th>
                   <th>Code</th>
                   <th>Value</th>
                   <th>Minimum Spend</th>
                   <th>Created</th>
                   <th>Actions</th>
                 </tr>
               </thead>
               <tfoot>
                 <tr>
                 <th>S/N</th>
                   <th>Title</th>
                   <th>Code</th>
                   <th>Value</th>
                   <th>Minimum Spend</th>
                   <th>Created</th>
                   <th>Actions</th>
                 </tr>
               </tfoot>

               <tbody>
               {gettingCoupons && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: 100,
                      }}
                    >
                      <Spinner animation="border" size="sm" />{" "}
                      Loading...
                    </div>
                  )}

                  { !gettingCoupons && formdata.length > 0 && formdata?.map(
                    (item, index)=><tr key={Math.floor(Math.random()*99999)}>
                        <td>{index + 1}</td>
                        <td>{makeTitleCase(item?.title)}</td>
                        <td>{item?.couponCode}</td>
                        <td>{item?.couponType==1?`${item?.couponValue}%`:item?.couponType==2?displayAmount(item?.couponValue):0}</td>
                        <td>{displayAmount(item?.minimumSpend)}</td>
                        <td> {moment(item?.created).format(
                              "MMM Do YYYY, h:mm A"
                            )}</td>
                        <td style={{display:"flex", justifyContent:"space-around"}}>
                        <Form.Check
                 type="switch"
                id={`checkbox-active-${index}`}
                label=""
                checked={ item.active }
                onChange={(e) => handleChange(item._id, e.target.checked)}
              />

                        <Button size="sm" onClick={()=>handleDelete(item)} variant="outline-danger"><i className="fas fa-trash-alt"></i></Button>
                        </td>
                    </tr>
                  )

                  }

               </tbody>
               </table>
          </div>
        </div>
        </div>

        </div>

        {showCreateModal && <CreateCouponModal show={showCreateModal} close={()=>handleClose("create")}/>}
    </AdminLayout>
  )
}

export default Coupon