import React, { useState } from "react";
import AdminLayout from "../../layouts/AdminLayout";
import { Button, Form, Spinner, Badge } from "react-bootstrap";
import moment from "moment";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  createFailedTransaction,
  getFailedTransactions,
} from "../../api/admin.api";
import {
  formatTransactionStatusBadge,
  makeTitleCase,
  showAlert,
} from "../../utils/helpers";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import ViewTransactionModal from "../../components/Modals/ViewTransactionModal";

const FailedTransactions = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [status, setStatus] = useState(searchParams.get("status"));
  const [page, setPage] = useState(1);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [showViewModal, setShowViewModal] = useState(false);
  const [limit, setLimit] = useState(20);
  const [transactions, setTransactions] = useState([]);
  const { details } = useSelector((state) => state.admin);

  const initialState = {
    customerName: "",
    accountNumber: "",
    amount: undefined,
    paymentDate: undefined,
    phone: "",
  }
  const [formData, setFormData] = useState(initialState);

  let isAgent = details?.username === "ecommerce" ? true : false;

  const handleSubmit = (e) => {
    e.preventDefault();
    mutate(formData);
    resetForm()
  };

  function resetForm() {
    setFormData(initialState);
  }

  const updateTransactionStatus = (id, newStatus) => {
    const updatedTransactions = transactions.map((transaction) => {
      if (transaction._id === id) {
        return { ...transaction, status: newStatus };
      }
      return transaction;
    });
    setTransactions(updatedTransactions);
  };
  
  const handleInputChange = (field, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const { mutate, isLoading } = useMutation(createFailedTransaction, {
    onSuccess: (data) => {
      if (!data.status) {
        return showAlert({
          title: "Oops!",
          msg:
            typeof data.errors[0].msg == "string"
              ? data.errors[0].msg
              : data.errors[0].msg.message,
          icon: "error",
          btnText: "Try again",
        });
      } else {
        return showAlert({
          title: "Success!",
          icon: "success",
        });
      }
    },
    onError: (err) =>
      showAlert({
        title: "Error!",
        msg: "An internal error has occured",
        icon: "error",
        btnText: "close",
      }),
  });

  let { data, isLoading: isGettingFailedTransactions } = useQuery(
    ["failedTransactions", page, limit, status],
    getFailedTransactions,
    {
      onSuccess: (data) => {
        if (!data.status)
          return showAlert({
            title: "Oops!",
            msg:
              typeof data.errors[0].msg == "string"
                ? data.errors[0].msg
                : data.errors[0].msg.message,
            icon: "error",
            btnText: "Close",
          });

        setTransactions(data?.value);
      },
      onError: (err) =>
        showAlert({
          title: "Error!",
          msg: "An internal error has occurred",
          icon: "error",
          btnText: "Close",
        }),
    }
  );

  const handlePagination = (type) => {
    // e.preventDefault()
    if (type === "prev") {
      setPage((prev) => Math.ceil(Math.max(prev - 1, 1)));
    } else if (type === "next") {
      setPage((prev) => prev + 1);
    } else {
      setPage(type);
    }
  };

  const handleView = (transaction) => {
    setSelectedTransaction(transaction);
    setShowViewModal(true);
  };

  const handleClose = (type) => {
    // refetch();
    switch (type) {
      case "view":
        setShowViewModal(false);
        setSelectedTransaction(null);
        break;
      default:
        return;
    }
  };

  return (
    <>
      <AdminLayout>
        {!isAgent ? (
          <div className="container">
            {/* Outer Row */}
            <div className="row justify-content-center">
              <div className="col-xl-10 col-lg-12 col-md-9">
                <div className="card o-hidden border-0 shadow-lg my-5">
                  <div className="card-body p-0">
                    {/* Nested Row within Card Body */}
                    <div className="row flex items-center justify-center">
                      <div className="col-lg-6">
                        <div className="p-5">
                          <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" controlId="formName">
                              <Form.Label>Customer's name</Form.Label>
                              <Form.Control
                                type="text"
                                // placeholder="Customer's name"
                                value={formData.customerName}
                                onChange={(e) =>
                                  handleInputChange(
                                    "customerName",
                                    e.target.value
                                  )
                                }
                              />
                            </Form.Group>
                            <Form.Group
                              className="mb-3"
                              controlId="forAccountNumber"
                            >
                              <Form.Label>Account Number</Form.Label>
                              <Form.Control
                                type="text"
                                value={formData.accountNumber}
                                onChange={(e) =>
                                  handleInputChange(
                                    "accountNumber",
                                    e.target.value
                                  )
                                }
                              />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="forAmount">
                              <Form.Label>Amount</Form.Label>
                              <Form.Control
                                type="text"
                                value={formData.amount}
                                onChange={(e) =>
                                  handleInputChange("amount", e.target.value)
                                }
                              />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="forphone">
                              <Form.Label>Phone number</Form.Label>
                              <Form.Control
                                type="text"
                                value={formData.phone}
                                onChange={(e) =>
                                  handleInputChange("phone", e.target.value)
                                }
                              />
                            </Form.Group>
                            <Form.Group
                              className="mb-3"
                              controlId="forPaymentDate"
                            >
                              <Form.Label>Payment Date</Form.Label>
                              <DatePicker
                                selected={formData.paymentDate}
                                onChange={(date) =>
                                  handleInputChange("paymentDate", date)
                                }
                              />
                            </Form.Group>

                            <Button
                              variant="primary"
                              type="submit"
                              className="mt-3 thm_btn thm_btn-2 btn-block"
                              style={{ width: "100%" }}
                              disabled={isLoading}
                            >
                              {isLoading ? (
                                <>
                                  <Spinner
                                    animation="border"
                                    size="sm"
                                    color="#fff"
                                  />{" "}
                                  Loading...
                                </>
                              ) : (
                                "Submit"
                              )}
                            </Button>
                            <hr />
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="container-fluid">
            {/* Page Heading */}
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
              <h1 className="h3 mb-0 text-gray-800">Failed Transactions</h1>
              <Form.Group className="mb-3" controlId="formSelect">
                <Form.Select
                  aria-label="filter-duration"
                  onChange={handleStatusChange}
                  value={status}
                >
                  <option selected disabled value="">
                    Status
                  </option>
                  <option value="reported">Reported</option>
                  <option value="processing">Processing</option>
                  <option value="resolved">Resolved</option>
                  <option value="custom">All</option>
                </Form.Select>
              </Form.Group>
            </div>

            <div className="card shadow mb-4">
              <div className="card-header py-3">
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  {data && (
                    <div className="row my-3">
                      <div className="col-sm-12 col-md-5">
                        <div
                          className="dataTables_info"
                          id="dataTable_info"
                          role="status"
                          aria-live="polite"
                        >
                          Showing {limit * (page - 1) + 1} to{" "}
                          {page * limit < data?.value?.length
                            ? page * limit
                            : data?.value?.length}{" "}
                          of {data?.value?.length} transactions
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-7">
                        <div
                          className="dataTables_paginate paging_simple_numbers"
                          id="dataTable_paginate"
                        >
                          <ul className="pagination" style={{ float: "right" }}>
                            <li
                              className={`paginate_button page-item previous ${
                                page === 1 ? "disabled" : ""
                              }`}
                              id="dataTable_previous"
                            >
                              <a
                                href="#"
                                className="page-link"
                                onClick={() => handlePagination("prev")}
                              >
                                Previous
                              </a>
                            </li>
                            {[...Array(data?.value?.totalPages)].map(
                              (val, index) => (
                                <li
                                  className={`paginate_button page-item  ${
                                    page == index + 1 ? "active" : ""
                                  }`}
                                >
                                  <a
                                    onClick={() => handlePagination(index + 1)}
                                    className="page-link"
                                  >
                                    {index + 1}
                                  </a>
                                </li>
                              )
                            )}
                            <li
                              className={`paginate_button page-item next ${
                                page * limit > data?.value?.totalDocument
                                  ? "disabled"
                                  : ""
                              }`}
                              id="dataTable_next"
                            >
                              <a
                                onClick={() => handlePagination("next")}
                                className="page-link"
                              >
                                Next
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
                  <table
                    className="table table-bordered center"
                    style={{ textAlign: "center" }}
                    id="dataTable"
                    width="100%"
                    cellSpacing={0}
                  >
                    <thead>
                      <tr>
                        <th>No #</th>
                        <th>Customer's Name</th>
                        <th>Phone Number</th>
                        <th>Account Number</th>
                        <th>Amount</th>
                        <th>Transaction Status</th>
                        <th>Payment Date</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tfoot>
                      <tr>
                        <th>No #</th>
                        <th>Customer's Name</th>
                        <th>Phone Number</th>
                        <th>Account Number</th>
                        <th>Amount</th>
                        <th>Transaction Status</th>
                        <th>Payment Date</th>
                        <th>Action</th>
                      </tr>
                    </tfoot>
                    <tbody>
                      {isGettingFailedTransactions && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: 100,
                          }}
                        >
                          <Spinner animation="border" size="sm" /> Loading...
                        </div>
                      )}

                      {!isGettingFailedTransactions &&
                        transactions.length > 0 && (
                          <>
                            {transactions.map((transaction, index) => (
                              <tr
                                key={`${Math.floor(
                                  Math.random() * 10000
                                )}-${index}`}
                              >
                                <td>{index + 1}</td>
                                <td>
                                  {" "}
                                  {transaction?.customerName
                                    ? makeTitleCase(transaction?.customerName)
                                    : ""}
                                </td>
                                <td>{transaction.phone}</td>
                                <td>
                                  <strong>{transaction.accountNumber}</strong>
                                </td>
                                <td>{transaction.amount}</td>

                                <td>
                                  <Badge
                                    bg={formatTransactionStatusBadge(
                                      transaction?.status
                                    )}
                                  >
                                    {transaction?.status}
                                  </Badge>
                                </td>
                                <td>
                                  {moment(transaction?.paymentDate).format(
                                    "MMM Do YYYY"
                                  )}
                                </td>
                                <td
                                  style={{
                                    display: "flex",
                                    aligItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Button
                                    size="sm"
                                    onClick={() => handleView(transaction)}
                                    variant="outline-primary"
                                  >
                                    <i className="fas fa-cog"></i>
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </>
                        )}
                    </tbody>
                  </table>

                  {data && (
                    <div className="row">
                      <div className="col-sm-12 col-md-5">
                        <div
                          className="dataTables_info"
                          id="dataTable_info"
                          role="status"
                          aria-live="polite"
                        >
                          Showing {limit * (page - 1) + 1} to{" "}
                          {page * limit < data?.value?.length
                            ? page * limit
                            : data?.value?.length}{" "}
                          of {data?.value?.length} transactions
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-7">
                        <div
                          className="dataTables_paginate paging_simple_numbers"
                          id="dataTable_paginate"
                        >
                          <ul className="pagination" style={{ float: "right" }}>
                            <li
                              className={`paginate_button page-item previous ${
                                page === 1 ? "disabled" : ""
                              }`}
                              id="dataTable_previous"
                            >
                              <a
                                href="#"
                                className="page-link"
                                onClick={() => handlePagination("prev")}
                              >
                                Previous
                              </a>
                            </li>
                            {[...Array(data?.value?.totalPages)].map(
                              (val, index) => (
                                <li
                                  className={`paginate_button page-item  ${
                                    page == index + 1 ? "active" : ""
                                  }`}
                                >
                                  <a
                                    onClick={() => handlePagination(index + 1)}
                                    className="page-link"
                                  >
                                    {index + 1}
                                  </a>
                                </li>
                              )
                            )}
                            <li
                              className={`paginate_button page-item next ${
                                page * limit > data?.value?.totalDocument
                                  ? "disabled"
                                  : ""
                              }`}
                              id="dataTable_next"
                            >
                              <a
                                onClick={() => handlePagination("next")}
                                className="page-link"
                              >
                                Next
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </AdminLayout>
      {selectedTransaction !== null && (
        <ViewTransactionModal
          transaction={selectedTransaction}
          show={showViewModal}
          close={() => handleClose("view")}
          updateTransactionStatus={updateTransactionStatus}
        />
      )}
    </>
  );
};

export default FailedTransactions;