import { useMutation, useQuery } from '@tanstack/react-query';
import React from 'react'
import { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom'
import { checkOTP, resetPassword } from '../api/user.api';
import WebsiteLayout from '../layouts/WebsiteLayout'
import { setUserInfo } from '../store/slices/userSlice';
import { cookieStore } from '../utils/cookie';
import { showAlert } from '../utils/helpers';

const ResetPassword = () => {
    const dispatch = useDispatch()
    let {id, otp} = useParams();
    let history = useNavigate()
    const [formdata, setFormdata] = useState({
        password:"", confirmPassword:""
    })
    const {isLoading:checking} = useQuery(['pwd-reset', id, otp], checkOTP, {
        onSuccess: data=>{
            if(!data.status)return showAlert({
                title: "Oops!",
                msg: typeof data.errors[0].msg == "string"
                ? data.errors[0].msg
                : data.errors[0].msg.message,
                icon: "error",
                btnText: "Close",
                callback: () => {
                    history("/")
                }
              });
        },
        onError: err => showAlert({
            title: "Error!",
            msg: "An internal error has occurred",
            icon: "error",
            btnText: "Close",
            callback: () => {
                history("/")
            }
          })
    })

    const {mutate, isLoading} = useMutation(resetPassword, {
        onSuccess: data=> {
            console.log(data)
            if(!data.status)return showAlert({
                title: "Oops!",
                msg: typeof data.errors[0].msg == "string"
                ? data.errors[0].msg
                : data.errors[0].msg.message,
                icon: "error",
                btnText: "Close",
              });
              return  showAlert({
                title: "Success!",
                msg: "Password reset successful!",
                icon: "success",
                btnText: "Done",
                callback:async () => {
                    await cookieStore("authToken", data?.value?.token);
                    dispatch(setUserInfo(data?.value?.user));
                }
              })

        },
        onError: err => showAlert({
            title: "Error!",
            msg: "An internal error has occurred",
            icon: "error",
            btnText: "Close"
          })
    })

    const handleSubmit = async (e) => {
        e.preventDefault();
        let {  password, confirmPassword } = formdata;
        if (!password.trim() || !confirmPassword.trim())
          return showAlert({
            title: "Missing Field(s)!",
            msg: "You must fill both fields",
            icon: "warning",
            btnText: "Retry",
          });
    
        if (password?.length < 6)
          return showAlert({
            title: "Error!",
            msg: "Your password cannot be less than 6 characters",
            icon: "warning",
            btnText: "Retry",
          });
    
        if (password.trim() !== confirmPassword.trim())
          return showAlert({
            title: "Mismatch",
            msg: "Both passwords do not match",
            icon: "warning",
            btnText: "Retry",
          });

          mutate({
            id,
            ...formdata
          })

    
    }
  return (
    <WebsiteLayout>
             <section className="copntact_area pt-120 pb-120">
        <div className="container">
          <div className="contact_wrap">
            <div className="row mb-10 justify-content-md-center">
              <div className="col-lg-12 col-md-12 text-center">
                <div className="contact_info mb-40 text-center justify-center" style={{textAlign:"center"}}>
                 <h2>Reset Password</h2>
                </div>
              </div>
            
            
            </div>
            <div className="row">
              <form className="contact_form" onSubmit={handleSubmit}>
                <div className="row">
                 
                  <div className="col-12">
                    <input
                      type="password"
                      name="password"
                      placeholder="Enter new password"
                      value={formdata.password}
                      onChange={(e)=>setFormdata({...formdata, password:e.target.value})}
                    />
                  </div>
                  <div className="col-12">
                    <input
                      type="password"
                      name="password-confirmation"
                      placeholder="Retype password"
                      value={formdata.confirmPassword}
                      onChange={(e)=>setFormdata({...formdata, confirmPassword:e.target.value})}
                    />
                  </div>
             
                  <div className="col-12">
                    <div className="contact_btn text-center">
                      <button type="submit" className="thm_btn thm_btn-black" disabled={isLoading}>
                      {isLoading?<Spinner animation='border' size='sm'/>:"  Reset Password"}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </WebsiteLayout>
  )
}

export default ResetPassword