import { useMutation } from "@tanstack/react-query";
import React, {useState} from "react";
import { Modal, Row, Col, Button, Tabs, Tab, Form, Spinner } from "react-bootstrap";
import { X } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "../../api/user.api";
import { toggleLogin, toggleResetPassword } from "../../store/slices/appSlice";
import { showAlert } from "../../utils/helpers";


const ForgotPasswordModal = () => {
    let dispatch = useDispatch();
    const [email, setEmail] = useState(null)
    const { showRPModal } = useSelector((state) => state.app);


    const handleShowLogin = (e) => {
        e.preventDefault();
        dispatch(toggleResetPassword());
        dispatch(toggleLogin());
      };

      const {mutate, isLoading} = useMutation(forgotPassword, {
        onSuccess: data => {
            if(!data.status)return showAlert({
                title: "Oops!",
                msg: typeof data.errors[0].msg == "string"
                ? data.errors[0].msg
                : data.errors[0].msg.message,
                icon: "error",
                btnText: "Close"
              });

             return showAlert({
                title: "Success!",
                msg: `A password reset link has been sent to ${email}, also check your spam and other folders`,
                icon: "success",
                btnText: "Close",
                callback: ()=>{
                    dispatch(toggleResetPassword())
                }
              })

        },
        onError: err => showAlert({
            title: "Error!",
            msg: "An internal error has occurred",
            icon: "error",
            btnText: "Close",
          })
      })
    const handleSubmit = (e) => {
        e.preventDefault()
        console.log(email)
        mutate({email})

    }
  return (
    <Modal
    show={showRPModal}
    onHide={() => dispatch(toggleResetPassword())}
    keyboard={false}
    size={"md"}
    centered
  >
      <div
        style={{
          position: "absolute",
          right: -15,
          top: -15,
          color: "#fff",
          backgroundColor: "#1e1d23ad",
          padding: 5,
          borderRadius: "50%",
          width: 30,
          height: 30,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}
        onClick={()=>dispatch(toggleResetPassword())}
      >
        <X size={18} />
      </div>
      <Modal.Header>
          <Modal.Title>Forgot Password</Modal.Title>
      </Modal.Header>
    <Modal.Body>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control type="email" disabled={isLoading} placeholder="Enter your email" value={email} onChange={(e) =>setEmail(e.target.value)} />
        </Form.Group>
        <Button disabled={isLoading} className="float-right btn-block mb-3" variant="danger" type="submit">
          {isLoading?<Spinner size="sm" animation="border" color="#fff"/>:"Submit"}
        </Button>
        <span className="btn btn-text btn-text-warning text-right float-right" onClick={handleShowLogin}>Login instead</span>
      </Form>
    </Modal.Body>
  </Modal>
  )
}

export default ForgotPasswordModal