import React from 'react'
import WebsiteLayout from '../layouts/WebsiteLayout'

const TOS = () => {
  return (
    <WebsiteLayout>

<section className="copntact_area pt-120 pb-120">
        <div className="container">
          <div className="contact_wrap">


            <div className="row mb-10 justify-content-md-center">
              <div className="col-lg-12 col-md-12 text-center">
                <div className="contact_info mb-40 text-center justify-center" style={{textAlign:"center"}}>
                 <h2> Terms of Service for Genesis Restaurant</h2>
                </div>
              </div>
            </div>

            <div className="row">
            

<h3>Introduction</h3>

Welcome to Genesis Restaurant, a platform for ordering food for delivery or pickup. By using our website or mobile application, you agree to be bound by the following terms and conditions (“Terms of Service”). If you do not agree to these Terms of Service, you may not use our services.

<h3>Service Description</h3>

Genesis Restaurant provides a platform for ordering food from local restaurants. We do not own or operate any restaurants, and we are not responsible for the food or other products that you purchase through our services.

<h3>Order Placement</h3>

When you place an order through our website or mobile application, you are making an offer to purchase food or other products from a restaurant. We will transmit your order to the restaurant, and the restaurant will prepare your order and make it available for delivery or pickup. By placing an order, you agree to pay the price of the food and any other fees or charges that may apply to your order.

<h3>Delivery and Pickup</h3>

Delivery: If you choose delivery, you agree to pay any delivery fee that may apply to your order, and you acknowledge that delivery times are estimated and may vary. If you are not at the delivery address when the delivery arrives, you may be responsible for additional fees.

Pickup: If you choose pickup, you agree to pick up your order at the restaurant within the designated pickup time frame. If you do not pick up your order within the designated pickup time frame, the restaurant may cancel your order and you will not be entitled to a refund.

<h3>Payment</h3>

You agree to pay the price of the food and any other fees or charges that may apply to your order, including delivery fees, taxes, and any other charges that may be imposed by the restaurant. You authorize Genesis Restaurant to charge your payment method for the amount of your order, and you agree to provide accurate and current payment information.

<h3>Refunds and Cancellations</h3>

Refunds: If you are not satisfied with your order, you should contact the restaurant directly to resolve the issue. Genesis Restaurant does not issue refunds for orders placed through our website or mobile application.

Cancellations: You may cancel your order before it has been prepared by the restaurant, but you may be subject to a cancellation fee. If the restaurant has already prepared your order, you cannot cancel the order.

<h3>Disclaimer of Warranties</h3>

THE SERVICE IS PROVIDED “AS IS” WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION ANY WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. GENESIS RESTAURANT DOES NOT WARRANT THAT THE SERVICE WILL BE AVAILABLE AT ANY PARTICULAR TIME OR LOCATION, WILL BE SECURE OR ERROR-FREE, OR THAT DEFECTS WILL BE CORRECTED.

<h3>Limitation of Liability</h3>

GENESIS RESTAURANT SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES, ARISING OUT OF OR RELATING TO THE USE OF THE SERVICE.

<h3>Modification of Terms of Service</h3>

We may modify these Terms of Service from time to time, and such modifications will be effective upon posting on our website or mobile application. Your continued use of the service after such posting will be deemed acceptance of the modified terms.
            </div>

            </div>
            </div>
            </section>
             </WebsiteLayout>
  )
}

export default TOS