import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { Badge, Button, Card, Col, Form, Row, Spinner } from "react-bootstrap";
import { Minus, Plus, Search, Send, Trash } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { fetchProducts, getOrderDetailsById } from "../../api/admin.api";
import { getOutletsByState, getStates } from "../../api/app.api";
import ItemSingle from "../../components/Admin/ItemSingle";
import SingleCartItem from "../../components/Admin/SingleCartItem";
import NewOrderModal from "../../components/Modals/NewOrderModal";
import AdminLayout from "../../layouts/AdminLayout";
import { clearCart, setCart, setDeliveryFee, setModifiedCart, setProducts } from "../../store/slices/adminSlice";
import { displayAmount, makeTitleCase, showAlert } from "../../utils/helpers";

const NewOrder = () => {
    let dispatch = useDispatch()
    let { products, items:cartItems, total:cartTotal, deliveryFee } = useSelector(state=>state.admin)
    const [showAll, setShowAll] = useState(true);
    const [searchQuery, setSearchQuery] = useState("");
    const [completeOrder, setCompleteOrder] = useState(false)
    const [availableProducts, setAvailableProducts] = useState(products);
    const [searchParams, setSearchParams] = useSearchParams();
    const queryId = searchParams.get("oid");
    const [orderDetails, setOrderDetails] = useState({})
    const [selectedState, setSelectedState] = useState(null)
    const [allProds, setAllProds] = useState([])
    const [outletsInState, setOutletsInState] = useState(null)
    const [selectedOutlet, setSelectedOutlet] = useState(null)

    const { data, isLoading, isSuccess, error, refetch } = useQuery(
      ["allProducts", selectedOutlet],
      fetchProducts,
      {
        enabled:false,
        onSuccess: (data) => {
          console.log(data)
          if (!data?.status) {
            return showAlert({
                title: "Oops!",
                msg: data?.errors[0].msg,
                icon: "error",
                btnText: "Close",
              });
          }
          dispatch(setProducts(data?.value?.products));
          setAllProds(data?.value?.products);
        },
        onError: err => showAlert({
            title: "Error!",
            msg: "An internal error has occurred",
            icon: "error",
            btnText: "Close",
          })
      }
    );
  
    const {
      isLoading: isLoadingOrderCart,
      isError: hasOrderCartError,
      refetch: refetchOrderCart,
    } = useQuery(["orderCart", queryId], getOrderDetailsById, {
      onSuccess: data=>{
          if(data.status){
            // console.log(data.value)
            setOrderDetails(data.value)
            dispatch(setDeliveryFee(data?.value?.deliveryFee))
             dispatch(setModifiedCart(data.value))
            setSelectedState(data?.value?.outlet?.state)
            setSelectedOutlet(data?.value?.outlet?._id)
          }
      },
      enabled: false,
    });

    const {data:states, isLoading:loadingStates, isSuccess:statesSuccess} = useQuery(['states'], getStates, {
      onSuccess: data => {
          if(!data.status){
            showAlert({
              title:"Error!", msg:typeof data.errors[0].msg == "string"
              ? data.errors[0].msg
              : data.errors[0].msg.message, icon:"error", btnText:"close" 
           })
          }
      },
      onError: err => showAlert({
          title:"Error!", msg:"An internal error has occured", icon:"error", btnText:"close" 
       })
  });
  
    const startSettlement = () => {
      setCompleteOrder(true)
    }
  
    useEffect(() => {
      // if (showAll) 
      setAvailableProducts(products);
    }, [showAll, products]);
  
    const handleChange = async (e) => {
      if(!selectedState){
        return showAlert({
          title: "Oops!",
          msg: "You have to select a Region first",
          icon: "error",
          btnText: "Close",
        })
      }
      let query = e.target.value.toLowerCase();
      setSearchQuery(query);
     
    };
  
    const handleCloseOrder = () => {
      setCompleteOrder(false)
      dispatch(clearCart())
  
    }
  
    useEffect(() => {
      let prods = allProds?.filter((val) =>
      val?.name.toLowerCase().includes(searchQuery) 
      || val?.category.includes(searchQuery) 
      || val?.product_price == searchQuery
    );
    setAvailableProducts(prods);
    // dispatch(setProducts(prods));
    }, [searchQuery])
  
    useEffect(() => {
      if (queryId) {
        refetchOrderCart();
      }
    }, [queryId]);

    useEffect(() => {
      if(selectedOutlet) refetch()
    }, [selectedOutlet])
    


    const { } = useQuery({
      queryKey:["getOutletsInState", selectedState],
      queryFn: getOutletsByState,
      onSuccess: data => {
          if (!data?.status && selectedState) {
            return showAlert({
                title: "Oops!",
                msg: typeof data.errors[0].msg == "string"
                ? data.errors[0].msg
                : data.errors[0].msg.message,
                icon: "error",
                btnText: "Close",
              });
          }
          setOutletsInState(data.value)
      },
      onError: err => {
        showAlert({
            title: "Error!",
            msg: "An internal error has occurred",
            icon: "error",
            btnText: "Close",
          })
      }
    })
  
  return (
    <>
    <AdminLayout>
     <div className="container-fluid">
          {/* Page Heading */}
          <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 className="h3 mb-0 text-gray-800">New Order</h1>
          </div>

          <Row>
            <Col sm={8}>
              <div className="card shadow mb-4">
                <div className="card-header py-3">
                <Form>
        <Row>
          <Col>
          {loadingStates && <Spinner animation='border' size='lg'/>}
            {!loadingStates && states && <Form.Group className="mb-3" controlId="formSelect">
            <Form.Label>Select Region</Form.Label>
              <Form.Select
              size="md"
                onChange={(e) => setSelectedState(e.target.value)}
                value={selectedState}
              >
                <option selected disabled value={null}>  Select Region </option>
                {
                  states?.value?.map((val, index)=><option key={`dbw-${index}`} value={val._id}>{makeTitleCase(val.name)}</option>)
                }
                
              </Form.Select>
            </Form.Group>}
          </Col>

          {!loadingStates && outletsInState && 
          <Col>
            <Form.Group className="mb-3" controlId="formOutletSelect">
              <Form.Label>Select Outlet</Form.Label>
              <Form.Select
                onChange={(e) => setSelectedOutlet(e.target.value)}
                value={selectedOutlet}
              >
                <option selected value={null}>  Choose </option>
                {
                  outletsInState?.map((val, index)=><option key={`outletinstate-${Math.floor(Math.random()*9999)}-${index}`} value={val._id}>{makeTitleCase(val.name)}</option>)
                }
                
              </Form.Select>
            </Form.Group>
            </Col>}
        </Row>
      </Form>

                  <Form>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formHorizontalEmail"
                    >
                      <Col sm={10}>
                        <Form.Control
                          onChange={handleChange}
                          type="search"
                          placeholder="Search by name, category or amount"
                          value={searchQuery}
                        />
                      </Col>
                      {/* <Col sm={1}>
                        <button type="submit" className="btn btn-inline btn-sm">
                          <Search />
                        </button>
                      </Col> */}
                      <Col sm={2}>
                        <Button
                          size="sm"
                          variant="outline-danger"
                          onClick={() => setSearchQuery("")}
                        >
                          Clear
                        </Button>
                      </Col>
                    </Form.Group>
                  </Form>
                </div>
                <div
                  className="card-body"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                  }}
                >
                  {isLoading &&  selectedState && <Spinner animation="border" size="lg"/>}
                  {!isLoading && availableProducts?.map((item, index) => (
                    <ItemSingle key={`vsw-${Math.random()*99999}-${index}`} product={item} />
                  ))}
                </div>
              </div>
            </Col>

            <Col sm={4}>
              <div className="card shadow mb-4" style={{ minHeight: 450 }}>
                <div className="card-header py-3">
                  <span>Cart Items</span>
                  <Badge style={{ marginLeft: 5 }} bg="danger">
                    {cartItems?.length}
                  </Badge>
                </div>
                <div className="card-body">
                  <div style={{ overflow: "scroll" }}>
                    <table className="table table-hover table-stripped">
                      <tbody>
                        {cartItems?.length > 0 &&
                          cartItems.map((orderItem, index) => (
                            <SingleCartItem
                              key={`hdb-${Math.floor(Math.random()*9999)}-${index}`}
                              orderItem={orderItem}
                              index={index}
                            />
                          ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="footer p-15">
                    <div className="amount-tab mb-3" style={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                      <strong>Total</strong>
                      <strong>{displayAmount(cartTotal, 2)}</strong>
                    </div>

                    <button
                      type="submit"
                      className="btn btn-success btn-md btn-block mt-10"
                      onClick={startSettlement}
                    >
                      {queryId?"Update Order":"Complete Order"} 
                    </button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        </AdminLayout>
        {completeOrder && <NewOrderModal outletid={selectedOutlet} order={queryId?orderDetails:null} show={completeOrder} close={handleCloseOrder}/>}
    </>
  )
}

export default NewOrder