import { useQuery } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { getOutletByToken } from "../api/admin.api";
import Footer from "../components/Admin/Footer";
import Sidebar from "../components/Admin/Sidebar";
import Topbar from "../components/Admin/Topbar";
import useWindowDimensions from "../components/useWindowDimensions";
import { setDetails } from "../store/slices/adminSlice";
import { cookieGet } from "../utils/cookie";
import { showAlert } from "../utils/helpers";
// import "../assets/css/sb.min.css"

const AdminLayout = ({ children }) => {
  let { width } = useWindowDimensions();
  let history = useNavigate();
  let dispatch = useDispatch();
  const { details } = useSelector((state) => state.admin);

  const { refetch } = useQuery(["getOutletDetails"], getOutletByToken, {
    enabled: false,
    onSuccess: (data) => {
      console.log("data", data);
      if (!data.status) return history("/admin/login");
      dispatch(setDetails(data?.value));
    },
    onError: (err) => console.log(err),
  });

  const checkForCookie = async () => {
    const val = await cookieGet("adminToken");
    if (!val || !details) {
      if (!val) {
        history("/admin/logout");
      } else {
        refetch();
      }
    }
  };

  useEffect(() => {
    checkForCookie();
  }, []);

  return (
    <>
      <ToastContainer
        position={width > 767 ? "top-left" : "bottom-center"}
        draggable
        autoClose={2000}
      />
      <div id="wrapper">
        <Sidebar outlet={details} />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar outlet={details} />
            {children}
          </div>
          {/* <Footer /> */}
        </div>
      </div>
    </>
  );
};

export default AdminLayout;
