import React from "react";
import OwlCarousel from "react-owl-carousel";

const ClientFeedback = () => {
  let reponsiveObject = {
    0: {
      items: 1,
    },
    600: {
      items: 2,
    },
    1000: {
      items: 4,
    },
  };
  return (
    <section
      className="testimonial_area testimonial_bg pt-120 pb-120"
      data-background="assets/img/bg/tm_bg.jpg"
      style={{ backgroundImage: `url(assets/img/bg/tm_bg.jpg)` }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-md-7">
            <div className="tm_content">
              <div className="sec_title sec_title-white">
                <span>Our Satisfied Customers</span>
                <h2>Have This To Say</h2>
                <p>We are always glad to hear from our customers</p>
              </div>
            </div>
          </div>

          <div className="col-lg-5 col-md-5">
            <div className="testimonial_active">
            <OwlCarousel
              className="owl-theme"
              items={1}
              loop
              autoplay
              autoplayHoverPause
            >
              <div className="tm_single white_bg">
                <div className="tm_top ul_li justify-content-between">
                  <div className="tm_author">
                    <img src="https://lh3.googleusercontent.com/a-/AFdZucqhv7KMjqBVkRIAPHX6iDrNL-udxw399_esuRE-MNo=w120-h120-p-rp-mo-ba5-br100" alt />
                  </div>
                  <div className="tm_quote">
                    <img src="assets/img/icon/quote.png" alt />
                  </div>
                </div>
                <p>
                Genesis restaurant offers delicious continental and local dishes. Their attendants are very courteous and welcoming.
                There is also a sufficient car park for you to park and enjoy your food without fear of it being towed.
                </p>
                <div className="tm_bottom ul_li justify-content-between">
                  <div className="a_info">
                    <h4>Joshua Esiebo</h4>
                    {/* <span>Founder &amp; co</span> */}
                  </div>
                  <div className="rating_wrap">
                    <ul className="rating_star ul_li">
                      <li>
                        <i className="fas fa-star" />
                      </li>
                      <li>
                        <i className="fas fa-star" />
                      </li>
                      <li>
                        <i className="fas fa-star" />
                      </li>
                      <li>
                        <i className="fas fa-star" />
                      </li>
                      <li>
                        <i className="fal fa-star" />
                      </li>
                    </ul>
                    {/* <span>4 Rating</span> */}
                  </div>
                </div>
              </div>
              <div className="tm_single white_bg">
                <div className="tm_top ul_li justify-content-between">
                  <div className="tm_author">
                    <img src="https://lh3.googleusercontent.com/a-/AFdZucrCBup4O-83_9wlNxWB90BoLkWSlCs-FEQCkTQpUQ=w120-h120-p-rp-mo-ba4-br100" alt />
                  </div>
                  <div className="tm_quote">
                    <img src="assets/img/icon/quote.png" alt />
                  </div>
                </div>
                <p>
                Food is decently priced and tasty. Staff is courteous and helpful with narrowing down choices if you have questions. The group I was in afforded us the opportunity to try different things on two different occasions. We tried the yam and egg sauce, the yam coated with egg, the asun rice, then another rice I can't remember the name, the moimoi, the spaghetti, the freshly squeezed orange juice, the sandwich, the indomie, all good and fresh. Pity I didn't take pictures of the different dishes we had.
                </p>
                <div className="tm_bottom ul_li justify-content-between">
                  <div className="a_info">
                    <h4>Eddy Isong</h4>
                    {/* <span>Founder &amp; Ceo</span> */}
                  </div>
                  <div className="rating_wrap">
                    <ul className="rating_star ul_li">
                      <li>
                        <i className="fas fa-star" />
                      </li>
                      <li>
                        <i className="fas fa-star" />
                      </li>
                      <li>
                        <i className="fas fa-star" />
                      </li>
                      <li>
                        <i className="fas fa-star" />
                      </li>
                      <li>
                        <i className="fas fa-star" />
                      </li>
                    </ul>
                    {/* <span>3 Rating</span> */}
                  </div>
                </div>
              </div>
              <div className="tm_single white_bg">
                <div className="tm_top ul_li justify-content-between">
                  <div className="tm_author">
                    <img src="https://lh3.googleusercontent.com/a-/AFdZucr5J8KEOYSVucLLf5k8KGo-9Oi10HbGJYzGAzmLa1U=w120-h120-p-rp-mo-ba4-br100" alt />
                  </div>
                  <div className="tm_quote">
                    <img src="assets/img/icon/quote.png" alt />
                  </div>
                </div>
                <p>
                I had a great time with my family. Nice delicacies and cool restaurant. Cheerful servers. Excellent food plating. I enjoyed my meat pie, tasty and crispy. However, the Air conditioner and lighting have to be worked on.
                </p>
                <div className="tm_bottom ul_li justify-content-between">
                  <div className="a_info">
                    <h4>Ezekwem Peace Chiamaka</h4>
                    {/* <span>Founder &amp; co</span> */}
                  </div>
                  <div className="rating_wrap">
                    <ul className="rating_star ul_li">
                      <li>
                        <i className="fas fa-star" />
                      </li>
                      <li>
                        <i className="fas fa-star" />
                      </li>
                      <li>
                        <i className="fas fa-star" />
                      </li>
                      <li>
                        <i className="fas fa-star" />
                      </li>
                      <li>
                        <i className="fas fa-star" />
                      </li>
                    </ul>
                    {/* <span>4 Rating</span> */}
                  </div>
                </div>
              </div>
            </OwlCarousel>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ClientFeedback;
