import React, {useState} from 'react'
import DatePicker from "react-datepicker";
import moment from 'moment'

const DateRangePicker = ({onChange}) => {
    const currentDate = new Date();
    const [dateRange, setDateRange] = useState([currentDate,currentDate]);
    const [startDate, endDate] = dateRange;

    const handleChange = (update) => {
        setDateRange(update);
        if(!update.includes(null)){onChange(update)}
      }
  return (
    <DatePicker
      utcOffset={0}
      selectsRange={true}
      startDate={startDate}
      endDate={endDate}
      onChange={(update) =>handleChange(update)}
      withPortal
      placeholderText="Select date range"
    />
  )
}

export default DateRangePicker