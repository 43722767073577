import axios from "axios";
import { API_URL } from "../configs/app";

export const register = async data => {
    let res = await axios.post(`${API_URL}/auth/register`, data)
    return res.data
}

export const login = async data => {
    let res = await axios.post(`${API_URL}/auth/login`, data)
    return res.data
}