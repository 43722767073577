import React from 'react'
import { displayAmount, makeTitleCase } from '../../utils/helpers';

const OrderItem = ({ item }) => {
    // console.log(item)
    // let currentItem = cart.find((data) => data.id === item.id);
    const price = item.oSize? item?.oSize?.price : item.sale_price && item.sale_price != null
      ? item.sale_price
      : item.product_price;
  return (
    <tr>
    <td>
      <div className="carttable_product_item">
        <div className="item_image">
          <div
            style={{
              backgroundImage: `url(${item.image_url})`,
              width: 120,
              height: 120,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "contain",
            }}
          ></div>
        </div>
        <h3 className="item_title" style={{marginLeft:5}}>{makeTitleCase(item.name)}  {item?.oSize?`(${item?.oSize?.title})`:null}</h3>
      </div>
    </td>
    <td>
      <span className="price_text1">{displayAmount(price, 2)}</span>
    </td>
    <td>
      <div className="cart-plus-minus">
       
        <input
          className="cart-plus-minus-box"
          type="text"
          name="qtybutton"
          disabled
          defaultValue={1}
          value={item.oQuantity}
        />
        
      </div>
    </td>
    <td>
      <span className="price_text2">
        {displayAmount(item.oTotal, 2)}
      </span>
    </td>
  </tr>
  )
}

export default OrderItem