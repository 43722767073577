import React from 'react'
import useWindowDimensions from '../useWindowDimensions'
const front_img = require("../../assets/images/breakfast_2.png")
const Jumbotron = () => {
    const { width } = useWindowDimensions()
  return (
   <section className="hero_area hero_3 section_notch_bottom" style={{backgroundImage:`url(/assets/img/bg/hero_bg_ing_02.jpg)`}}>
  <div className="hero_wrap hero_height">
    <div className="container-fluid">
      <div className="row align-items-center">
        <div className="col-lg-12">
          <div className="hero_content_wrap text-center">
            <div className="hero_text">
              <h2>Breakfast <span>Menu</span></h2>
            </div>
            <div className="hero_img" style={{
                maxWidth:800
                }}>
              <img src={front_img} alt="" />
            </div>

            {width > 767 &&
            <div className="hero_feat_wrap">
              <div className="hf_single sf_01">
                <h4>Sweet Choice</h4>
                <p>Waffles + Omelet + Expresso</p>
                <div className="hf_shape">
                  <img src="assets/img/shape/hf_01.png" alt />
                </div>
              </div>
              <div className="hf_single sf_02">
                <div className="hf_shape">
                  <img src="assets/img/shape/hf_02.png" alt />
                </div>
                <h4>Quick Filling</h4>
                <p>Omelet & Sausage</p>
              </div>
            </div>}

            <div className="hero_shape">
              <img className="shape hs_01" src="assets/img/shape/hs_01.png" alt />
              <img className="shape hs_02" src="assets/img/shape/hs_02.png" alt />
              <img className="shape his_01" src="assets/img/shape/his_01.png" alt />
              <img className="shape his_02" src="assets/img/shape/his_02.png" alt />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

  )
}

export default Jumbotron