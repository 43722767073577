import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { loginApi } from "../../api/admin.api";
// import Spinner from "../components/Spinner";
import { cookieGet, cookieStore } from "../../utils/cookie";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { showAlert } from "../../utils/helpers";
import { Button, Form, Spinner } from "react-bootstrap";
import WebsiteLayout from "../../layouts/WebsiteLayout";
import { useMutation } from "@tanstack/react-query";
import { login } from "../../api/admin.api";
import { useDispatch } from "react-redux";
import { setDetails } from "../../store/slices/adminSlice";

// import "../../assets/css/sb.min.css"

const MySwal = withReactContent(Swal);
const logo = require("../../assets/images/go-genesis.webp")

function Login() {
  const history = useNavigate();
  let dispatch = useDispatch()
  const [formData, setFormData] = useState({
    username: "",
    password: ""
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    mutate(formData)
  }

  const { mutate, isLoading,  } = useMutation(login, {
    onSuccess: data => {
        if(!data.status) {
          return showAlert({
            title:"Oops!", msg:typeof data.errors[0].msg == "string"
            ? data.errors[0].msg
            : data.errors[0].msg.message, icon:"error", btnText:"Try again" 
         })
        }
      cookieStore("adminToken", data?.value?.token);
      dispatch(setDetails(data?.value?.details))
      history("/admin/dashboard");
    },
    onError: err => showAlert({
        title:"Error!", msg:"An internal error has occured", icon:"error", btnText:"close" 
     })
  })


  const handleInputChange = (field, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const checkForCookie = async () => {
    const val = await cookieGet("adminToken");
    console.log(val)
     if (val) {
      //redirect
      history("/admin/dashboard");
    }
  };
  
  useEffect(() => {
    //check for cookie
    checkForCookie();
  }, []);

  return (
//   <WebsiteLayout isBreakfast={true}>
    <div className="container">
      {/* Outer Row */}
      <div className="row justify-content-center">
        <div className="col-xl-10 col-lg-12 col-md-9">
          <div className="card o-hidden border-0 shadow-lg my-5">
            <div className="card-body p-0">
              {/* Nested Row within Card Body */}
              <div className="row">
                <div className="col-lg-6 d-none d-lg-block bg-login-image" style={{backgroundImage:`url(${logo})`, backgroundPosition:"center", backgroundSize:"contain"}}/>
                <div className="col-lg-6">
                  <div className="p-5">
                    <div className="text-center">
                      <h1 className="title text-gray-900 mb-4">Login</h1>
                    </div>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="formName">
                        <Form.Label>Username</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Username"
                              value={formData.username}
                              onChange={(e) =>
                                handleInputChange("username", e.target.value)
                              }
                            />
                          </Form.Group>
                        <Form.Group className="mb-3" controlId="forPassword">
                        <Form.Label>Password</Form.Label>
                            <Form.Control
                              type="password"
                              placeholder="Enter Password"
                              value={formData.password}
                              onChange={(e) =>
                                handleInputChange("password", e.target.value)
                              }
                            />
                          </Form.Group>

                      <Button
                            variant="primary"
                            type="submit"
                            className="mt-3 thm_btn thm_btn-2 btn-block"
                            style={{width:"100%"}}
                            disabled={isLoading}
                          >
                            {isLoading?<><Spinner animation="border" size="sm" color="#fff"/> Loading...</>:"Login"}
                            </Button>
                      <hr />
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // </WebsiteLayout>
    );
}

export default Login;
