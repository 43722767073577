import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useState } from "react";
import { Card, Spinner } from "react-bootstrap";
import { Divide } from "react-feather";
import { customerOrders } from "../api/order.api";
import UserLayout from "../layouts/UserLayout";
import { displayAmount, showAlert } from "../utils/helpers";
import moment from "moment";
import { Link } from "react-router-dom";

const deliveryImg = require("../assets/images/delivery.gif")
const takeAwayImg = require("../assets/images/takeaway.gif")

const UserOrders = () => {
  const [orders, setOrders] = useState(null);
  const { isLoading } = useQuery(["allCustomerOrders"], customerOrders, {
    onSuccess: (data) => {
    //   console.log(data);
      if (!data?.status)
        return showAlert({
          title: "Oops!",
          msg: data?.errors[0].msg,
          icon: "error",
          btnText: "close",
        });
      setOrders(data?.value);
    },
    onError: (err) => {
      console.log(err);
      showAlert({
        title: "Error!",
        msg: "An internal error has occured",
        icon: "error",
        btnText: "Close",
      });
    },
  });

  return (
    <UserLayout title="Recent Orders">
      {isLoading && !orders && (
        <div
          style={{
            padding: 20,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner size={"md"} animation="border" />{" "}
          <span className="mx-3">Loading</span>
        </div>
      )}
      {!isLoading && orders && orders.map( (order, index)=>{
        let isDelivery = order?.deliveryType === "delivery" ?true:false;
        let itemCount = order?.items.length
      return (
      <div key={`fwf-${Math.floor(Math.random()*9999)}-${index}`} className="mb-4">
        <div className="timer mb-1" style={{display:"flex", justifyContent:"space-between", flexWrap:"wrap"}}>
            <span>{moment(order?.created).format(  "MMM Do YYYY, h:mm A")}</span>
            <span>order ID: {order?.orderNumber}</span>
        </div>
             <Card className="p-3">
        <Link to={`/order/details/${order?.oid}`}>
          <div className="card-top" style={{display:"flex", alignItems:"center"}}>
            <img style={{width:50}} src={isDelivery?deliveryImg:takeAwayImg} />
            <div className="mx-3" style={{display:"flex", flexDirection:"column", }}>
            <span className="" style={{fontSize:16, color:"#000"}}>{isDelivery?`Delivery from ${order?.outlet?.name}`:`Take away at ${order?.outlet?.name}`}</span>
            <span style={{color:"#646464"}}> {`${itemCount} ${itemCount > 1?"items":"item"}`}</span>
            </div>
          </div>
          </Link>
          <div className="card-bottom mt-3" style={{display:"flex", justifyContent:"space-between"}}>
            <span style={{fontSize:18, fontWeight:600, color:"#646464"}}>{displayAmount(order?.sumTotal, 2)}</span>
            <button onClick={()=>console.log("Hi")} className="btn btn-sm btn-danger"> Re-order</button>
          </div>
      
        </Card>
        <hr/>
      </div>)})
}
    </UserLayout>
  );
};

export default UserOrders;
