import React from 'react'
import { Coffee, Crosshair, LogOut, MapPin, ShoppingCart, User } from 'react-feather'
import { useDispatch } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { toggleLogout } from '../store/slices/appSlice'
import WebsiteLayout from './WebsiteLayout'

const UserLayout = ({children, title}) => {
    let location = useLocation()
    let dispatch = useDispatch()
    const handleShowLogout = (e) => {
        e.preventDefault();
        dispatch(toggleLogout());
      };
  return (
    <WebsiteLayout>
          <section className="shop_area pt-120 pb-120">
        <div className="container-fluid">
          <div className="row">

          <div className="col-lg-3 col-md-4 col-sm-12 mt-3">
                <div
                  className="cart_table table-responsive"
                  style={{}}
                >
                    <ul style={{color:"#1e1d23"}}>

                        <li> <Link style={{color:location.pathname === "/user/orders"?"": "#1e1d23"}} className='order-item-link' to="/user/orders"> <ShoppingCart size={18} /> <span className='mx-3'>Recent Orders</span> </Link> </li>
                        {/* <li> <Link style={{color:location.pathname === "/order-tracking"?"": "#1e1d23"}} className='order-item-link' to=""> <Crosshair size={18} /> <span className='mx-3'>Order Tracking </span> </Link> </li> */}
                        <li> <Link style={{color:location.pathname === "/user/profile"?"": "#1e1d23"}} className='order-item-link' to="/user/profile"> <User size={18} /> <span className='mx-3'>Account Settings</span> </Link> </li>
                        <li> <Link style={{color:location.pathname === "/user/addresses"?"": "#1e1d23"}} className='order-item-link' to="/user/addresses"> <MapPin size={18} /> <span className='mx-3'>Address Book</span> </Link> </li>
                        <li onClick={handleShowLogout} style={{cursor:"pointer"}} className='order-item-link'> <LogOut size={18} /> <span className='mx-3'>Log Out</span></li>
                    </ul>

                </div>
          </div>
          <div className="col-lg-9 col-md-9 col-sm-12 mt-3">
                <div
                  className="cart_table table-responsive"
                  style={{}}
                >
                      <p className="title" style={{ fontSize: 22, color: "#000" }}>
        {title}
      </p>

      <hr />
                        {children}
                </div>
          </div>



          </div>
        </div>
      </section>
    </WebsiteLayout>
  )
}

export default UserLayout