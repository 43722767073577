import { useMutation } from '@tanstack/react-query';
import React, {useState} from 'react'
import { useEffect } from 'react'
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import { verifyPayment } from '../api/payment.api';
import WebsiteLayout from '../layouts/WebsiteLayout';
import { clearOrder } from '../store/slices/orderSlice';
import { makeTitleCase, showAlert } from '../utils/helpers';
import {  getToken } from "firebase/messaging";
import { storeNotifToken } from "../api/app.api";
import { messaging } from '../utils/firebase';
import { trackPurchase } from '../utils/facebookPixelEvents';

const PaymentRedirect = () => {
    // const { clearOrder } = useSelector((state) => state.order);
    const {userRef, userInfo} = useSelector(state=>state.user)
    // const messaging = getMessaging();
    const [isSavingToken, setIsSavingToken] = useState(false)
    const VAPIDKEY = "BJqB5jzuV_MklnwVr4d0oqtHJb4C88vjofCaM6IwmQXH1Nh3vtNBIzS2p-Dgno8Snng-L8eninZY_tWed3oBE4c"
    let dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams();
    const [successful, setSuccessful] = useState(false)
    let txRef = searchParams.get("trxref")
    let reference = searchParams.get("reference");
    const {mutate, isLoading, isSuccess, isError, data, status} = useMutation(verifyPayment,{
        onSuccess: (data) => {
            // console.log(data)
            if(data?.status){
              setSuccessful(data?.value)
              dispatch(clearOrder())
              trackPurchase(data?.value?.amount/100)
            }else{
              showAlert({
                title:"Error!",
                msg:data?.errors[0]?.msg,
                icon:"error",
                btnText:"Close"
              })
            }
        },
        onError: (err) => showAlert({
          title:"Error!",
          msg:"An internal error has occured",
          icon:"error",
          btnText:"Close"
        })
    })

  const getMessagingToken = async () => {
    if(isSavingToken) return;
    try{
      setIsSavingToken(true)
      const currentToken = await getToken(messaging, { vapidKey: VAPIDKEY });
      if (currentToken) {
          console.log("currentToken", currentToken)
          //send to server
          const resp = await storeNotifToken({
            token: currentToken,
            ref: userRef
          })
          setIsSavingToken(false);

      } else {
        setIsSavingToken(false)
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
        // ...
      }
    }
    catch(err){
      setIsSavingToken(false)
      console.log('An error occurred while retrieving token. ', err)
    }  
  };

    useEffect(() => {
        if(!isLoading && !successful)mutate({txRef})
    }, [])
    useEffect(() => {
     if(successful && !isSavingToken) getMessagingToken()
    }, [successful])
    
    
  return (
  <WebsiteLayout>
              <section className="services_area services_space white_bg">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-12">

            {isLoading && !successful && <div className="mx-10 text-center" style={{textAlign:"center"}}>
                <img src={require("../assets/images/verify_payment.png")} style={{width:"50%"}} alt=""/>
                <h3>Completing order <Spinner animation="border" variant='danger' size="md"/> </h3>
                <p>Please wait while your order is being placed at the restaurant</p>
                <p className='mb-3 mt-3'>To receive recent info about your order, Click the "Allow" button on the notification message.</p>
            </div>}

            {successful && <div className="mx-10 text-center" style={{textAlign:"center"}}>
                <img src={require("../assets/images/success_payment.png")} style={{width:"50%"}} alt=""/>
                <h3 className='text-success'>Order Placed Successfully!</h3>
                <p className='mb-3 mt-3'>Thank you {userInfo?makeTitleCase(userInfo?.name):""}, The restaurant has received your order and it is being prepared</p>
                
                <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                <div className='m-3'><Link to={`/order/details/${successful.order_oid}`} className='btn btn-md btn-success'> Order Details</Link></div>
                
                <div className='m-3'><Link to="/" className='btn btn-md btn-outline-success'> Return Home</Link></div>
                </div>
            </div>}

          </div>
        </div>
      </div>
    </section>
  </WebsiteLayout>
  )
}

export default PaymentRedirect