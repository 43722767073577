import React from 'react'
import { Modal, Row, Col } from "react-bootstrap";
import {
  convertHMS,
  displayAmount,
  makeTitleCase,
  parseTimeDate,
} from "../../utils/helpers";
import moment from "moment"

const ViewTrendModal = ({order, show, close}) => {
  return (
    <Modal show={show} onHide={close} centered keyboard={false} size={"md"}>
    <Modal.Header closeButton>
      <Modal.Title>#{order.orderNumber}</Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <p>Order date:{moment(order?.created).format( "MMM Do YYYY" )}</p>
      <Row>
        <Col>
          <p>
            {" "}
            Order Created @:{" "}
            <span>  {moment(order?.timestamp?.[1]).format( " h:mm A, MMM Do YYYY" )}  </span>
          </p>
          {(order?.orderStatus == 2 ||
            order?.orderStatus == 3 ||
            order?.orderStatus == 4 ||
            order?.orderStatus == 5) &&
            order?.timestamp?.[2] && (
              <p>
                {" "}
                Preparing @:{" "}
                <span>  {moment(order?.timestamp?.[2]).format( " h:mm A, MMM Do YYYY" )}  </span>
              </p>
            )}

          {(order?.orderStatus == 3 ||
            order?.orderStatus == 4 ||
            order?.orderStatus == 5) &&
            order?.timestamp?.[3] && (
              <p>
                {" "}
                Ready @:{" "}
                <span>  {moment(order?.timestamp?.[3]).format( " h:mm A, MMM Do YYYY" )}  </span>
              </p>
            )}

          {(order?.orderStatus == 4 ||
            order?.orderStatus == 5) &&
            order?.timestamp?.[4] &&(
              <p>
                {" "}
                On Transit @:{" "}
                <span>  {moment(order?.timestamp?.[4]).format( " h:mm A, MMM Do YYYY" )}  </span>
              </p>
            )}

          {(order?.orderStatus == 5) &&
            order?.timestamp?.[5] &&(
              <p>
                {" "}
                Completed @:{" "}
                <span>  {moment(order?.timestamp?.[5]).format( " h:mm A, MMM Do YYYY" )}  </span>
              </p>
            )}
        </Col>
      </Row>
    </Modal.Body>
  </Modal>
);
}

export default ViewTrendModal