import React, { useEffect, useState } from 'react'
import AdminLayout from '../../layouts/AdminLayout'
import { useMutation, useQuery } from '@tanstack/react-query'
import { getOutletProducts, toggleProdAvailability } from '../../api/admin.api'
import { displayAmount, makeTitleCase, showAlert } from '../../utils/helpers'
import { Badge, Button, Form, Spinner } from 'react-bootstrap'

const OutletManageProducts = () => {
const [products, setProducts] = useState(null)
const [updatedProducts, setUpdatedProducts] = useState([])
const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)
const { isLoading:loadingProducts} = useQuery({
    queryKey:['singleOutletProducts'],
    queryFn: getOutletProducts,
    onSuccess: data => {
      if (!data.status)
      return showAlert({
        title: "Oops!",
        msg: typeof data.errors[0].msg == "string"
        ? data.errors[0].msg
        : data.errors[0].msg.message,
        icon: "error",
        btnText: "Close",
      });

      setProducts(data.value)
    },
    onError: err =>  showAlert({
      title: "Error!",
      msg: "An internal error has occurred",
      icon: "error",
      btnText: "Close",
    })
  })


  const { mutate, isLoading:isSubmitting } = useMutation(toggleProdAvailability, {
    onSuccess: data => {
        if(!data.status){
            return showAlert({
                title: "Error!",
                msg:
                  typeof data.errors[0].msg == "string"
                    ? data.errors[0].msg
                    : data.errors[0].msg.message,
                icon: "error",
                btnText: "Close",
              });
        }       
         showAlert({
          title: "Product Updated!",
          msg: `This product has been updated successfully`,
          icon: "success",
          btnText: "Close",
        });
        
    },
    onError: err => {
        return showAlert({
            title: "Error!",
            msg: "An internal error has occured",
            icon: "error",
            btnText: "Close",
          });
    }
})

  const handleSubmit = async () => {
    await  mutate(updatedProducts)
    setHasUnsavedChanges(false)
  }

  const handleChange = (id, val) => {
    let otherProducts = products.filter(item=>item._id!==id)
    let thisProduct = products.find(item=>item._id===id);
    thisProduct = {
        ...thisProduct,
        available: val
    }
    setHasUnsavedChanges(true)
      setUpdatedProducts(prev=>([...prev,thisProduct]))
      return  setProducts([thisProduct, ...otherProducts])
  }


  useEffect(() => {
    const onBeforeUnload = (e) => {
      if (hasUnsavedChanges) {
        e.preventDefault();
        e.returnValue = "";
      }
    };
    window.addEventListener("beforeunload", onBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, [hasUnsavedChanges]);


  return (
    <AdminLayout>
 <div className="container-fluid">
          <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <div>
            <h1 className="h3 mb-0 text-gray-800">Products</h1></div>
            <Button onClick={handleSubmit} variant='primary' size='md' disabled={!hasUnsavedChanges}>{isSubmitting?"Saving...":"Save Changes"}</Button>
            </div>
            <div className="card shadow mb-4">
          <div className="card-body">
          <div className="table-responsive">
              {loadingProducts && <Spinner animation="border" size="lg"/>}
              {!loadingProducts && products && 
               <table
               className="table table-bordered text-center"
               id="dataTable"
               width="100%"
               cellSpacing={0}
             >
               <thead>
                 <tr>
                   <th>S/N</th>
                   <th>Item</th>
                   <th>Price</th>
                   <th>Available</th>
                   <th>Category</th>
                   <th>Action</th>
                 </tr>
               </thead>
               <tfoot>
                 <tr>
                 <th>S/N</th>
                   <th>Item</th>
                   <th>Price</th>
                   <th>Available</th>
                   <th>Category</th>
                   <th>Action</th>
                 </tr>
               </tfoot>
               <tbody>
                 {products &&
                   products?.length > 0 &&
                   products?.map((item, index) => (
                     <tr
                       key={`${Math.floor(Math.random() * 10000)}-${index}`}
                     >
                       <td>{(index + 1) }</td>
                       <td>
                           
                        <div 
                        //  onClick={()=>handleView(item)}
                          style={{display:"flex", alignItems:"center", cursor:"pointer"}}> 
                        <div style={{ 
                             width: 50,
                             height: 50,
                             borderRadius: 10,
                             marginRight: 15,
                             backgroundImage: `url(${item.image_url})`,
                             backgroundRepeat:"no-repeat",
                             backgroundSize:"contain",
                             backgroundPosition:"center"
                             }}></div>
                         {makeTitleCase(item.name)}
                         </div>
                       </td>
                       <td>
                         {item.is_promo && item.sale_price !== null ? (
                           <>
                             <span style={{textDecorationLine:'line-through'}}>{displayAmount(item.product_price)}</span>
                             <span> {displayAmount(item.sale_price)}</span>
                           </>
                         ) : (
                           displayAmount(item.product_price)
                         )}
                       </td>
                       <td>{item.available 
                       ?<Badge className='mr-1' pill bg="success">On Sale</Badge>
                       : <Badge className='mr-1' pill bg="danger">Unavailable</Badge>
                        }
                        </td>
                     
                       <td>
                        {/* {moment(item?.created).format(  "MMM Do YYYY, h:mm A" )} */}
                        {item.category.map((item, index)=>item==="all-products"?null:<Badge key={`bdh-${Math.floor(Math.random()*9999)}`} className='mr-1' pill bg="secondary">{makeTitleCase(item)}</Badge>)}
                          </td>
                      
                       <td>
                         <Form.Check
                        //  type="checkbox"
                          style={{ position:"relative"}}
                           type="switch"
                          size={14}
                          id="available-checkbox"
                          checked={ item.available }
                          onChange={(e) => {e.preventDefault(); handleChange(item._id, e.target.checked)}}
                        />
                       </td>
                     </tr>
                   ))}
               </tbody>
             </table>}
            </div>
        
        </div>
        </div>

    </div>
      
    </AdminLayout>
  )
}

export default OutletManageProducts
