import React from "react";

const AboutTimeline = () => {
  return (
    <section className="history_area pt-120 pb-120">
      <div className="container">
        <div className="history_wrap">
          <div className="sec_title">
            <span>Our Story</span>
            <h2>3 decades of Evolution</h2>
            <p>
            It's been a 30 year journey, from our first restaurant to floating over 28 restaurants, spreading our gospel of taste and quality service to major cities round the country.

            </p>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="history_left">
                <div className="history_item">
                  <div className="h_thumb">
                    <img src="assets/img/history/gen1.webp" alt />
                  </div>
                  <div className="h_text">
                    <span>1991 - Zik Avenue, Enugu</span>
                    <p>
                      We started with a commitment to serve really great meals. Cheers to 3 decades of evolution. 🍷
                    </p>
                  </div>
                  <div className="number_box">
                    <span>02</span>
                  </div>
                </div>
              
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="history_right">
                <div className="history_item">
                  <div className="h_thumb">
                    <img src="assets/img/history/food1.webp" alt />
                  </div>
                  <div className="h_text">
                    <span>Lots of options to choose from</span>
                    <p>
                      We offer a wide variety of meals to choose from in every of our outlets. Always ready to fill your taste-buds
                    </p>
                  </div>
                  <div className="number_box">
                    <span>01</span>
                  </div>
                </div>
                <div className="history_item">
                  <div className="h_thumb">
                    <img src="assets/img/history/restaurant.webp" alt />
                  </div>
                  <div className="h_text">
                  <span>28 Restaurants and counting</span>
                    <p>
                      We have over 28 restaurant outlets across Nigeria serving freshly prepared and healthy meals everyday
                    </p>
                  </div>
                  <div className="number_box">
                    <span>03</span>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutTimeline;
