import { useMutation, useQuery } from "@tanstack/react-query";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getUser, updateUser } from "../api/user.api";
import LoadingModal from "../components/Modals/LoadingModal";
import UserLayout from "../layouts/UserLayout";
import { setUserInfo } from "../store/slices/userSlice";
import { makeTitleCase, showAlert, showToast } from "../utils/helpers";

const UserProfile = () => {
    let dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.user);
  const [user, setUser] = useState(userInfo ? userInfo : null);
  const [formData, setFormData] = useState(userInfo ? userInfo : {})

 const {isLoading, refetch} = useQuery(["userProfile"], getUser, {
    enabled: false,
    onSuccess: (data) => {
      if (!data.status)
        return showAlert({
          title: "Oops!",
          msg: data?.errors[0].msg,
          icon: "error",
          btnText: "close",
        });
      setUser(data?.value);
      setFormData(data?.value);
    },
    onError: (err) => {
      showAlert({
        title: "Error!",
        msg: "An internal error has occured",
        icon: "error",
        btnText: "Close",
      });
    },
  });
useEffect(() => {
  if(!userInfo) refetch()
}, [userInfo])


const handleInputChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const {mutate, isLoading:loadingUpdate} = useMutation(updateUser, {
    onSuccess: data => {
        if(!data.status) return showAlert({
            title: "Oops!",
            msg: data?.errors[0].msg,
            icon: "error",
            btnText: "close",
          });
          showToast("success", "Your profile has been updated")
    },
    onError: err => {
        showAlert({
            title: "Error!",
            msg: "An internal error has occured",
            icon: "error",
            btnText: "Close",
          });
    }
  })
const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(setUserInfo(formData))
        mutate(formData)
}

  return <UserLayout title={"Your Profile"}>
     {isLoading && !user && (
        <div
          style={{
            padding: 20,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner size={"md"} animation="border" />{" "}
          <span className="mx-3">Loading</span>
        </div>
      )}

      {
        user && <>

           <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col>
                          <Form.Group className="mb-3" controlId="formName">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              value={(formData.name)}
                              onChange={(e) => handleInputChange("name", e.target.value)}
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <Form.Group className="mb-3" controlId="formPhone">
                            <Form.Label>Phone</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder=""
                              value={formData.phone}
                              onChange={(e) => handleInputChange("phone", e.target.value) }
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className="mb-3" controlId="formEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder=""
                              value={formData.email}
                              disabled
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group className="mb-3" controlId="formGender">
                            <Form.Label>Gender</Form.Label>
                            <Form.Select
                              value={formData.gender}
                              onChange={(e) => handleInputChange("gender", e.target.value) }
                            >
                            <option selected disabled >Choose</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className="mb-3" controlId="formDOB">
                            <Form.Label>Date Of Birth</Form.Label>
                            <Form.Control
                              type="date"
                              value={formData.dob}
                              onChange={(e) => handleInputChange("dob", e.target.value) }
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      

                      <Row>
                        <Col>
                          <Button
                            variant="primary"
                            type="submit"
                            className="mt-30 thm_btn thm_btn-black thm_btn-2"
                            disabled={loadingUpdate}
                          >
                           {loadingUpdate?"Updating...":"Update"}
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                    {loadingUpdate && <LoadingModal/>}
        </>
      }
  </UserLayout>;
};

export default UserProfile;
