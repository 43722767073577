import React from 'react'
import { useDispatch } from 'react-redux'
import { removeFromCart } from '../../store/slices/orderSlice';
import { displayAmount } from '../../utils/helpers'

const SideCartItem = ({item}) => {
  let dispatch = useDispatch();

  return (
    <div className="cart_item">
    <div className="item_image">
      <div style={{
        backgroundImage:`url(${item.product.image_url})`,
        width:70, height:70, backgroundRepeat:"no-repeat", backgroundPosition:"center", backgroundSize:"contain"
      }}></div>
    </div>
    <div className="item_content">
      <h4 className="item_title">{item.quantity +" -"} {item.product.name} {item?.size?`(${item?.size?.title})`:null}</h4>
      {item.hasBlackFridayOffer && <del><span className="item_price" style={{fontSize:12, color:"#726f6f"}}>{displayAmount(item.baseTotal,2)}</span></del>} <span className="item_price">{displayAmount(item.total,2)}</span> 
      
      {item.hasBlackFridayOffer && <span className="badge badge-dark" style={{fontSize:10}}>25% Black Friday</span>}
      <button type="button" className="remove_btn" onClick={()=>dispatch(removeFromCart(item.id))}>
        <i className="fal fa-times" />
      </button>
    </div>
  </div>
  )
}

export default SideCartItem