import { createSlice } from "@reduxjs/toolkit";
import { getCartProducts } from "../../utils/helpers";

const initialState = {
  details: null,
  products: [],
  items: [],
  total: 0,
  deliveryFee: 0,
};

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setDetails: (state, action) => {
      state.details = action.payload;
    },
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    setCart: (state, action) => {
      state.items = action.payload.items;
      state.total = action.payload.total;
      state.deliveryFee = action.payload.deliveryFee;
    },
    setModifiedCart: (state, action) => {
      state.items = getCartProducts(action.payload.items);
      state.total = action.payload.cartSum;
      state.deliveryFee = action.payload.deliveryFee;
    },
    clearCart: (state) => {
      state.items = [];
      state.total = 0;
      state.deliveryFee = 0;
    },
    addItemToCart: (state, action) => {
      if (state.items.length < 1) {
        state.items = [action.payload];
        state.total = action.payload.isVariable
                      ?action.payload.product.oSize.price * action.payload.quantity
                      :action.payload.product.product_price * action.payload.quantity;
      } else {
        let currentItem = state.items.find(
          (item) => item.id == action.payload.id
        );
        if (currentItem) {
          let itemPosition = state.items.indexOf(currentItem)
          state.items.splice(itemPosition,1, { ...currentItem, quantity: currentItem.quantity + 1 })
          state.total =
            state.total +
            (action.payload.isVariable
              ?action.payload.product.oSize.price * action.payload.quantity
              :action.payload.product.product_price * action.payload.quantity)
        } else {
          state.items = [action.payload, ...state.items];
          state.total =
            state.total +
            (action.payload.isVariable
              ?action.payload.product.oSize.price * action.payload.quantity
              :action.payload.product.product_price * action.payload.quantity)
        }
      }
    },
    removeItemFromCart: (state, action) => {
      let currentItem = state.items.find(
        (item) => item.id == action.payload.id
      );
      state.items = state.items.filter((item) => item.id !== action.payload.id);
      state.total = state.total - (currentItem.isVariable
          ?currentItem.product.oSize.price * currentItem.quantity
          :currentItem.product.product_price * currentItem.quantity)
    },

    changeCartItemCount: (state, action) => {
      let { type, id } = action.payload;
      let currentItem = state.items.find((item) => item.id == id);
      // let fiteredItems = state.items.filter((item) => item.id != id);
      let itemPosition = state.items.indexOf(currentItem)
      
      if (type === "increment") {
        state.items.splice(itemPosition,1, { ...currentItem, quantity: currentItem.quantity + 1 });
        state.total = state.total + (currentItem.isVariable?currentItem.product.oSize.price * 1:currentItem.product.product_price * 1);
  
      } else if (type === "decrement") {
        state.items.splice(currentItem.quantity <= 1
          ?(itemPosition,1, { ...currentItem, quantity: 1 })
          :(itemPosition,1, { ...currentItem, quantity: currentItem.quantity - 1 })
          );
        state.total = currentItem.quantity <= 1
            ? state.total
            : state.total - (currentItem.isVariable?currentItem.product.oSize.price * 1:currentItem.product.product_price * 1);
    }
    },
    setDeliveryFee: (state, action) => {
      state.deliveryFee = action.payload;
    }
  },
});

export const { setDetails, setProducts, setCart, clearCart, addItemToCart, removeItemFromCart, changeCartItemCount, setDeliveryFee, setModifiedCart } =
  adminSlice.actions;
export default adminSlice.reducer;
