import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging } from "firebase/messaging";


const firebaseConfig = {
  apiKey: "AIzaSyCuBQ5ObW2EvYfY4XfukIMuQ_kQ-jlM5fg",
  authDomain: "go-genesis-premium.firebaseapp.com",
  projectId: "go-genesis-premium",
  storageBucket: "go-genesis-premium.appspot.com",
  messagingSenderId: "535849095969",
  appId: "1:535849095969:web:d0222a1ccb6aa7c04aa460",
  measurementId: "G-VKCCQG4SK2"
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
export const analytics = getAnalytics(firebase);
export const messaging = getMessaging(firebase);


export default firebase;