import axios from "axios";
import { API_URL } from "../configs/app";


export const getProductsByOutlet = async ({queryKey}) => {
    let res = await axios.post(`${API_URL}/product/get/outlet`, queryKey[1])
    return res.data
}
export const getProductsByState = async ({queryKey}) => {
    let res = await axios.post(`${API_URL}/product/get`, queryKey[1])
    return res.data
}

export const getRandomProducts = async ({queryKey}) => {
    let res = await axios.post(`${API_URL}/product/random`, queryKey[1])
    return res.data
}

export const getSingleProduct = async ({queryKey}) => {
    let res = await axios.get(`${API_URL}/product/single/${queryKey[1]}`)
    return res.data
}