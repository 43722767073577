import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { orderDetails } from "../api/order.api";
import WebsiteLayout from "../layouts/WebsiteLayout";
import { displayAmount, makeTitleCase, showAlert } from "../utils/helpers";
import Moment from "react-moment";
import moment from "moment";
import { ESTIMATED_DELIVERY_TIME, ESTIMATED_PICKUP_TIME } from "../configs/app";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import OrderItem from "../components/WebComponents/OrderItem";

const OrderDetails = () => {
  const statusValues = [
    "unpaid",
    "placed",
    "prepared",
    "on transit",
    "completed",
  ];
  const { oid } = useParams();
  const [orderInfo, setOrderInfo] = useState(null);
  const { deliveryType } = useSelector((state) => state.order);
  let isDelivery = deliveryType === "delivery" ? true : false;
  const { data, isLoading } = useQuery(["orderDetials", oid], orderDetails, {
    onSuccess: (data) => {
      if (!data.status)
        return showAlert({
          title: "Oops!",
          msg: data?.errors[0].msg,
          icon: "error",
          btnText: "close",
        });
      setOrderInfo(data?.value);
    },
    onError: (err) =>
      showAlert({
        title: "Error!",
        msg: "An internal error has occured",
        icon: "error",
        btnText: "Close",
      }),
  });

  return (
    <WebsiteLayout>
      <section className="shop_area pt-120 pb-120">
        <div className="container">
          <div className="row">
            <div className="col-12">
              {!orderInfo && isLoading && (
                <div
                  className="cart_table table-responsive"
                  style={{
                    height: 200,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Spinner size={22} animation="border" />
                  <span style={{ marginLeft: 5, fontSize: 18 }}> Loading</span>
                </div>
              )}

              {!isLoading && orderInfo && (
                <div className="cart_table table-responsive row">
                  <div
                    className="col-lg-3 col-sm-6 mb-3"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <span>Order ID</span>
                    <span style={{ fontSize: 16, fontWeight: 600 }}>
                      {orderInfo?.orderNumber}
                    </span>
                  </div>

                  <div
                    className="col-lg-3 col-sm-6 mb-3"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <span>
                      {isDelivery
                        ? " To be delivered around"
                        : "To be ready around"}
                    </span>
                    <span style={{ fontSize: 16, fontWeight: 600 }}>
                      {moment(orderInfo?.created)
                        .add(
                          isDelivery
                            ? ESTIMATED_DELIVERY_TIME
                            : ESTIMATED_PICKUP_TIME,
                          "m"
                        )
                        .format("LT")}
                    </span>
                  </div>

                  <div
                    className="col-lg-3 col-sm-6 mb-3"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <span>Outlet</span>
                    <span style={{ fontSize: 16, fontWeight: 600 }}>
                      {orderInfo?.outlet?.name}
                    </span>
                  </div>
                  <div
                    className="col-lg-3 col-sm-6 mb-3"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <span>Created On</span>
                    <span style={{ fontSize: 16, fontWeight: 600 }}>
                      {moment(orderInfo?.created).format(
                        "MMM Do YYYY, h:mm A"
                      )}
                    </span>
                  </div>
                </div>
              )}

              {!isLoading && orderInfo && (
                <div className="cart_table table-responsive row mt-3">
                  <table className="table">
                    <thead className="text-uppercase">
                      <tr>
                        <th>Product</th>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th>Subtotal</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderInfo?.items?.map((item, index) => (
                        <OrderItem key={`syg-${Math.floor(Math.random()*9999)}-${index}`} item={item} />
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>

          {orderInfo && (
            <>
              <div className="row pt-60">
                <div className="col-lg-4 col-sm-6 col-xs-12">
                  <div className="cart_tutal_wrap">
                    <h3 className="title">Customer Details</h3>
                    <ul>
                        <li>  Name
                            <span>{makeTitleCase(orderInfo?.customer?.name)}</span>
                        </li>
                        <li> Phone
                        <span>{orderInfo?.customer?.phone}</span>
                        </li>
                        <li>Email
                        <span>{orderInfo?.customer?.email}</span>
                        </li>
                      
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-xs-12">
                  <div className="cart_tutal_wrap">
                    <h3 className="title">Other Details</h3>
                    <ul>
                        {orderInfo?.deliveryType === "delivery" &&<li>Address
                            <span>{orderInfo.address} {orderInfo?.deliveryArea?.name}</span>
                        </li>}
                        <li>Note 
                        <span>{orderInfo?.note}</span>
                        </li>
                        
                    </ul>
                  </div>
                </div>

                <div className="col-lg-4 col-sm-6 col-xs-12">
                  <div className="cart_tutal_wrap">
                    <h3 className="title">Order Summary</h3>
                    <ul>
                      <li>
                        Subtotal{" "}
                        <span>{displayAmount(orderInfo?.cartSum, 2)}</span>
                      </li>
                      {orderInfo?.couponPromo && (
                        <li
                          style={{
                            backgroundColor: "#cdffd44d",
                            color: "darkgreen",
                          }}
                        >
                          Coupon Discount{" "}
                          <span>
                            -{" "}
                            {displayAmount(
                              orderInfo?.couponPromo?.discountedAmount,
                              2
                            )}
                          </span>
                        </li>
                      )}
                      {orderInfo?.deliveryType === "delivery" && (
                        <li style={{ color: "#6f7987" }}>
                          Delivery Fee{" "}
                          <span>
                            {displayAmount(orderInfo?.deliveryArea?.amount, 2)}
                          </span>
                        </li>
                      )}

                      <li style={{ fontWeight: 600 }}>
                        Total{" "}
                        <span>{displayAmount(orderInfo?.sumTotal, 2)}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="row pt-60">
             
              <div className="col-lg-12 col-sm-12 col-xs-12">
                  <div className="cart_tutal_wrap">
                    <h3 className="title">Order Status</h3>

                    <div className="progress-track" style={{ padding: 0 }}>
                      {orderInfo.deliveryType === "delivery" ? (
                        <ul id="progressbar">
                          <li style={{width:"20%"}}
                            className={`step0 ${
                              orderInfo?.orderStatus >= 1 ? "active" : ""
                            } `}
                            id="step1"
                          >
                            Placed
                          </li>
                          <li style={{width:"20%"}}
                            className={`step0  ${
                              orderInfo?.orderStatus >= 2 ? "active" : ""
                            } text-center`}
                            id="step2"
                          >
                            Preparing
                          </li>
                          <li style={{width:"20%"}}
                            className={`step0  ${
                              orderInfo?.orderStatus >= 3 ? "active" : ""
                            }  text-center`}
                            id="step3"
                          >
                            Ready for delivery
                          </li>
                          <li style={{width:"20%"}}
                            className={`step0  ${
                              orderInfo?.orderStatus >= 4 ? "active" : ""
                            } text-center`}
                            id="step4"
                          >
                            On Transit
                          </li>
                          <li style={{width:"20%"}}
                            className={`step0  ${
                              orderInfo?.orderStatus >= 5 ? "active" : ""
                            } text-center`}
                            id="step4"
                          >
                            Completed
                          </li>
                        </ul>
                      ) : (
                        <ul id="progressbar">
                          <li style={{width:"25%"}}
                            className={`step0  ${
                              orderInfo?.orderStatus >= 1 ? "active" : ""
                            } `}
                            id="step1"
                          >
                            Placed
                          </li>
                          <li style={{width:"25%"}}
                            className={`step0   ${
                              orderInfo?.orderStatus >= 2 ? "active" : ""
                            }  text-center`}
                            id="step2"
                          >
                            Preparing
                          </li>
                          <li style={{width:"25%"}}
                            className={`step0  ${
                              orderInfo?.orderStatus >= 3 ? "active" : ""
                            }  text-center`}
                            id="step3"
                          >
                            Ready for Pickup
                          </li>
                          <li style={{width:"25%"}}
                            className={`step0  ${
                              orderInfo?.orderStatus >= 5 ? "active" : ""
                            }  text-center`}
                            id="step3"
                          >
                            Completed
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                </div>

              </div>
            </>
          )}
        </div>
      </section>
    </WebsiteLayout>
  );
};

export default OrderDetails;
