import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { displayAmount } from "../../utils/helpers";
import SideCartItem from "./SideCartItem";

const SideCart = ({cartStatus, switchCart}) => {
    const [openMenu, setOpenMenu] = useState(false)
    const { cart } = useSelector(state=>state.order)
    const { showMobileCart, showMobileMenu } = useSelector(state=>state.app)
    const cartTotal = cart.reduce((n, {total}) => n + total, 0)
   
  return (
    <aside className={`slide-bar ${cartStatus?"show":""}`}>
      <div className="close-mobile-menu" onClick={switchCart}>
        <a href="#">
          <i className="fal fa-times" />
        </a>
      </div>
      {/* sidebar-info start */}
      <div className="cart_sidebar" style={{display:showMobileCart?"block":"none"}}>
        <h2 className="heading_title text-uppercase">
          Cart Items - <span>{cart.length}</span>
        </h2>
        <div className="cart_items_list">
         { cart.map((item, index)=><SideCartItem item={item} key={`dbs-${Math.floor(Math.random()*9999)}-${index}`}/>)}
        </div>
        <div className="total_price text-uppercase">
          <span>Sub total:</span>
          <span>{displayAmount(cartTotal,2)}</span>
        </div>
        <ul className="btns_group ul_li">
          <li>
            <Link to="/cart" className="thm_btn text-uppercase">
              View Cart
            </Link>
          </li>
          <li>
            <Link
              to="/checkout"
              className="thm_btn thm_btn-2 text-uppercase"
            >
              Checkout
            </Link>
          </li>
        </ul>
      </div>
      {/* sidebar-info end */}
      {/* side-mobile-menu start */}
      <nav className="side-mobile-menu" style={{display:showMobileMenu?"block":"none"}}>
        {/* <div className="header-mobile-search">
          <form role="search" method="get" action="#">
            <input type="text" placeholder="Search Keywords" />
            <button type="submit">
              <i className="ti-search" />
            </button>
          </form>
        </div> */}
        <ul id="mobile-menu-active" className="metismenu">
          <li className=""><Link to="/">Home</Link></li>
          {/* <li className=""><Link to="/about">About</Link></li> */}
          <li className={`dropdown ${openMenu?"mm-active":""}`} onClick={()=>setOpenMenu(!openMenu)}>
            <a href="#" onClick={()=>setOpenMenu(!openMenu)}>Menu</a>
            <ul className={`sub-menu mm-collapse ${openMenu?"mm-show":""}`}>
                        <li> <Link to="/menu/rice">Rice</Link> </li>
                        <li> <Link to="/menu/national">National</Link> </li>
                        <li> <Link to="/menu/protein">Protein</Link> </li>
                        <li> <Link to="/menu/pizza">Pizza</Link> </li>
                        <li> <Link to="/menu/drinks">Drinks</Link> </li>
                        <li> <Link to="/menu/pastries">Pastries</Link> </li>
                        <li> <Link to="/menu/all">View All Menu</Link> </li>
            </ul>
            </li>
          <li className=""><Link to="/restaurants">Our Restaurants</Link></li>
          {/* <li className=""><Link to="/blog">Blog</Link></li> */}
          <li className=""><Link to="/contact">Contact</Link></li>

        </ul>
      </nav>
      {/* side-mobile-menu end */}
    </aside>
  );
};

export default SideCart;
