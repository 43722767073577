import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { getLocations } from '../api/app.api'
import WebsiteLayout from '../layouts/WebsiteLayout'
import { makeTitleCase, showAlert } from '../utils/helpers'

const Outlets = () => {
  const [locations, setLocations] = useState(null)
  const [allLocations, setAllLocations] = useState([])
  const { data, isLoading, isSuccess } = useQuery(['all-outlets'], getLocations, {
    onSuccess: (data) => {
        if(!data.status){
          showAlert({
            title:"Error!",
            msg:"Unable to get restaurant locations, Try again",
            icon:"error",
            btnText:"Close"
          })
        }
        setLocations(data?.value)
        setAllLocations(data?.value)
    },
    onError: (err)=>{
      showAlert({
        title:"Error!",
        msg:"Unable to get restaurant locations, Try again",
        icon:"error",
        btnText:"Close"
      })
    }
  })

  const handleChange = (e) => {
    e.preventDefault();
    let filteredLocations;
    let val = e.target.value;
    filteredLocations = val === "all"? allLocations :allLocations.filter(item=>item.state===val)
    setLocations(filteredLocations)
  }
  
  return (
    <WebsiteLayout>
   <section className="team_area pt-120 pb-90">
  <div className="container">
  <div className="shop_filter_bar">
  <div className="row">
    <div className="col-lg-12">
        <div className="pricing_range" style={{alignItems:"center", justifyContent:"center", textAlign:"center"}}>
            <div className="form-group" style={{display:"flex", alignItems:"center"}}>
              <label style={{width:200}} htmlFor='locationSelect'  className="item_title text-uppercase mr-3">Choose City</label>
              <select className='select' id='locationSelect' onChange={handleChange}>
                <option value="all">All Outlets</option>
                <option value="rivers">Port Harcourt</option>
                <option value="abuja">Abuja</option>
                <option value="lagos">Lagos</option>
                <option value="imo">Owerri</option>
                <option value="enugu">Enugu</option>
                <option value="delta">Warri</option>
                <option value="edo">Benin</option>
              </select>
            </div>
          
        </div>
    </div>
  </div>
</div>

    <div className="row ">
    {
        isLoading && Array(6).fill().map((val)=><OutletLoading key={`hdh-${Math.floor(Math.random()*9999)}-${val}`}/>) 
      }
       {!isLoading && isSuccess && locations && locations?.map((item, index)=><OutletCard  key={`gdhwb-${Math.floor(Math.random()*9999)}-${index}`} item={item} />)}
    </div>
  </div>
</section>

    </WebsiteLayout>
  )
}


const OutletCard = ({item}) =>(
  <div className="col-lg-4 col-md-6 col-sm-6" >
  <div className="team_single pb-3 pt-3" style={{minHeight:120, maxHeight:120, overflow:"hidden"}}>
    <div className="team_text" style={{margin:0}} >
      <table>
        <tbody>
        <tr style={{color:"#000", fontSize:15, fontWeight:600,}}>
          <td><i style={{color:"#fd0000"}} className='fa fa-store-alt mr-2'></i></td>
          <td> {makeTitleCase(item?.name)}</td>
        </tr>
        <tr style={{fontSize:14,}} className="">
          <td><i className='fa fa-map-marker-alt ml-1'></i></td>
          <td> {makeTitleCase(item?.address)}</td>
        </tr>
        {/* <tr style={{fontSize:14,}}>
          <td><i className='fa fa-phone ml-1'></i></td>
          <td> {item?.phone}</td>
        </tr> */}
        </tbody>
      </table>
    </div>
  </div>
</div>
)

const OutletLoading = () => ( <div className="col-lg-4 col-md-6 col-sm-6">
<div className="team_single pb-5 pt-5">
  <div className="team_text text-center" style={{margin:0}} >
   <Spinner animation='border' size='md'/>
  </div>
</div>
</div>)

export default Outlets