import axios from "axios";
import { API_URL } from "../configs/app";
import { setAdminHeader, setHeader } from "../utils/helpers";

export const checkCouponCode = async (data) => {
  let res = await axios.post(`${API_URL}/admin/coupon/verify`, data);
  return res.data;
};
// export const checkCouponCode = async (data) => {
//   let res = await axios.post(`${API_URL}/admin/coupon/verify`, data);
//   return res.data;
// };

export const getSpecialOffers = async (data) => {
  let res = await axios.get(`${API_URL}/admin/offers/get`);
  return res.data;
};

export const login = async (data) => {
  let res = await axios.post(`${API_URL}/admin/login`, data);
  return res.data;
};

export const createFailedTransaction = async (data) => {
  let header = await setAdminHeader();
  let res = await axios.post(`${API_URL}/report/failed-transactions`, data, header);
  return res.data;
};

export const getOutletByToken = async () => {
  let header = await setAdminHeader();
  let res = await axios.get(`${API_URL}/admin/outlet-details`, header);
  return res.data;
};

export const storeAdminNotifToken = async (data) => {
  try {
    const res = await axios.post(`${API_URL}/admin/notification/store`, data);
    return res.data;
  } catch (err) {
    return {
      error: true,
      msg: err,
    };
  }
};

export const getAllOutletOrders = async ({ queryKey }) => {
  const res = await axios.get(
    `${API_URL}/admin/alll-outlet-orders?page=${queryKey[1]}&limit=${queryKey[2]}&period=${JSON.stringify(queryKey[3])}&outlet=${queryKey[4]}&status=${queryKey[5]}${queryKey[6]?`&channel=${queryKey[6]}`:""}`
  );
  return res.data;
};

export const getOutletOrders = async ({ queryKey }) => {
  let header = await setAdminHeader();
  const res = await axios.get(
    `${API_URL}/admin/outlet-orders?page=${queryKey[1]}&limit=${queryKey[2]}&period=${queryKey[3]}&status=${queryKey[4]}`,
    header
  );
  return res.data;
};

export const getOutletProducts = async ({ queryKey }) => {
  let header = await setAdminHeader();
  const res = await axios.get(
    `${API_URL}/admin/outlet-products?page=${queryKey[1]}&limit=${queryKey[2]}`,
    header
  );
  return res.data;
};

export const updateProductAvailability = async ({ queryKey }) => {
  let header = await setAdminHeader();
  const res = await axios.get(
    `${API_URL}/admin/outlet-products?page=${queryKey[1]}&limit=${queryKey[2]}`,
    header
  );
  return res.data;
};

export const updateOrder = async (data) => {
  let { type, id } = data;
  try {
    const resp = await axios.get(`${API_URL}/admin/update-order/${type}/${id}`);
    return resp.data;
  } catch (err) {
    return {
      error: true,
      msg: err,
    };
  }
};

export const fetchProducts = async ({ queryKey }) => {
  const res = await axios.get(`${API_URL}/admin/products/${queryKey[1]}`);
  return res.data;
};

export const fetchPaginatedProducts = async ({ queryKey }) => {
  console.log(queryKey)
  const res = await axios.get(
    `${API_URL}/admin/products/${
      queryKey[1] ? queryKey[1] : "631f264b046f9a6c2cec795a"
    }?page=${queryKey[2]}&limit=${queryKey[3]}`
  );
  return res.data;
};

export const getOrderDetailsById = async ({ queryKey }) => {
  try {
    const res = await axios.get(`${API_URL}/admin/order/details/${queryKey[1]}`);
    return res.data;
  } catch (err) {
    return {
      error: true,
      msg: err,
    };
  }
};

export const getLocations = async () => {
  try {
    const resp = await axios.get(`${API_URL}/admin/locations`);
    return resp.data;
  } catch (err) {
    return {
      error: true,
      msg: err,
    };
  }
};

export const initializeOrder = async (data) => {
  try {
    const res = await axios.post(`${API_URL}/admin/order/initialize`, data);
    return res.data;
  } catch (err) {
    return {
      error: true,
      msg: err,
    };
  }
};
export const modifyOrder = async (data) => {
  try {
    const res = await axios.post(`${API_URL}/admin/order/modify`, data);
    return res.data;
  } catch (err) {
    return {
      error: true,
      msg: err,
    };
  }
};

export const deleteProduct = async (data) => {
  try {
    let header = await setAdminHeader();
    const res = await axios.post(`${API_URL}/admin/outlet/product/delete`, data, header);
    return res.data;
  } catch (err) {
    return {
      error: true,
      msg: err,
    };
  }
};

export const createSingleProduct = async (data) => {
  try {
    // console.log(data); return;
    let header = await setAdminHeader();
    const res = await axios.post(`${API_URL}/admin/outlet/product/create`, data, header);
    return res.data;
  } catch (err) {
    return {
      error: true,
      msg: err,
    };
  }
};

export const updateSingleProduct = async (data) => {
  try {
    // console.log(data); return;
    let header = await setAdminHeader();
    const res = await axios.post(`${API_URL}/admin/outlet/product/update`, data, header);
    return res.data;
  } catch (err) {
    return {
      error: true,
      msg: err,
    };
  }
};

export const toggleProdAvailability = async (data) => {
  try {
    let header = await setAdminHeader();
    const res = await axios.post(`${API_URL}/admin/outlet/product/toggle`, data, header);
    return res.data;
  } catch (err) {
    return {
      error: true,
      msg: err,
    };
  }
};

export const getOutletEarnings = async (data) => {
  try {
    const res = await axios.post(`${API_URL}/admin/outlet/earnings`, data);
    return res.data;
  } catch (err) {
    return {
      error: true,
      msg: err,
    };
  }
};

export const getAllCustomers = async ({ queryKey }) => {
  try {
    const res = await axios.get(
      `${API_URL}/admin/customers-details?page=${queryKey[1]}&limit=${queryKey[2]}&filter=${queryKey[3]}&period=${queryKey[4]}`
    );
    return res.data;
  } catch (err) {
    return {
      error: true,
      msg: err,
    };
  }
};

export const getAllUsers = async ({ queryKey }) => {
  try {
    const res = await axios.get(
      `${API_URL}/admin/users-details?page=${queryKey[1]}&limit=${queryKey[2]}`
    );
    return res.data;
  } catch (err) {
    return {
      error: true,
      msg: err,
    };
  }
};

export const getFailedTransactions = async ({ queryKey }) => {
  let header = await setAdminHeader();
  try {
    const res = await axios.get(
      `${API_URL}/report/failed-transactions?page=${queryKey[1]}&limit=${queryKey[2]}&status=${queryKey[3]}`, header
    );
    return res.data;
  } catch (err) {
    return {
      error: true,
      msg: err,
    };
  }
};

export const updateTransaction = async (data) => {
  let header = await setAdminHeader();
  let res = await axios.put(`${API_URL}/report/failed-transactions`, data, header);
  return res.data;
};

export const getOutlets = async () => {
  let res = await axios.get(`${API_URL}/admin/outlets/`);
  return res.data;
};

export const updateStoretimes = async (data) => {
  let res = await axios.post(`${API_URL}/admin/storetimes/update`, data);
  return res.data;
};
export const createCouponCode = async (data) => {
  let res = await axios.post(`${API_URL}/admin/coupon/create`, data);
  return res.data;
};
export const getCouponCode = async () => {
  let res = await axios.get(`${API_URL}/admin/coupon`);
  return res.data;
};
export const deleteCouponCode = async (id) => {
  let res = await axios.get(`${API_URL}/admin/coupon/delete/${id}`);
  return res.data;
};
export const updateCoupon = async (data) => {
  let res = await axios.post(`${API_URL}/admin/coupon/update`, data);
  return res.data;
};
export const getUsersSpend = async (data) => {
  let res = await axios.post(`${API_URL}/admin/users/spend`, data);
  return res.data;
};

export const fetchReport = async ({queryKey}) => {
  let url = queryKey[1] === "6363d70f563e316cd40f12e0" ? `${API_URL}/report?x=x` : `${API_URL}/report?outlet=${queryKey[1]}`
  url = `${url}&timeframe=${queryKey[2]}`
  // console.log(url)
  let header = await setAdminHeader();
  const res = await axios.get(url,header);
  return res.data;
};

export const fetchCustomerPurchaseHistory= async (phone) => {
  let url = `${API_URL}/report/customer-history?phone=${phone}` 
  console.log(url)
  let header = await setAdminHeader();
  const res = await axios.get(url,header);
  return res.data;
};