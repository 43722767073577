import React from "react";
import { Link } from "react-router-dom";

const FastDelivery = () => {
  return (
    <section className="cta_area mb-120">
      <div className="container">
        <div className="cta_bg_2">
          <div className="row">
            <div className="col-lg-6">
              <div className="cta_text_wrap">
                <div className="sec_title">
                  <span>Fastest delivery</span>
                  <h2>Fastest delivery on your door step</h2>
                </div>
                <div className="cta_btn mt-30">
                  <Link className="thm_btn" to="/order">
                    order Now
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="cta_img">
                <img src="assets/img/bg/cta_img.png" alt />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FastDelivery;
