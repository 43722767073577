import React, { Fragment, useState, useEffect } from "react";
import { Form, Spinner } from "react-bootstrap";
import { ChevronDown, ChevronRight, Map, MapPin } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedOutlet,
  setSelectedState,
} from "../../store/slices/orderSlice";
import { calcCartTotal, makeTitleCase, showAlert } from "../../utils/helpers";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import useWindowDimensions from "../useWindowDimensions";
import {
  calcDistanceByCordinates,
  getAddressByPoints,
  getAddressSuggestions,
} from "../../api/order.api";
import { useQuery } from "@tanstack/react-query";
import { getDistance } from "geolib";
import Geocode from "react-geocode";
import { GooglePlacesKey } from "../../configs/app";
import { GoogleMap, Marker, withGoogleMap } from "react-google-maps";

Geocode.setApiKey(GooglePlacesKey);
Geocode.setLanguage("en");
Geocode.setRegion("ng");
Geocode.setLocationType("ROOFTOP");

const StateAndOutletSelect = ({ type, ...props }) => {
  let dispatch = useDispatch();
  let isDelivery = type === "delivery" ? true : false;
  const { width: pageWidth } = useWindowDimensions();
  let isMobile = pageWidth <= 767 ? true : false;
  const { states, outlets } = useSelector((state) => state.app);
  const { selectedState, selectedOutlet } = useSelector((state) => state.order);
  const [stateOutlets, setStateOutlets] = useState(null);
  const [showSelects, setShowSelects] = useState(isDelivery ? false : true);
  const [address, setAddress] = useState(null);
  const [hasSelected, setHasSelected] = useState(false);
  const [suggestedPlaces, setSuggestedPlaces] = useState(null);
  const [placeCordinate, setPlaceCordinate] = useState([0, 0]);
  const [loadingDistance, setLoadingDistance] = useState(false);
  const [loadingCurrentLocation, setLoadingCurrentLocation] = useState(false);
  const [addressCords, setAddressCords] = useState(null);
  // {
  //   lat: 6.445156938262765,
  //   lng: 3.4578440883366706,
  // }
  const handleStateChange = (e) => {
    let selState = states.find((state) => state.slug === e.target.value);
    dispatch(setSelectedState(selState));
    let stateOutlet = outlets.filter((outlet) => outlet.state === selState._id);
    setStateOutlets(stateOutlet);
  };

  const handleSelectToggle = () => {
    setShowSelects((prev) => !prev);
  };

  const handleChange = (e) => {
    let address = e; //e.target.value;
    setAddress(address);
    setHasSelected(false);
  };

  const {
    isLoading: loadingSuggestions,
    refetch: fetchSuggestions,
    isFetching,
  } = useQuery(["suggestions", address], getAddressSuggestions, {
    enabled: false,
    onSuccess: (data) => {
      let places = data?.resourceSets[0]?.resources;
      setSuggestedPlaces(places);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const getSuggestions = async () => {
    if (address.length > 2) {
      fetchSuggestions();
    } else {
      setSuggestedPlaces(null);
    }
  };

  useEffect(() => {
    if (address) getSuggestions();
  }, [address]);

  // const handleSelect = async (address) => {
  //   console.log(address)
  //   setAddress(address?.name.toLowerCase());
  //   setHasSelected(true);
  //   setPlaceCordinate(address?.point?.coordinates)
  //   // let results = await geocodeByAddress(address);
  //   // let latLng = await getLatLng(results[0]);
  // };

  const handleSelect = async (address) => {
    setAddress(address.toLowerCase());
    setHasSelected(true);
    let results = await geocodeByAddress(address);
    let latLng = await getLatLng(results[0]);
    setAddressCords(latLng);
  };

  // const getDistance = async (origin, destinations) => {
  //   setLoadingDistance(true)
  //   let res = await calcDistanceByCordinates(origin, destinations)
  //   setLoadingDistance(false)
  //   if(res.statusCode == 200){
  //     let data = res?.resourceSets[0].resources[0].results
  //     return data;
  //   }else{

  //   }
  // }

  //   const getClosestOutlet = async (address) => {
  //     let origin = placeCordinate.join()
  //     let destinations = outlets.map(outlet=>`${outlet.location.lat},${outlet.location.lng}`);
  //     destinations = destinations.join(";")
  //     let distance = await getDistance(origin, destinations)
  //     let shortestDistance = distance.reduce((item, current) =>
  //       item['travelDistance'] < current['travelDistance'] ? item : current,
  //     );
  //    console.log(shortestDistance.travelDistance)
  //     if(Math.round(shortestDistance.travelDistance) > 10){
  //       return showAlert({
  //         title: "Oops!",
  //         msg: `We do not deliver to ${address} at the moment`,
  //         icon: "warning",
  //         btnText: "Try a closer address",
  //       });
  //     }
  //     let selOutlet = outlets[shortestDistance.destinationIndex]
  //     let selState = states.find((state) =>state._id === selOutlet.state);
  //     if (!selState) {
  //       //show not found
  //       return showAlert({
  //         title: "Oops!",
  //         msg: `We do not deliver to ${address} at the moment`,
  //         icon: "warning",
  //         btnText: "Try another address",
  //       });
  //     }
  //   dispatch(setSelectedState(selState));
  //  dispatch(setSelectedOutlet(selOutlet.slug));
  //   };

  const getClosestOutlet = () => {
    setLoadingDistance(true);
    let val = outlets?.map((item) => ({
      outlet: {
        ...item,
        id: item._id,
        name: item.name,
        slug: item.slug,
        state: item.state,
        delivering_to: item.delivering_to,
        closed: item.closed,
        location: item.location,
      },
      distance: getDistance(addressCords, item.location, 1),
    }));
    let selectedOutlet = val.reduce((item, current) =>
      item["distance"] < current["distance"] ? item : current
    );
    setLoadingDistance(false);
    if (Math.round(selectedOutlet.distance) > 8000) {
      return showAlert({
        title: "Oops!",
        msg: `We do not deliver to ${address} at the moment`,
        icon: "warning",
        btnText: "Try a closer address",
      });
    }
    // setShowLoadingModal(false)
    let selState = states.find(
      (state) => state._id === selectedOutlet.outlet.state
    );
    if (!selState) {
      //show not found
      return showAlert({
        title: "Oops!",
        msg: `We do not deliver to ${address} at the moment`,
        icon: "warning",
        btnText: "Try another address",
      });
    }
    dispatch(setSelectedState(selState));
    dispatch(setSelectedOutlet(selectedOutlet.outlet._id));
  };

  // const handleCurrentLocation = (e) => {
  //   e.preventDefault();
  //   setLoadingCurrentLocation(true)
  //   navigator.geolocation.getCurrentPosition(
  //     async (position)=>{
  //       let points = `${position.coords.latitude},${position.coords.longitude}`

  //     let res = await getAddressByPoints(points)
  //     setLoadingCurrentLocation(false);
  //     let address = res?.resourceSets[0].resources[0].name
  //     setAddress(address);
  //     setHasSelected(true);
  //     setPlaceCordinate([position.coords.latitude,position.coords.longitude])
  //     },
  //     (err)=>{
  //       setLoadingCurrentLocation(false);
  //       return showAlert({
  //         title: "Oops!",
  //         msg: `An error has occured`,
  //         icon: "warning",
  //         btnText: "Try again",
  //       });
  //     })
  // }
  const handleCurrentLocation = (e) => {
    e.preventDefault();
    setLoadingCurrentLocation(true);
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        let initialAddress = await Geocode.fromLatLng(
          position.coords.latitude,
          position.coords.longitude
        );
        let address = initialAddress.results[0].formatted_address;
        setLoadingCurrentLocation(false);
        setAddress(address);
        setHasSelected(true);
        setAddressCords({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      (err) => {
        setLoadingCurrentLocation(false);
        return showAlert({
          title: "Oops!",
          msg: `An error has occured, Please allow location from browser settings`,
          icon: "warning",
          btnText: "Try again",
        });
      }
    );
  };
  const handleMarkerDrag = (e) => {
    console.log( e.nativeEvent)
    let {latitude:lat, longitude:lng} = e.nativeEvent.coordinate
    setAddressCords(lat,  lng)
   }
  const MapWithAMarker = withGoogleMap(({ coords }) => (
    <GoogleMap defaultZoom={16} defaultCenter={coords}>
      <Marker draggable  position={coords} onD onDragEnd={handleMarkerDrag} />
    </GoogleMap>
  ));

  return (
    <Form style={{ padding: 15 }}>
      {/*  */}
{/* // DELIVERY FORM */}
{isDelivery && (
  <>
    <Form.Group className="mb-3" controlId="stateSelect">
      <Form.Label>Enter a location/area close to you</Form.Label>

      <PlacesAutocomplete
        value={makeTitleCase(address)}
        onChange={handleChange}
        onSelect={handleSelect}
        searchOptions={{
          componentRestrictions: { country: ['ng'] },
        }}
      >
        {({
          getInputProps,
          suggestions,
          getSuggestionItemProps,
          loading,
        }) => (
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
              }}
            >
              <div style={{ minWidth: isMobile ? "60%" : "70%" }}>
                {" "}
                <Form.Control
                  {...getInputProps({
                    placeholder: "Enter your address...",
                    className: "location-search-input",
                    size: "md",
                  })}
                />
                {!hasSelected && address && (
                  <Form.Text className="text-muted danger-text">
                    <small style={{ color: "#e2333f" }}>
                      Kindly select a location from the list
                    </small>
                  </Form.Text>
                )}
              </div>
              <button
                disabled={!hasSelected}
                type="button"
                className="btn btn-danger btn-md"
                style={{
                  marginLeft: 20,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => getClosestOutlet()}
              >
                {/* <MapPin size={14} /> */}
                {loadingDistance ? (
                  <Spinner size="sm" animation="border" />
                ) : (
                  <MapPin size={14} />
                )}
                <span style={{ fontSize: 14, marginLeft: 5 }}>
                  {" "}
                  Continue
                </span>
              </button>
            </div>
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? {
                      backgroundColor: "#dedede",
                      cursor: "pointer",
                      color: "#6d6d6d",
                    }
                  : {
                      backgroundColor: "#ffffff",
                      cursor: "pointer",
                      color: "#6d6d6d",
                    };
                return (
                  <button
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                    key={`djd-${Math.floor(Math.random() * 9999)}`}
                  >
                    {suggestion.description}
                  </button>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>

      {/* <div style={{ display: "flex", flexDirection: "row" }}>
        <Form.Control
          size="md"
          onChange={handleChange}
          value={makeTitleCase(address)}
        />
        <button
          disabled={!hasSelected || loadingDistance}
          type="button"
          className="btn btn-danger btn-md"
          style={{
            marginLeft: 20,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={() => getStateByAddress(address)}
        >
          {loadingDistance?<Spinner size="sm" animation="border" />:<MapPin size={14} />}
          <span style={{ fontSize: 14, marginLeft: 5 }}> Continue</span>
        </button>
      </div> */}
      {/* {!hasSelected && address && (
        <Form.Text className="text-muted danger-text">
          <small style={{ color: "#e2333f", fontSize:12 }}>
           Select a location from the list
          </small>
        </Form.Text>
      )}
      <div>
        {isFetching && (
          <Spinner animation="border" color={"red"} size={"sm"} />
        )}

        {
          !isFetching && suggestedPlaces && suggestedPlaces.map(place=>
            <button onClick={(e)=>{
              e.preventDefault();
               handleSelect(place);
               }
               } key={`djd-${Math.floor(Math.random() * 9999)}`}>
              <p style={{fontSize:16}}>{place?.name}</p>
            </button>)
        }
      </div> */}
    </Form.Group>
    <div style={{marginBottom:15}}>
      <button
        disabled={loadingCurrentLocation}
        onClick={handleCurrentLocation}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: loadingCurrentLocation ? "#ccc" : "red",
        }}
      >
        <MapPin size={12} />
        <span
          style={{
            fontSize: 14,
            marginLeft: 10,
            color: loadingCurrentLocation ? "#ccc" : "red",
          }}
        >
          {loadingCurrentLocation?"Please wait...":"Use my current location"}
        </span>
      </button>
    </div>
    {addressCords && (
      <MapWithAMarker
        containerElement={<div style={{ height: `250px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
        coords={addressCords}
      />
    )}
    {/* <GoogleMap
        defaultZoom={8}
        defaultCenter={{ lat: addressCords.lat, lng: addressCords.lng }}
        {...props}
      >
      <Marker position={{ lat: addressCords.lat, lng: addressCords.lng }} />
      </GoogleMap> */}

    <p className="text-center" style={{ fontSize: 14 }}>
      Or{" "}
    </p>
  </>
)}
     {/* INSERT DELIVERY FORM HERE */}

      <p
        style={{
          color: "#000",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          border: "1px solid #ccc",
          borderLeftWidth: 0,
          borderRightWidth: 0,
          padding: "10px 0",
          cursor: "pointer",
        }}
        onClick={handleSelectToggle}
      >
        <span>
          {isDelivery
            ? "Search by restaurant location"
            : "Select outlet to pickup your order"}
        </span>
        {showSelects ? <ChevronDown size={20} /> : <ChevronRight size={20} />}
      </p>

      {showSelects && (
        <>
          <Form.Group className="mb-3" controlId="stateSelect">
            <Form.Label>Where are you ordering from?</Form.Label>
            <Form.Select
              aria-label="Select State"
              size="md"
              value={selectedState?.slug}
              onChange={handleStateChange}
            >
              <option value={null}>Select State</option>
              {states?.map((state, index) => (
                <option
                  key={`gedh-${Math.floor(Math.random() * 9999)}-${index}`}
                  value={state.slug}
                >
                  {makeTitleCase(state.name)}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3" controlId="outletSelect">
            <Form.Label>Choose a restaurant outlet</Form.Label>
            <Form.Select
              aria-label="Select Outlet"
              size="md"
              value={selectedOutlet?._id}
              onChange={(e) => dispatch(setSelectedOutlet(e.target.value))}
              disabled={!selectedState ? true : false}
            >
              {!stateOutlets ||
                (!selectedState && (
                  <option value={null}>Select restaurant</option>
                ))}
              <option value={null}>Select restaurant</option>
              {stateOutlets &&
                selectedState &&
                stateOutlets.map((data, index) => (
                  <option
                    key={`hew-${Math.floor(Math.random() * 9999)}-${index}`}
                    value={data._id}
                  >
                    {data.name}
                  </option>
                ))}
            </Form.Select>
          </Form.Group>
        </>
      )}
    </Form>
  );
};

export default StateAndOutletSelect;



