import React, {useState, useEffect} from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { addItemToCart } from "../../store/slices/adminSlice";
import { displayAmount, makeTitleCase, showToast } from "../../utils/helpers";
import ProductVariantModal from "../Modals/ProductVariantModal";

const ItemSingle = ({ product }) => {
    let dispatch = useDispatch();
    const [isVariable, setIsVariable] = useState(false)
    const [payloadModified, setPayloadModified] = useState(false)
    let [payload, setPayload] = useState({ product, quantity:1, id:product.uid, isVariable:false, isAdmnCreated:true })
    const addToCart = async () => {
      // let payload = { product, quantity:1, id:product.uid, isVariable:false, isAdmnCreated:true }
        if(product?.variable){ setIsVariable(product?.varieties); return;}
        dispatch(addItemToCart(payload))
        showToast("success",`${payload.product.name} has been added to cart`)
    }

    const setProductVariant = (data) => {
      // let payload = { product, quantity:1, id:product.uid, isVariable:false, isAdmnCreated:true }
            setPayload(prev=>({
                ...payload,
                id: `${product.uid}-${data.title}`,
                product: {...payload.product, oQuantity:1, oSize:data, oTotal: 1 * data?.price},
                isVariable:true
            }))
            setPayloadModified(true)
    }

    useEffect(() => {
      if(payloadModified){
        dispatch(addItemToCart(payload))
        setPayloadModified(false)
        showToast("success",`${payload.product.name} (${payload?.product?.oSize?.title}) has been added to cart`)
      }
    }, [payloadModified])
    

  return (<>
    <Card className="mb-3" style={{ width: "10rem", cursor:"pointer"}} onClick={addToCart}>
    <Card.Img variant="top" src={product.image_url} style={{maxHeight:80}} />
    <Card.Body>
      <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
        <span>{makeTitleCase(product.name)}</span>
        <span style={{marginLeft:5}}><strong>{displayAmount(product.product_price)}</strong></span>
      </div>
    </Card.Body>
  </Card>

  {isVariable && <ProductVariantModal setVariant={setProductVariant} variants={isVariable} show={true} close={()=>setIsVariable(false)}  />} 
  </>
  )
}

export default ItemSingle