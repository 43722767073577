import React from "react";

const About = () => {
  return (
    <section className="services_area services_space white_bg">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="services_img">
              <img src={require("../../assets/images/gen_ban.png")} alt=""/>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="services_content">
              <div className="sec_title">
                <span>Quality Service</span>
                <h2>Good Food, Drinks and Great Service.</h2>
                <p>
                  For over 30 years, We have been providing fresh and tasty meals all across Africa. 
                Our experience in service coupled with our wide variety of meals to choose from stands us out in the hospitality business.
                </p>
                <p>
                  We appreciate your trust greatly. Our clients choose us and
                  our services because they know we are the best.
                </p>
              </div>
              <div className="experience_text">
                <h1>
                  30<span>+</span>
                </h1>
                <h2>
                  <span>Years Of</span> <br />
                  Service
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
