import React from 'react'
import American from '../components/Breakfast/American'
import FullEnglish from '../components/Breakfast/FullEnglish'
import Jumbotron from '../components/Breakfast/Jumbotron'
import Others from '../components/Breakfast/Others'
import SweetChoice from '../components/Breakfast/SweetChoice'
import Uaffi from '../components/Breakfast/Uaffi'
import WebsiteLayout from '../layouts/WebsiteLayout'

const BreakfastMenu = () => {
  return (
    <WebsiteLayout isBreakfast={true}>
      <Jumbotron/>
      <FullEnglish/>
      <SweetChoice/>
      <American/>
      {/* <Uaffi/> */}
      <Others/>


    </WebsiteLayout>
  )
}

export default BreakfastMenu