import React from 'react'
import useWindowDimensions from '../useWindowDimensions'

const American = () => {
    let { width } = useWindowDimensions()
    let isMobile = width <= 767 ? true : false
  return (
    <section className="services_area services_space white_bg" style={{paddingTop:30}}>
    <div className="container">
      <div className="row align-items-center">
      <div className="col-lg-6">
          <div className="services_img">
            <img src={require("../../assets/images/breakfast_3.png")} alt="" style={{scale:"0.8"}}/>
          </div>
        </div>
      <div className="col-lg-6">
          <div className="services_content">
            <div className="sec_title " style={{textAlign:isMobile?"center":""}}>
              <h2>American</h2>
              <ul className='bkfst'>
                <li>Sausage</li>
                <li>Pancakes</li>
                <li>Choice of topping:
                    <ol>
                        <li>Plain Syrup</li>
                        <li>Honey</li>
                        <li>Chocolate</li>
                    </ol>
                </li>
                </ul>
            </div>
            <div className="experience_text">
              <h1 style={{fontSize:42}}>
              ₦2,000
              </h1>
            </div>
          </div>
        </div>
       
       
      </div>
    </div>
  </section>
  )
}

export default American