import React from 'react'

const bg = require('../../assets/images/bg_offer.webp')
const SweetChoice = () => {
  return (
   <section className="offer_area offer_2 offer_img_bg pt-120 pb-120" style={{background:`url(${bg})`}}>
  <div className="container">
    <div className="row align-items-center">
      <div className="col-xl-6 offset-xl-6 col-lg-8 offset-lg-4">
        <div className="offer_content">
          <div className="sec_title sec_title-white">
            {/* <span>Special Kombo Pack</span> */}
            <h2>Sweet Choice</h2>
           <p> <ul className='bkfst'>
                <li>Waffles</li>
                <li>Choice of topping: 
                    <ol>
                        <li>Plain Syrup</li>
                        <li>Honey</li>
                        <li>Chocolate</li>
                        <li>Ice Cream</li>
                    </ol>
                </li>
                <li></li>
                <li></li>
            </ul>
            </p>
          </div>
          <ul className="offer_btn ul_li">
            <li>
              <div className="offer_price">
                <h4> ₦2,000</h4>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>

  )
}

export default SweetChoice