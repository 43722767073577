import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Button, Tabs, Tab, Form } from "react-bootstrap";
import { X } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { setDeliveryType } from "../../store/slices/orderSlice";
import { makeTitleCase } from "../../utils/helpers";
import { useQuery } from "@tanstack/react-query";

import { getOutlets, getOutletsByState, getStates } from "../../api/app.api";
import { closeInitialOrder, setOutlets, setState } from "../../store/slices/appSlice";
import StateAndOutletSelect from "./StateAndOutletSelect";

const InitialOrderChoice = () => {
  let dispatch = useDispatch();
  let { deliveryType, selectedOutlet, selectedState } = useSelector((state) => state.order);
  let { states, showInitialOrder } = useSelector((state) => state.app);

  let { data, isLoading, isError, error, isSuccess, refetch } = useQuery( ["getStates"], getStates,
    { enabled: false, }
  );

  let {
    data: outletData,
    isLoading: isLoadingOutlet,
    isError: isErrorOutlet,
    error: errorOutlet,
    isSuccess: isSuccessOutlet,
    refetch: refetchOutlet,
  } = useQuery(["getAllOutlets"], getOutlets, {
    enabled: false,
  });

  useEffect(() => {
    if (!states) {
      refetch();
      refetchOutlet();
    }
  }, []);

  useEffect(() => {
    if (isSuccess) { dispatch(setState(data?.value)); }
    if(isSuccessOutlet){dispatch(setOutlets(outletData?.value))}
  }, [isSuccess, isSuccessOutlet]);

  const handleTabChange = (type) => {
    dispatch(setDeliveryType(type));
  };
  


  return (
    <Modal
      show={(!selectedOutlet || !deliveryType) && showInitialOrder}
      onHide={() => dispatch(closeInitialOrder())}
      keyboard={false}
      size={"md"}
      centered
      backdrop="static"
    >
      <Modal.Body style={{ padding: 0 }}>
        <Tabs
          defaultActiveKey="delivery"
          activeKey={deliveryType}
          className="mb-3"
          fill
          justify
          onSelect={handleTabChange}
        >
          <Tab eventKey="delivery" title={<DeliveryTitle deliveryType={deliveryType}/>}>
            <StateAndOutletSelect type="delivery"/>
          </Tab>
          <Tab eventKey="pickup" title={<TakeAwayTitle deliveryType={deliveryType}/>}>
          <StateAndOutletSelect type="pickup"/>
          </Tab>
          {/* <Tab eventKey="eatin" title="Eat In">
          <StateAndOutletSelect/>
          </Tab> */}
        </Tabs>
      </Modal.Body>
    </Modal>
  );
};

export default InitialOrderChoice;

const DeliveryTitle = ({deliveryType}) =>{
  let isDelivery = deliveryType === "delivery"?true:false
  return(
  <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
  <img src={require("../../assets/images/delivery.gif")} style={{width:25, marginRight:5, filter:isDelivery?"unset":"grayscale(1)"}} />
    <span style={{fontWeight:"600", color:isDelivery?"#e2333f":"#707071"}}>Delivery</span>
  </div>
)
  }

const TakeAwayTitle = ({deliveryType}) =>{
  let isPickup = deliveryType === "pickup"?true:false
  return (
  <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
  <img src={require("../../assets/images/takeaway.gif")} style={{width:25, marginRight:5, filter:isPickup?"unset":"grayscale(1)"}} />
  <span style={{fontWeight:"600", color:isPickup?"#e2333f":"#707071"}}>Pick Up</span>
  </div>
)
  }
// const InitialOrderForm = ({ states, selectedStateFtn, outletData }) => {
//   console.log("outletData", outletData);
//   const [selectedState, setSelectedState] = useState(states ? states[0] : null);
//   const [selectedOutlet, setSelectedOutlet] = useState(
//     outletData ? outletData[0]._id : null
//   );
//   useEffect(() => {
//     if (selectedState) {
//       selectedStateFtn(selectedState);
//     }
//   }, [selectedState]);

//   return (
//     <Form style={{ padding: 15 }}>
//       <Form.Group className="mb-3" controlId="stateSelect">
//         <Form.Label>Where are you ordering from?</Form.Label>
//         <Form.Select
//           aria-label="Select State"
//           size="lg"
//           value={selectedState}
//           onChange={(e) => setSelectedState(e.target.value)}
//         >
//           {states?.map((state) => (
//             <option value={state.slug}>{makeTitleCase(state.name)}</option>
//           ))}
//         </Form.Select>
//       </Form.Group>
//       <Form.Group className="mb-3" controlId="outletSelect">
//         <Form.Label>Select a restaurant outlet</Form.Label>
//         <Form.Select
//           aria-label="Select Outlet"
//           size="lg"
//           value={selectedOutlet}
//           onChange={(e) => setSelectedOutlet(e.target.value)}
//         >
//           {!outletData && <option value={null}>Select restaurant</option>}
//           {outletData &&
//             outletData.map((data) => (
//               <option value={data._id}>{data.name}</option>
//             ))}
//         </Form.Select>
//       </Form.Group>
//     </Form>
//   );
// };
