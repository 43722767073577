import React from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'

const JumbotronSliderImage = ({image}) => {
//   let imageRef = useRef("imageRef")
//   let imgHeight ;
//  useEffect(() => {
//    imgHeight = imageRef.current.naturalHeight
//   console.log(imgHeight)
//  }, [])
 
  return (
    <div className="sliderImage" style={{backgroundImage:`url(${image})`,}}>
    {/* <div className="row align-items-center justify-content-lg-between"> */}
    {/* <img src={image} ref={imageRef}/> */}
    {/* </div> */}
    </div>
  )
}

export default JumbotronSliderImage


// style={{
//   display:"flex",
//   alignItems:"center",
//   justifyContent:"center",
//   height:"80vh",
//   backgroundImage:`url(${image})`,
//   backgroundPosition:"center",
//   backgroundOrigin:"center",
//   backgroundSize:"contain",
//   backgroundRepeat:"no-repeat"
// }}