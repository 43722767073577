import { useMutation, useQuery } from '@tanstack/react-query'
import React, {useState} from 'react'
import { Badge, Card, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap'
import { MapPin, X } from 'react-feather'
import { getUserAddress, removeUserAddress } from '../api/user.api'
import UserLayout from '../layouts/UserLayout'
import { makeTitleCase, showAlert } from '../utils/helpers'

const UserAddress = () => {
    
    const [address, setAddress] = useState(null)
   const { isLoading } = useQuery(['userAddress'], getUserAddress, {
        onSuccess: data => {
                if(!data.status) return showAlert({
                    title: "Oops!",
                    msg: data?.errors[0].msg,
                    icon: "error",
                    btnText: "close",
                  });
                  setAddress(data?.value)
        },
        onError: err => {
            console.log(err)
            showAlert({
                title: "Error!",
                msg: "An internal error has occured",
                icon: "error",
                btnText: "Close",
              });
        }
    })

    const {mutate} = useMutation(removeUserAddress, {
        onSuccess: data => {
            if(data.status){
            let newAddr = address.filter(addr=>addr.id != data?.value?.id)
            setAddress(newAddr)
            }

        }
    })

    const handleDelete = id => {
        let conf = window.confirm("Are you sure?")
        if(!conf) return;
        mutate(id)
    }
  return (
    <UserLayout title="Your Addresses">
          {isLoading && !address && (
        <div
          style={{
            padding: 20,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner size={"md"} animation="border" />{" "}
          <span className="mx-3">Loading</span>
        </div>
      )}


      { address && !isLoading && address.map((addr,index)=><div
          style={{padding: 20,}} key={`gdg-${Math.floor(Math.random()*9999)}e-${index}`}
        >
          <Card className="p-3">
            <div style={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                <div>
                <MapPin size={18} color="red"/>
                <span className='mx-5'>{makeTitleCase(addr.name)}</span>
                </div>

                <div style={{textAlign:"center"}}>
                    {addr.primary && <Badge pill bg="" className='mx-3' style={{backgroundColor:"#e01f31cc"}}>Primary</Badge>}
                    {!addr.primary && <Badge pill bg="secondary" className='mx-3' >Make Primary</Badge>}
                    <OverlayTrigger
          key={addr.id}
          placement="bottom"
          overlay={
            <Tooltip id={`tooltip-bottom`}>
              Delete Address
            </Tooltip>
          }
        >
                    <X className='' onClick={()=>handleDelete(addr.id)} size={20} style={{color:"red", cursor:"pointer"}} />
            </OverlayTrigger>
                </div>

            </div>
          </Card>
          
        </div>)}

      
    </UserLayout>
  )
}

export default UserAddress