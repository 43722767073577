import React, { useState } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { addToCart } from "../../store/slices/orderSlice";
import { displayAmount, makeTitleCase, showToast } from "../../utils/helpers";
import useWindowDimensions from "../useWindowDimensions";


const MenuItemGrid = ({ item }) => {
  let dispatch = useDispatch();
  let history = useNavigate();
  let { width } = useWindowDimensions();
  let isMobile = width <= 767 ? true : false;
  let isTab = width > 767  && width <= 960 ? true : false;
  const [activeVariant, setActiveVariant] = useState(
    item.variable ? item.varieties[0] : null
  );
  const [counter, setCounter] = useState(1);
  const [price, setPrice] = useState(
    !item
      ? null
      : item.sale_price && item.sale_price != null
      ? item.sale_price
      : item.product_price
  );
  let { selectedState, cart } = useSelector((state) => state.order);

  const handleVariableChange = (val) => {
    setActiveVariant(val);
    setPrice(val.price);
  };

  const handleInputChange = (e) => {
    setCounter(e.target.value);
  };
  const handleCounterChange = (type) => {
    if (type == "add") {
      setCounter((prev) => parseInt(prev) + 1);
    } else if (type == "remove") {
      counter > 1? setCounter((prev) => parseInt(prev) - 1): setCounter(1);
    }
  };

  const handleSubmit = () => {
    let productTotal = price * counter
     // get the sum of each "total" in state.cart
     const cartTotal = cart.reduce((sum, item) => sum + item.total, 0);
     const newTotal = cartTotal + productTotal
     const appliedBlackFridayOffer = cart.some(item => item.hasBlackFridayOffer === true);

    let payload = {
      id: Math.floor(Math.random() * 100000000),
      product: item,
      quantity: counter,
      total: productTotal,
      size: item.variable ? activeVariant : null,
    };
    dispatch(addToCart(payload));

    (selectedState.name !== "abuja" && item.name.match(/ram|biryani/gi)) && showToast("success", `You have been gifted ${counter} FREE ${counter > 1 ? 'cokes' : 'coke'}!`);
    if((cart.length >= 1) && (newTotal >= 5000) && !appliedBlackFridayOffer && (new Date().getDay() === 15))
    {showToast("info", "You just got a 25% discount! as part of our Black Friday Offer")}
    
    showToast("success", `${makeTitleCase(item.name)} has been added to cart`);
  };

  return (
    <div
      className="col-lg-3 col-md-4 col-sm-6 grid-item mb-30"
      style={{ width: isMobile ? "50%" : "" }}
    >
      <div className="shop_single white_bg" style={{ padding: 0 }}>
        <div
          className="thumb text-center"
          style={{
            backgroundImage: `url(${item.image_url})`,
            width: "100%",
            height: isMobile ? 100 : 150,
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            cursor: "pointer",
          }}
          onClick={() => history(`/product/${item.slug}`)}
        ></div>
        <div
          className="content"
          style={{ padding: "0 30px 30px 30px", marginTop: 15 }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            <h6
              style={{
                fontWeight: 400,
                fontSize: isMobile ? 13 : 16,
                margin: 0,
                marginTop: 3,
              }}
              className="title"
            >
      
    
        <OverlayTrigger
          key={"top"}
          placement={"top"}
          overlay={
            <Tooltip id={`tooltip-top`}>
             {(item.name).toUpperCase()} <br/> {item.description?item.description:""}
            </Tooltip>
          }
        >
          {
            isMobile
            ?<Link to={`/product/${item.slug}`}>{item?.name?.length > 8 ?<>{makeTitleCase(item.name.substring(0,8))}..</> :makeTitleCase(item.name)}</Link> 
            :<Link to={`/product/${item.slug}`}>{item?.name?.length > 14 ?<>{makeTitleCase(item.name.substring(0,12))}...</> :makeTitleCase(item.name)}</Link> 
          }
                {/* {activeVariant ? `(${activeVariant.title})` : null} */}     
      </OverlayTrigger>
      
      <span className="mt-1" style={{display:"block", fontSize:12, color:item.description?"#969595":"#fff"}}>{item.description?item.description:" ."}</span>
              
            </h6>
            <span
              className="bold"
              style={{
                fontWeight: 600,
                fontSize: isMobile ? 13 : 16,
                color: "#ed366a",
              }}
            >
              {displayAmount(price * counter)}
            </span>
          </div>
          
          {/* <div
            className="description"
            style={{ fontSize: isMobile ? 10 : 12, textAlign: "justify" }}
          >
            {item.description}
          </div> */}

          <div className="variant">
            {item.variable && (
              <div
                style={{
                  backgroundColor: "#e6e6e6",
                  borderRadius: 5,
                  width: "100%",
                  minHeight: 30,
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  justifyContent: "space-evenly",
                  fontSize: isMobile ? 10 : 12,
                  marginTop: 15,
                }}
              >
                {item.varieties.map((cat, index) => (
                  <span
                    style={{
                      backgroundColor:
                        activeVariant?.title === cat.title ? "#fff" : "#e6e6e6",
                      padding: "3px 10px",
                      borderRadius: 5,
                      cursor: "pointer",
                    }}
                    key={`vvs-${Math.floor(Math.random()*9999)}-${index}`}
                    onClick={() => handleVariableChange(cat)}
                  >
                    {makeTitleCase(cat.title)}
                  </span>
                ))}
              </div>
            )}
          </div>
          <div
            className="footer"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: 15,
              flexWrap: "wrap",
            }}
          >
            <div
              className="counter mt-15"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: isMobile || isTab ? "100%" : "",
              }}
            >
              <button
                onClick={() => handleCounterChange("remove")}
                className="btn btn-sm btn-outline-primary"
              >
                -
              </button>
              <input
                type="number"
                value={counter}
                onChange={handleInputChange}
                style={{
                  width: 50,
                  height: 30,
                  padding: 0,
                  paddingLeft: 5,
                  margin: "0 10px",
                  textAlign: "center",
                }}
              />
              <button
                onClick={() => handleCounterChange("add")}
                className="btn btn-sm btn-outline-primary"
              >
                +
              </button>
            </div>

            <button
              className={`btn btn-sm btn-outline-danger text-center mt-15`}
              style={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                width: isMobile || isTab ? "100%" : "",
              }}
              onClick={handleSubmit}
            >
              <i
                className="fal fa-shopping-basket"
                style={{ marginRight: 5 }}
              ></i>
              <span> Add</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuItemGrid;
