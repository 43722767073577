import { combineReducers, configureStore } from '@reduxjs/toolkit'
import appSlice from './slices/appSlice'
import orderSlice from './slices/orderSlice'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import userSlice from './slices/userSlice'
import adminSlice from './slices/adminSlice'

const rootReducer = combineReducers({
  order: orderSlice,
  app: appSlice,
  user: userSlice,
  admin: adminSlice
})
const persistConfig = {
  key: 'root',
  version: 1,
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer )

export const store = configureStore({
  reducer: persistedReducer,
})
