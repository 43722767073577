import React, { useState } from "react";
import { updateTransaction } from "../../api/admin.api";
import { useMutation } from "@tanstack/react-query";
import { formatTransactionStatusBadge, showAlert } from "../../utils/helpers";
import { Badge, Button, Card, Col, Form, Modal, Row } from "react-bootstrap";



const ViewTransactionModal = ({ show, close, transaction, updateTransactionStatus }) => {
  const [newStatus, setNewStatus] = useState(transaction.status);


  const { mutate, isLoading } = useMutation(updateTransaction, {
    onSuccess: (data) => {
      if (data.status) {
        showAlert({
          title: "Updated!",
          msg: `Transaction status has been updated`,
          btnText: "Close",
        });
        close();
      }
    },
    onError: (err) => {
      showAlert({
        title: "Error!",
        msg: `An internal error has occured`,
        icon: "error",
        btnText: "Close",
      });
    },
  });

  const handleStatusChange = (e) => {
    setNewStatus(e.target.value);
  };

  const handleSubmit = async () => {
    mutate({transactionId: transaction._id, status: newStatus });
    updateTransactionStatus(transaction._id, newStatus)
  };

  return (
    <Modal
      show={show}
      onHide={() => close(newStatus !== transaction?.status ? true : false)}
      keyboard={false}
      size={"md"}
      centered
    >
      <Modal.Header closeButton>
      </Modal.Header>
      <Form>
        <Form.Group className="mb-3" controlId="formSelect">
          <Form.Select
            aria-label="filter-duration"
            onChange={handleStatusChange}
            value={newStatus}
          >
            <option selected disabled value="">
              Status
            </option>
            <option value="reported">Reported</option>
            <option value="processing">Processing</option>
            <option value="resolved">Resolved</option>
          </Form.Select>
        </Form.Group>
      </Form>
      <Modal.Body>
        <div>
          <Button
            disabled={isLoading}
            className={`btn btn-lg btn-${formatTransactionStatusBadge(newStatus)}`}
            onClick={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
            type="submit"
          >
            {isLoading ? "Updating" : `Update`}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ViewTransactionModal;
