import React from 'react'
import { LIVE_CHAT_URL } from '../../configs/app'
import AdminLayout from '../../layouts/AdminLayout'

const LiveChat = () => {
  return (
    <AdminLayout>
            <iframe style={{width:"100%", height:"120%"}} src={LIVE_CHAT_URL}></iframe>
    </AdminLayout>
  )
}

export default LiveChat