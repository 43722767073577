import React from "react";
import { Link } from "react-router-dom";

const Sidebar = ({ outlet }) => {
  let isAgent = outlet?.username === "ecommerce" ? true : false;
  return (
    <div id="wrapper">
      {/* Sidebar */}
      <ul
        className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
        id="accordionSidebar"
      >
        {/* Sidebar - Brand */}
        <Link
          className="sidebar-brand d-flex align-items-center justify-content-center"
          to="/admin/dashboard"
        >
          <img
            src={require("../../assets/images/qsr_top_logo.png")}
            className="img-responsive"
            style={{ width: 120 }}
          />
        </Link>

        <hr className="sidebar-divider my-0" />
        {/* Nav Item - Dashboard */}
        <li className="nav-item">
          <Link className="nav-link" to="/admin/dashboard">
            <i className="fas fa-fw fa-tachometer-alt" />
            <span>Dashboard</span>
          </Link>
        </li>

        {!isAgent && (
          <>
            <hr className="sidebar-divider d-none d-md-block" />
            <div className="sidebar-heading">Ordering</div>
            <li className="nav-item">
              <Link className="nav-link" to="/admin/customer-orders">
                <i className="fas fa-fw fa-chart-area" />
                <span>Customers Orders</span>
              </Link>
              <Link className="nav-link" to="/admin/failed-transactions">
                <i className="fas fa-fw fa-exclamation-circle" />
                <span>Report failed transactions</span>
              </Link>
            </li>
          </>
        )}
        {!isAgent && (
          <>
            <hr className="sidebar-divider d-none d-md-block" />
            <div className="sidebar-heading">Product</div>
            <li className="nav-item">
              <Link className="nav-link" to="/admin/manage-products">
                <i className="fas fa-fw fa-chart-area" />
                <span>Manage Products</span>
              </Link>
            </li>
          </>
        )}

        {isAgent && (
          <>
            <hr className="sidebar-divider" />
            <div className="sidebar-heading">People</div>
            {/* Nav Item - Charts */}
            <li className="nav-item">
              <Link className="nav-link" to="/admin/customers">
                <i className="fas fa-fw fa-users" />
                <span>Customer Details</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/admin/users">
                <i className="fas fa-fw fa-users" />
                <span>Other Users</span>
              </Link>
            </li>
          </>
        )}

        {isAgent && (
          <>
            <hr className="sidebar-divider" />
            <div className="sidebar-heading">Products</div>
            {/* Nav Item - Charts */}
            <li className="nav-item">
              <Link className="nav-link" to="/admin/products">
                <i className="fas fa-fw fa-chart-area" />
                <span>All Products</span>
              </Link>
            </li>
          </>
        )}

        {isAgent && (
          <>
            <hr className="sidebar-divider" />
            <div className="sidebar-heading">Transactions</div>
            <li className="nav-item">
              <Link className="nav-link" to="/admin/failed-transactions">
                <i className="fas fa-times-circle" />
                <span>Failed Transactions</span>
              </Link>
            </li>
          </>
        )}

        {isAgent && (
          <>
            <hr className="sidebar-divider d-none d-md-block" />
            <div className="sidebar-heading">Ordering</div>
          </>
        )}

        {isAgent && (
          <>
            <li className="nav-item">
              <Link className="nav-link" to="/admin/new-order">
                <i className="fas fa-fw fa-plus" />
                <span>New Order</span>
              </Link>
            </li>
          </>
        )}

        {isAgent && (
          <>
            {" "}
            <li className="nav-item">
              <Link className="nav-link" to="/admin/all-outlet-orders">
                <i className="fas fa-fw fa-chart-area" />
                <span>Customers Orders</span>
              </Link>
            </li>
          </>
        )}

        <hr className="sidebar-divider my-0" />
        {isAgent && (
          <>
            {" "}
            <li className="nav-item">
              <Link className="nav-link" to="/admin/store-times">
                <i className="fas fa-fw fa-business-time" />
                <span>Store Times</span>
              </Link>
            </li>
          </>
        )}
        {isAgent && (
          <>
            {" "}
            <li className="nav-item">
              <Link className="nav-link" to="/admin/coupon-codes">
                <i className="fas fa-fw fa-ticket-alt" />
                <span>Coupon Codes</span>
              </Link>
            </li>
          </>
        )}
        {isAgent && (
          <>
            {" "}
            <li className="nav-item">
              <Link className="nav-link" to="/admin/live-chat">
                <i className="fas fa-fw fa-headset" />
                <span>Live Chat</span>
              </Link>
            </li>
          </>
        )}
        <li className="nav-item">
          <Link className="nav-link" to="/admin/logout">
            <i className="fas fa-fw fa-power-off" />
            <span>Logout</span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
