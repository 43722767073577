import React from 'react'
import useWindowDimensions from '../useWindowDimensions'

const FullEnglish = () => {
    let { width } = useWindowDimensions()
    let isMobile = width <= 767 ? true : false
  return (
    <section className="services_area services_space white_bg" style={{paddingTop:30}}>
    <div className="container">
      <div className="row align-items-center">
      <div className="col-lg-6">
          <div className="services_content">
            <div className="sec_title" style={{textAlign:isMobile?"center":""}}>
              <span>English Breakfast</span>
              <h2>Full English</h2>
                <ul className='bkfst'>
                    <li>Toast</li>
                    <li>Choice of Egg</li>
                    <li>Baked Beans</li>
                    <li>Sausage</li>
                </ul>
            </div>
            <div className="experience_text">
              <h1 style={{fontSize:42}}>
              ₦2,000
              </h1>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="services_img">
            <img src={require("../../assets/images/breakfast_4.png")} alt="" style={{scale:"0.8"}}/>
          </div>
        </div>
       
      </div>
    </div>
  </section>
  )
}

export default FullEnglish