import React from "react";

const RestaurantBanner = () => {
  return (
    <section
      className="offer_area offer_2 offer_img_bg pt-120 pb-120 mb-120"
      data-background="assets/img/bg/offer_bg.jpg"
      style={{backgroundImage:`url(assets/img/bg/offer_bg.jpg)`}}
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="offer_content">
              <div className="sec_title sec_title-white">
                <span>Genesis Restaurant</span>
                <h2>
                 Over 25 Outlets
                </h2>
                <p>...and counting</p>
              </div>
              <ul className="offer_btn ul_li">
                <li>
                  <a className="thm_btn" href="/restaurants">
                    See our outlets
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RestaurantBanner;
