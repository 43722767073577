import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  source_address:null,
  destination_address:null,
  selectedState: null,
  selectedOutlet: null,
  selectedCategory: null,
  deliveryType: "delivery",
  cart: [],
  coupon: null,
};

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setDeliveryType: (state, action) => {
      state.deliveryType = action.payload;
    },
    setSelectedState: (state, action) => {
      state.selectedState = action.payload;
    },
    setSelectedOutlet: (state, action) => {
      state.selectedOutlet = action.payload;
    },
    setSelectedCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
    setCart: (state, action) => {
      state.cart = action.payload;
      state.coupon = null;
    },
    // addToCart: (state, action) => {//Normal add to cart functionality
    //   state.cart.unshift(action.payload);
    //   state.coupon = null;
    // },
    addToCart: (state, action) => {
      //Promo add to cart functionality
      const product = action.payload.product;
      const freeCoke = {
        id: Math.floor(Math.random() * 100000000),
        product: {
          _id: "638a9a3cf1db7c6a6a01d8hb",
          uid: "ddfecb84-2298-5467-b15b-c2c9ca90b149",
          name: "Free Promo Coke",
          slug: "free-promo-coke",
          product_price: 0,
          sale_price: 0,
          available: true,
          category: ["all-products"],
          category_ids: ["631b1724dbc3f010460c79ef"],
          stock_count: 100,
          status: "published",
        },
        quantity: action.payload.quantity,
        total: 0,
        size: null,
      };

   
    
      // const isRamBiryani = /ram|biryani/gi.test(product.name);
      const isRamBiryani = /biryani/gi.test(product.name);
    
      if (state.selectedState.name !== "abuja" && isRamBiryani) {
        const existingFreeCokeIndex = state.cart.findIndex(
          (item) => item.product.name === "Free Promo Coke"
        );
        if (existingFreeCokeIndex !== -1) {
          state.cart[existingFreeCokeIndex].quantity += action.payload.quantity;
        } else {
          state.cart.unshift(freeCoke);
        }
      }
      // get the sum of each "total" in state.cart
      const cartTotal = state.cart.reduce((sum, item) => sum + item.total, 0);
      const newTotal = cartTotal + action.payload.total
      const appliedBlackFridayOffer = state.cart.some(item => item.hasBlackFridayOffer === true);
         //check if cart has more than 1 item and the cart totals to >= 5000
         if((state.cart.length >= 1) && (newTotal >= 5000) && !appliedBlackFridayOffer 
         && (new Date().getDay() === 15)
         ){
          //apply 25% discount to this item.
          const discountedProduct = {
            ...action.payload,
            total:  action.payload.total - ((25/100) * action.payload.total),
            hasBlackFridayOffer:true,
            baseTotal: action.payload.total,
            product:{
              ...product,
              product_price: product.product_price -  ((25/100) * product.product_price),
              basePrice:  product.product_price,
            }
          }
          state.cart.unshift(discountedProduct);
        }
        else{
          state.cart.unshift(action.payload);
        }
      state.coupon = null;
    },
    
    // removeFromCart: (state, action) => {//Normal remove functionality
    //   const removedItemId = action.payload;
    //   const removedItemIndex = state.cart.findIndex((item) => item.id === removedItemId);
    
    //   if (removedItemIndex !== -1) {
    //     const removedItem = state.cart[removedItemIndex];
    
    //     state.cart.splice(removedItemIndex, 1);
    //   }
    
    //   state.coupon = null;
    // },
    removeFromCart: (state, action) => {//Promo remove functionality
      const removedItemId = action.payload;
      const removedItemIndex = state.cart.findIndex((item) => item.id === removedItemId);
    
      if (removedItemIndex !== -1) {
        const removedItem = state.cart[removedItemIndex];
        // const isRamBiryani = /ram|biryani/gi.test(removedItem.product.name);
        const isRamBiryani = /biryani/gi.test(removedItem.product.name);
    
        if (isRamBiryani) {
          const existingFreeCokeIndex = state.cart.findIndex(
            (item) => item.product.name === "Free Promo Coke"
          );
          if (existingFreeCokeIndex !== -1) {
            state.cart[existingFreeCokeIndex].quantity -= removedItem.quantity;
            if (state.cart[existingFreeCokeIndex].quantity <= 0) {
              state.cart.splice(existingFreeCokeIndex, 1);
            }
          }
        }

           // get the sum of each "total" in state.cart
      const cartTotal = state.cart.reduce((sum, item) => sum + item.total, 0);
      const newTotal = cartTotal - removedItem.total
      const hasBlackFridayItem = state.cart.some(item => item.hasBlackFridayOffer === true);
     
    
        state.cart.splice(removedItemIndex, 1);

        if(hasBlackFridayItem && ((state.cart.length <= 2) || (newTotal < 5000))){
          const blackFridayItem = state.cart.findIndex(item => item.hasBlackFridayOffer === true);
          state.cart.splice(blackFridayItem, 1);
        }
      }
    
      state.coupon = null;
    },
    
    updateCartItem: (state, action) => {
      let currentItem = state.cart.find(
        (item) => item.id === action.payload.id
      );
      let itemPosition = state.cart.indexOf(currentItem);
      state.cart.splice(itemPosition, 1, action.payload);
      state.coupon = null;
    },
    setStoreCoupon: (state, action) => {
      state.coupon = action.payload;
    },
    clearOrder: (state) => {
      state.cart = [];
      state.coupon = null;
    },
    setSourceAddress: (state, action) => {
      state.source_address = action.payload;
    },
    setDestinationAddress: (state, action) => {
      state.destination_address = action.payload;
    },
  },
});

export const {
  setDeliveryType,
  setSelectedState,
  setSelectedOutlet,
  setSelectedCategory,
  setCart,
  addToCart,
  removeFromCart,
  updateCartItem,
  setStoreCoupon,
  clearOrder,
} = orderSlice.actions;
export default orderSlice.reducer;
