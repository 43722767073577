import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { cookieGet } from "../utils/cookie";

const ProtectedAdminRoute = ({ redirectPath = "/admin/login", children }) => {
  let history = useNavigate();
  
  const checkAuth = async () => {
    const val = await cookieGet("adminToken");
    if (!val) { return history(redirectPath); }
  };
  useEffect(() => {
    checkAuth();
  }, []);

  return children;
};

export default ProtectedAdminRoute;
