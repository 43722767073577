import { useQuery } from "@tanstack/react-query";
import React, { useState, useEffect } from "react";
import { ArrowLeft, Instagram } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getRandomProducts, getSingleProduct } from "../api/product.api";
import MenuItemGrid from "../components/WebComponents/MenuItemGrid";
import WebsiteLayout from "../layouts/WebsiteLayout";
import { addToCart } from "../store/slices/orderSlice";
import { displayAmount, makeTitleCase, showToast } from "../utils/helpers";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { Helmet } from "react-helmet";

const MenuItemDetails = () => {
  const { slug } = useParams();
  let { selectedState } = useSelector((state) => state.order);
  let history = useNavigate();
  let dispatch = useDispatch();
  const [randomProducts, setRandomProducts] = useState([]);
  let url = window.location.href;
  let {
    data: product,
    isLoading: loadingProduct,
    isError: errorProduct,
    isSuccess: successProduct,
  } = useQuery(["singleProduct", slug], getSingleProduct);
  product = product?.value;

  let { data, refetch } = useQuery(
    ["randomProducts", { state: selectedState._id }],
    getRandomProducts,
    {
      onSuccess: (data) => {
        if (data.status) {
          setRandomProducts(data.value);
        }
      },
    }
  );

  const [price, setPrice] = useState(
    !product
      ? null
      : product?.sale_price && product?.sale_price != null
      ? product?.sale_price
      : product?.product_price
  );

  const [activeVariant, setActiveVariant] = useState(
    product?.variable ? product?.varieties[0] : null
  );

  const [counter, setCounter] = useState(1);
  const handleInputChange = (e) => {
    setCounter(e.target.value);
  };
  const handleCounterChange = (type) => {
    if (type == "add") {
      setCounter((prev) => parseInt(prev) + 1);
    } else if (type == "remove") {
      setCounter((prev) => parseInt(prev) - 1);
    }
  };

  const handleVariableChange = (val) => {
    setActiveVariant(val);
    setPrice(val.price);
  };
  const handleSubmit = () => {
    let payload = {
      id: Math.floor(Math.random() * 100000000),
      product: product,
      quantity: counter,
      total: price * counter,
      size: product?.variable ? activeVariant : null,
    };
    dispatch(addToCart(payload));
    showToast(
      "success",
      `${makeTitleCase(product?.name)} has been added to cart`
    );
  };

  useEffect(() => {
    if (product) {
      setPrice(
        product?.sale_price && product?.sale_price != null
          ? product?.sale_price
          : product?.product_price
      );
      if (product?.varieties) {
        setActiveVariant(product?.varieties[0]);
      } else {
        setActiveVariant(null);
      }
    }
  }, [product]);

  useEffect(() => {
    if (slug) refetch();
  }, [slug]);

  return (
    <>
     
      <WebsiteLayout>
      <Helmet>
        <title>{`Buy ${makeTitleCase(product?.name)} at Genesis Restaurant`}</title>
        <meta
          property="og:title"
          content={`Buy ${makeTitleCase(product?.name)} at Genesis Restaurant`}
        />
        <meta
          property="og:description"
          content={`Order online and get this ${makeTitleCase(product?.name)} in a matter of minutes for just ${displayAmount(price)}`}
        />
        <meta
          property="og:image"
          content={`${product?.image_url}`}
        />
      </Helmet>
        <section className="shop_details pt-120 pb-90">
          <div className="container">
            <div className="product_top_wrap mb-60">
              <div className="row mb-5">
                <div className="col-lg-12 col-md-12">
                  <button
                    style={{ fontSize: 16 }}
                    className="btn btn-lg"
                    onClick={() => history(-1)}
                  >
                    <ArrowLeft size={16} /> Go Back
                  </button>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="product_wrap mb-30 btn-center">
                    <div className="product_details_img ">
                      <div className="pl_thumb">
                        <img src={product?.image_url} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 center-mobile">
                  <div className="details_content mb-30">
                    <h4 className="title">
                      {makeTitleCase(product?.name)}{" "}
                      {activeVariant ? `(${activeVariant.title})` : null}
                    </h4>
                    <p>{product?.description}</p>

                    <div
                      className="variant"
                      style={{ marginTop: "15px", marginBottom: "15px" }}
                    >
                      {product?.variable && (
                        <div
                          style={{
                            backgroundColor: "#e6e6e6",
                            borderRadius: 5,
                            width: "100%",
                            minHeight: 30,
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                            justifyContent: "space-evenly",
                            fontSize: 12,
                            marginTop: 15,
                            padding: "10px 0",
                          }}
                        >
                          {product?.varieties.map((cat, index) => (
                            <span
                              style={{
                                backgroundColor:
                                  activeVariant?.title === cat.title
                                    ? "#ffffff"
                                    : "#e6e6e6",
                                padding: "15px 20px",
                                borderRadius: 5,
                                cursor: "pointer",
                              }}
                              key={`fwc-${Math.floor(Math.random()*9999)}-${index}`}
                              onClick={() => handleVariableChange(cat)}
                            >
                              {makeTitleCase(cat.title)}
                            </span>
                          ))}
                        </div>
                      )}
                    </div>

                    <h4 className="price">
                      <strong>{displayAmount(price * counter, 2)}</strong>
                    </h4>

                    <ul className="btns_group ul_li btn-center">
                      <li>
                        <div className="quantity_input quantity_boxed">
                          <h4 className="quantity_title">Quantity</h4>
                          <div className="cart-plus-minus">
                            <button
                              onClick={() => handleCounterChange("remove")}
                              className=" dec qtybutton"
                              style={{ lineHeight: "unset", fontSize: 22 }}
                            >
                              -
                            </button>
                            <input
                              className="cart-plus-minus-box"
                              type="number"
                              value={counter}
                              onChange={handleInputChange}
                              style={{ fontSize: 18 }}
                            />
                            <button
                              onClick={() => handleCounterChange("add")}
                              className="inc qtybutton"
                              style={{ lineHeight: "unset", fontSize: 22 }}
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </li>
                      <li>
                        <button
                          onClick={handleSubmit}
                          className="thm_btn thm_btn-black"
                        >
                          Add To Cart
                        </button>
                      </li>
                    </ul>
                    <div className="details_share_links">
                      <h4 className="list_title">
                        <i className="fal fa-share" /> Share this product
                      </h4>
                      <div className="social_links">
                        <FacebookShareButton
                          url={url}
                          style={{ marginRight: 15 }}
                        >
                          <FacebookIcon size={30} round={true} />
                        </FacebookShareButton>
                        <TwitterShareButton
                          url={url}
                          style={{ marginRight: 15 }}
                        >
                          <TwitterIcon size={30} round={true} />
                        </TwitterShareButton>
                        <a
                          href="https://www.instagram.com/"
                          target={"_blank"}
                          style={{ marginRight: 15, background: "unset" }}
                        >
                          <Instagram size={20} style={{ color: "#e95950" }} />
                        </a>
                        <WhatsappShareButton
                          url={url}
                          style={{ marginRight: 15 }}
                        >
                          <WhatsappIcon size={30} round={true} />
                        </WhatsappShareButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="releted_product">
              <div className="sec_title sec_title-2">
                <h2>Frequently Bought Together</h2>
              </div>
              <div className="row justify-content-center">
                {randomProducts.map((product, index) => (
                  <MenuItemGrid item={product} key={`yeb-${Math.floor(Math.random()*9999)}-${index}`} />
                ))}
              </div>
            </div>
          </div>
        </section>
      </WebsiteLayout>
    </>
  );
};

export default MenuItemDetails;
