import axios from "axios";
import { API_URL } from "../configs/app";
import { setHeader } from "../utils/helpers";

export const placeOrder = async data => {
    let res = await axios.post(`${API_URL}/order/place`, data)
    return res.data
}

export const orderDetails = async ({queryKey})  => {
    let res = await axios.get(`${API_URL}/order/detail/${queryKey[1]}`)
    return res.data
}

export const customerOrders = async () => {
    let header = await setHeader();
    let res = await axios.get(`${API_URL}/order/customer`, header)
    return res.data
}

export const checkStoreStatus = async ({queryKey}) => {
    let res = await axios.get(`${API_URL}/order/store-status/${queryKey[1]}`)
    return res.data
}

export const getAddressSuggestions = async ({queryKey}) => {
    let res = await axios.get(`https://dev.virtualearth.net/REST/v1/Locations?countryRegion=NG&addressLine=${encodeURI(queryKey[1])}&includeNeighborhood=1&key=AuR9APexDAtJ5gvN50AL_kXJTBpjaLMHg0DSXTDGAqovFIqbfrxcDhAyU6YyLxqI`)
    return res.data
}
export const calcDistanceByCordinates = async (origin, destinations) => {
    let res = await axios.get(`https://dev.virtualearth.net/REST/v1/Routes/DistanceMatrix?origins=${origin}&destinations=${destinations}&travelMode=driving&distanceUnit=km&timeUnit=minute&key=AuR9APexDAtJ5gvN50AL_kXJTBpjaLMHg0DSXTDGAqovFIqbfrxcDhAyU6YyLxqI`)
    return res.data
}


export const getAddressByPoints = async (points) => {
    let res = await axios.get(`https://dev.virtualearth.net/REST/v1/Locations/${points}?key=AuR9APexDAtJ5gvN50AL_kXJTBpjaLMHg0DSXTDGAqovFIqbfrxcDhAyU6YyLxqI`)
    return res.data
}
