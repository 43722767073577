import React from 'react'
import WebsiteLayout from '../layouts/WebsiteLayout'
import About2 from "../components/HomeSections/About"
import AboutTimeline from '../components/WebComponents/AboutTimeline'
import FastDelivery from '../components/WebComponents/FastDelivery'
import RestaurantBanner from '../components/HomeSections/RestaurantBanner'
import ClientFeedback from '../components/HomeSections/ClientFeedback'

const About = () => {
  return (
    <WebsiteLayout>
        <About2/>
        <RestaurantBanner/>
        <AboutTimeline/>
        <ClientFeedback/>
        <FastDelivery/>
    </WebsiteLayout>
  )
}

export default About