import React from 'react'
import { useDispatch } from 'react-redux';
import { Minus, Plus, Trash } from 'react-feather'
import { changeCartItemCount, removeItemFromCart } from '../../store/slices/adminSlice';
import { displayAmount, makeTitleCase } from '../../utils/helpers';

const SingleCartItem = ({orderItem}) => {
    let dispatch = useDispatch();
    const deleteItem = order => {
        let conf = window.confirm("Are you sure you want to delete this item?");
        if(!conf) return;
        dispatch(removeItemFromCart(order))
    }
  return (
    <tr
    style={{
      paddingTop: 10,
      paddingBottom: 10,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    }}
  >
    <td
      className="text-danger"
      style={{ cursor: "pointer" }}
    >
      <button
        className="btn btn-sm btn-outline-danger"
        onClick={() => deleteItem(orderItem)}
      >
        <Trash size={12} />
      </button>
    </td>

    <td >
      {makeTitleCase(orderItem.product.name)} {orderItem.isVariable?`(${orderItem.product.oSize.title})`:null}
    </td>
    <td
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <button
        // disabled={orderNumber && userInfo.role < 4}
        onClick={() =>
            dispatch(changeCartItemCount({type:"decrement",id: orderItem.id}))
        }
        className="btn btn-danger btn-sm"
        style={{ cursor: "pointer" }}
      >
        <Minus size={12} />
      </button>
      <span style={{ marginLeft: 10, marginRight: 10 }}>
        {orderItem.quantity}
      </span>
      <button className="btn btn-success btn-sm"
        onClick={() => dispatch(changeCartItemCount({type:"increment", id:orderItem.id})) }
        style={{ cursor: "pointer" }}
      >
        <Plus size={12} />
      </button>
    </td>
    <td style={{}}>
      {displayAmount((orderItem.isVariable?orderItem.product.oSize.price:orderItem.product.product_price), 2)}
    </td>
    <td style={{}}>
      {displayAmount(
        (orderItem.isVariable?orderItem.product.oSize.price:orderItem.product.product_price) * orderItem.quantity,
        2
      )}
    </td>
  </tr>
  )
}

export default SingleCartItem