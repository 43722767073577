import { useMutation } from "@tanstack/react-query";
import React, { useState } from "react";
import {
  Modal,
  Row,
  Col,
  Button,
  Tabs,
  Tab,
  Form,
  Spinner,
} from "react-bootstrap";
import { X } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../api/auth.api";
import { toggleLogin, toggleResetPassword } from "../../store/slices/appSlice";
import { setUserInfo } from "../../store/slices/userSlice";
import { cookieStore } from "../../utils/cookie";
import { showAlert, showToast } from "../../utils/helpers";
import ForgotPasswordModal from "./ForgotPasswordModal";

const LoginModal = () => {
  let dispatch = useDispatch();
  const { showLoginModal } = useSelector((state) => state.app);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const { mutate: doLogin, isLoading } = useMutation(login, {
    onSuccess: async (data) => {
      // console.log(data)
      if (!data.status) {
        return showAlert({
          title: "Error",
          msg: data?.errors[0]?.msg,
          icon: "error",
          btnText: "Close",
        });
      }
      await cookieStore("authToken", data?.value?.token);
      dispatch(setUserInfo(data?.value?.thisUser));
      dispatch(toggleLogin());
      showToast("success", "Login Successful");
    },
    onError: (err) => {
      console.log(err);
      return showAlert({
        title: "Error",
        msg: "An internal error has occurred, Try again.",
        icon: "error",
        btnText: "Close",
      });
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    doLogin(formData);
  };
  return (
    <>
      <Modal
        show={showLoginModal}
        onHide={() => dispatch(toggleLogin())}
        keyboard={false}
        size={"md"}
        centered
      >
        <div
          style={{
            position: "absolute",
            right: -15,
            top: -15,
            color: "#fff",
            backgroundColor: "#1e1d23ad",
            padding: 5,
            borderRadius: "50%",
            width: 30,
            height: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
          onClick={() => dispatch(toggleLogin())}
        >
          <X size={18} />
        </div>
        <Modal.Header>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                disabled={isLoading}
                placeholder="Enter email"
                value={formData.email}
                onChange={(e) => handleChange("email", e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                disabled={isLoading}
                placeholder="Password"
                value={formData.password}
                onChange={(e) => handleChange("password", e.target.value)}
              />
            </Form.Group>

            <Button
              disabled={isLoading}
              className="float-right btn-block mb-3"
              variant="danger"
              type="submit"
            >
              {isLoading ? (
                <Spinner size="sm" animation="border" color="#fff" />
              ) : (
                "Login"
              )}
            </Button>
            <span
              className="btn btn-text btn-text-warning text-right float-right"
              onClick={() => {
                dispatch(toggleLogin());
                dispatch(toggleResetPassword());
              }}
            >
              Forgot Password?
            </span>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LoginModal;
