import React, {useState, useEffect} from 'react'
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { getProductCategories } from '../../api/app.api';
import { displayAmount, makeTitleCase, showAlert } from '../../utils/helpers';
import { createSingleProduct, updateSingleProduct } from '../../api/admin.api';

const UpdateProductModal = ({ states,show, close, product }) => {
    const [categories, setCategories] = useState([]);
    const [imagePath, setImagePath] = useState("");
    const initialState = {
        name: product.name,
        description: product.description,
        category: product.category,
        categoryIds: product.category_ids,
        price: product.product_price,
        discountPrice: product.sale_price,
        available: product.available,
        variable: product.variable,
        varieties: product.varieties,
        image: product.image_url,
        state: product.state,
        outlet_id: product.outlet_id,
      };
      const emptyState = {
        name: "",
        description: "",
        category: null,
        categoryIds: null,
        price: 0,
        discountPrice: 0,
        available:false,
        variable: false,
        varieties: [],
        image: null,
        state:null,
        outlet_id:null,
      };

  const [formdata, setFormdata] = useState(initialState);

   

  const { data, isSuccess, } = useQuery(['productCategories'], getProductCategories, {
    onSuccess: data=>{
        // console.log(data)
        if(!data.status){
            return showAlert({
                title: "Error!",
                msg:
                  typeof data.errors[0].msg == "string"
                    ? data.errors[0].msg
                    : data.errors[0].msg.message,
                icon: "error",
                btnText: "Close",
              });
        }
        setCategories(data?.value)
    },
    onError: err=> {
        // console.log(err)
        return showAlert({
            title: "Error!",
            msg: "An internal error has occured",
            icon: "error",
            btnText: "Close",
          });
    }
})

const handleChange = (field, value) => {
    if (field === "image") {
      displayImage(value)
    }
    if (field === "category") {
        let catObj = categories?.find((item) => item._id === value);
        let cat = formdata.category != null?formdata?.category?.includes(catObj.slug) ?formdata?.category?.filter(item=>item!=catObj.slug) : [catObj.slug, ...formdata?.category ]: [catObj.slug];
        let catIds = formdata.categoryIds != null?formdata.categoryIds?.includes(value) ?formdata.categoryIds?.filter(item=>item!=value) : [value, ...formdata.categoryIds]: [value];
     return setFormdata((prevState) => ({
            ...prevState,
            [field]: cat,
            categoryIds: catIds
        }));
    }
    if (field === "available") {
      return  setFormdata((prevState) => ({
            ...prevState,
            [field]: value.checked,
          }));
    }

    setFormdata((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

const displayImage = (val) => {
    var reader = new FileReader();
    reader.onload = function(){
    setImagePath(reader.result)
    };
    reader.readAsDataURL(val);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    let payload = new FormData();
    payload.append("id", product._id);
    payload.append("name", formdata.name);
    payload.append("description", formdata.description);
    payload.append("category", JSON.stringify(formdata.category));
    payload.append("category_ids", JSON.stringify(formdata.categoryIds));
    payload.append("price", formdata.price);
    payload.append("sale_price", formdata.discountPrice);
    payload.append("available", formdata.available);
    payload.append("variable", formdata.variable);
    payload.append("varieties", JSON.stringify(formdata.varieties));
    // payload.append("state", formdata.state);
    payload.append("outlet_id", formdata.outlet_id);
    payload.append("image", formdata.image);
    // console.log(formdata)
    await mutate(payload)
}

const { mutate, isLoading } = useMutation(updateSingleProduct, {
    onSuccess: data => {
        if(!data.status){
            return showAlert({
                title: "Error!",
                msg:
                  typeof data.errors[0].msg == "string"
                    ? data.errors[0].msg
                    : data.errors[0].msg.message,
                icon: "error",
                btnText: "Close",
              });
        }
        close()
        setFormdata(initialState)
        setImagePath("")
        return showAlert({
          title: "Product Created!",
          msg: `${makeTitleCase(formdata.name)} for ${displayAmount(
            formdata.discountPrice > 0 && formdata.discountPrice < formdata.price ? formdata.discountPrice : formdata.price
          )} has been update successfully`,
          icon: "success",
          btnText: "Close",
        });
    },
    onError: err => {
        return showAlert({
            title: "Error!",
            msg: "An internal error has occured",
            icon: "error",
            btnText: "Close",
          });
    }
})

  return (
    <Modal
    show={show}
    onHide={close}
    backdrop="static"
    keyboard={false}
    size={"md"}
  >
    <Modal.Header closeButton>
      <Modal.Title>Update Product</Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formBasicName">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter a name for the Product"
            onChange={(e) => handleChange("name", e.target.value)}
            value={formdata.name}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formDescription">
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="a short description about the product"
            onChange={(e) => handleChange("description", e.target.value)}
            value={formdata.description}
          />
        </Form.Group>

        <Row>
        {/* <Col>
            <Form.Group className="mb-3" controlId="formSelect">
              <Form.Label>Region</Form.Label>
              <Form.Select aria-label="Region" onChange={(e) => handleChange("state", e.target.value)} value={formdata.state}  
                style={{minHeight:100}} >
                <option disabled  value={null}>Select Region</option>
                { states?.map((item, index) => (
                  <option
                    key={`${Math.floor(Math.random() * 99999)}-${index}`}
                    value={item._id}
                  >
                    {makeTitleCase(item.name)}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col> */}
          <Col>
            <Form.Group className="mb-3" controlId="formSelect">
              <Form.Label>Category</Form.Label>
              <Form.Select
                multiple
                aria-label="Product Category"
                onChange={(e) => handleChange("category", e.target.value)}
                value={formdata.categoryIds}
                style={{minHeight:100}}
              >
              <option disabled selected value={null}>Select Category</option>
                {isSuccess && categories?.map((item, index) => (
                  item.slug === "all-products" ? null :  <option
                  key={`${Math.floor(Math.random() * 10000)}-${index}`}
                  value={item._id}
                >
                  {makeTitleCase(item.name)}
                </option>
                 
                ))}
              </Form.Select>
              <Form.Text className="text-muted">
              <small>You can select multiple categories</small>
  </Form.Text> 
            </Form.Group>
          </Col>
        
        </Row>

        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="formPrice">
              <Form.Label>Price</Form.Label>
              <Form.Control
                type="number"
                placeholder=""
                onChange={(e) => handleChange("price", e.target.value)}
                value={formdata.price}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="formDiscount">
              <Form.Label>Discounted Price</Form.Label>
              <Form.Control
                type="number"
                onChange={(e) =>
                  handleChange("discountPrice", e.target.value)
                }
                value={formdata.discountPrice}
              />
              <Form.Text className="text-muted">
              <small>Enter an amount if you are running a promo/discount, otherwise
                leave blank</small>
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col>
            <Form.Check
               type="switch"
              id="available-checkbox"
              label="Available on sale?"
              checked={ formdata.available }
              onChange={(e) => handleChange("available", e.target)}
            />
             <Form.Text className="text-muted">
    <small>Switch ON to make this product visible or OFF to hide this product</small>
  </Form.Text> 
          </Col>
        </Row>

        <Row>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label  style={{
                  // width: "auto",
                  borderStyle: "dotted",
                  borderWidth: 3,
                  borderRadius: 20,
                  height: 200,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundImage: `url(${imagePath && imagePath != "" ? imagePath : formdata.image})`,
                  backgroundPosition:"center",
                  backgroundSize:"contain",
                  backgroundRepeat:"no-repeat",
                }}>                
              {
                  formdata.image === null
                  ?<>
                  <i className="fas fa-upload"></i>
                <span style={{ marginLeft: 5 }}>
                  {" "}
                  Click to upload a product image
                </span></>
                  :null
              }
               </Form.Label>
            <Form.Control
              type="file"
              hidden
              onChange={(e) => handleChange("image", e.target.files[0])}
            />
          </Form.Group>
        </Row>
      </Form>
    </Modal.Body>

    <Modal.Footer>
      <Button variant="secondary" onClick={close}>
        Close
      </Button>
      <Button onClick={handleSubmit} type="submit" variant="primary" disabled={isLoading}>
        {isLoading?<><Spinner animation='border' size='sm' color='#fff' /> Updating...Plese Wait"</>:"Update Product"}
      </Button>
    </Modal.Footer>
  </Modal>
  )
}

export default UpdateProductModal