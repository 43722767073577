import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { store } from "./store/store";
import ScrollToTop from "./components/ScrollToTop";
import ProtectedRoute from "./components/ProtectedRoute";
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import 'moment-timezone';
import "react-datepicker/dist/react-datepicker.css";


import './App.css';
import Home from './screens/Home';
import About from './screens/About';
import Menu from './screens/Menu';
import MenuItemDetails from './screens/MenuItemDetails';
import Cart from './screens/Cart';
import Checkout from './screens/Checkout';
import Contact from './screens/Contact';
import Outlets from './screens/Outlets';
import PaymentRedirect from './screens/PaymentRedirect';
import OrderDetails from './screens/OrderDetails';
import UserOrders from './screens/UserOrders';
import UserAddress from './screens/UserAddress';
import UserProfile from './screens/UserProfile';
import BreakfastMenu from './screens/BreakfastMenu';
import Login from './screens/Admin/Login';
import Dashboard from './screens/Admin/Dashboard';
import ProtectedAdminRoute from './components/ProtectedAdminRoute';
import Logout from './screens/Admin/Logout';
import AllOutletOrders from './screens/Admin/AllOutletOrders';
import OutletOrders from './screens/Admin/OutletOrders';
import NewOrder from './screens/Admin/NewOrder';
import Products from './screens/Admin/Products';
import Customers from './screens/Admin/Customers';
import Users from './screens/Admin/Users';
import StoreTimes from './screens/Admin/StoreTimes';
import LiveChat from './screens/Admin/LiveChat';
import ResetPassword from './screens/ResetPassword';
import Coupon from './screens/Admin/Coupon';
import PrivacyPolicy from './screens/PrivacyPolicy';
import TOS from './screens/TOS';
import OurPizzas from './screens/OurPizzas';
import SquadRedirect from './screens/SquadRedirect';
import BaniRedirect from './screens/BaniRedirect';
import FailedTransactions from './screens/Admin/FailedTransactions';
import CustomerProfile from './screens/Admin/CustomerProfile';
import OutletManageProducts from './screens/Admin/OutletManageProducts';


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
}); 

let persistor = persistStore(store)


function App() {

  
  return (
    <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
    <QueryClientProvider client={queryClient}>
      <Router>
        <ScrollToTop />
        <Routes>
          
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/our-pizzas" element={<OurPizzas />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TOS/>} />
          <Route path="/restaurants" element={<Outlets />} />
          <Route path="/breakfast-menu" element={<BreakfastMenu />} />
          <Route path="/our-restaurants" element={<Outlets />} />
          <Route path="/shop-now" element={<Menu />} />
          <Route path="/shop-now/*" element={<Menu />} />
          <Route path="/order" element={<Menu />} />
          <Route path="/order/details/:oid" element={<OrderDetails />} />
          <Route path="/payment/redirect" element={<PaymentRedirect />} />
          <Route path="/squad/redirect" element={<SquadRedirect />} />
          <Route path="/bani/redirect" element={<BaniRedirect />} />
          <Route path="/menu/:category" element={<Menu/>} />
          <Route path="/product/:slug" element={<MenuItemDetails />} />
          <Route path="/pwd-rxt/:id/:otp" element={<ResetPassword />} />
          <Route path="/user/orders" element={<ProtectedRoute><UserOrders /></ProtectedRoute>} />
          <Route path="/user/addresses" element={<ProtectedRoute><UserAddress /></ProtectedRoute>} />
          <Route path="/user/profile" element={<ProtectedRoute><UserProfile /></ProtectedRoute>} />


          <Route path="/admin/login" element={<><Login /></>} />
          <Route path="/admin/logout" element={<><Logout /></>} />
          <Route path="/wp-admin" element={<><ProtectedAdminRoute><Dashboard /></ProtectedAdminRoute></>}/>
          <Route path="/wp-admin/*" element={<><ProtectedAdminRoute><Dashboard /></ProtectedAdminRoute></>}/>
          <Route path="/wp-login" element={<><ProtectedAdminRoute><Dashboard /></ProtectedAdminRoute></>}/>
          <Route path="/wp-login/*" element={<><ProtectedAdminRoute><Dashboard /></ProtectedAdminRoute></>}/>
          <Route path="/admin/dashboard" element={<><ProtectedAdminRoute><Dashboard /></ProtectedAdminRoute></>}/>
          <Route path="/admin/products" element={<><ProtectedAdminRoute><Products /></ProtectedAdminRoute></>}/>
          <Route path="/admin/all-outlet-orders" element={<><ProtectedAdminRoute><AllOutletOrders /></ProtectedAdminRoute></>}/>
          <Route path="/admin/customer-orders" element={<><ProtectedAdminRoute><OutletOrders /></ProtectedAdminRoute></>}/>
          <Route path="/admin/manage-products" element={<><ProtectedAdminRoute><OutletManageProducts /></ProtectedAdminRoute></>}/>
          <Route path="/admin/failed-transactions" element={<><ProtectedAdminRoute><FailedTransactions /></ProtectedAdminRoute></>}/>
          <Route path="/admin/new-order" element={<><ProtectedAdminRoute><NewOrder /></ProtectedAdminRoute></>}/>
          <Route path="/admin/customers" element={<><ProtectedAdminRoute><Customers /></ProtectedAdminRoute></>}/>
          <Route path="/admin/users" element={<><ProtectedAdminRoute><Users /></ProtectedAdminRoute></>}/>
          <Route path="/admin/user-profile" element={<><ProtectedAdminRoute><CustomerProfile /></ProtectedAdminRoute></>}/>
          <Route path="/admin/store-times" element={<><ProtectedAdminRoute><StoreTimes /></ProtectedAdminRoute></>}/>
          <Route path="/admin/live-chat" element={<><ProtectedAdminRoute><LiveChat /></ProtectedAdminRoute></>}/>
          <Route path="/admin/coupon-codes" element={<><ProtectedAdminRoute><Coupon /></ProtectedAdminRoute></>}/>






          {/* every other route */}
          <Route path="/*" element={<Home />} />
          </Routes>
        </Router>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
