import React from 'react'
import MenuItem from './MenuItem'
import menu from '../../breakfastmenu.json'
import { useState } from 'react'
import { useEffect } from 'react'
const Others = () => {
    const [cat, setCat] = useState("Hot Drinks")
    const [menuItems, setMenuItems] = useState([])
    useEffect(() => {
     let items = menu.find(val=>val.category === cat)
     setMenuItems(items.items)
    }, [cat])
    
  return (
   <section className="shop_masonry_area pt-60 pb-60">
  <div className="container">
    <div className="sec_title sec_title-2">
      <h2>Others</h2>
    </div>
    <div className="row">
      <div className="masonry_active text-center mb-40">
        <button onClick={()=>setCat("Hot Drinks")} className={cat === "Hot Drinks"?"active":""}>Hot Drinks</button>
        <button onClick={()=>setCat("Cold Drinks")} className={cat === "Cold Drinks"?"active":""}>Cold Drinks</button>
        <button onClick={()=>setCat("Options")} className={cat === "Options"?"active":""}>Options</button>
      </div>
    </div>
    <div className="row grid">
        {
            menuItems.map((val, key)=> <MenuItem item={val} key={`ggfd-${Math.floor(Math.random()*9999)}-${key}`} />)
}

    </div>
  

  </div>
</section>

  )
}

export default Others