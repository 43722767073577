import React from 'react'
import { Modal, Row, Col, Button, Tabs, Tab, Form, Spinner } from "react-bootstrap";
import { X } from "react-feather";
const loadingImage = require("../../assets/images/loading.gif")
const LoadingModal = () => {
  return (
    <Modal
      show={true}
      onHide={() => {}}
      keyboard={false}
      size={"sm"}
      centered
    >
      <Modal.Body style={{textAlign:"center", }}>
            {/* <Spinner size='lg' animation='border' color='primary'/> */}
            <img src={loadingImage}/>
            <h4>Please Wait</h4>
      </Modal.Body>
    </Modal>
  )
}

export default LoadingModal