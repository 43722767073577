import { useQuery } from "@tanstack/react-query";
import React, {useState} from "react";
import { Badge, Spinner } from "react-bootstrap";
import { getAllUsers } from "../../api/admin.api";
import AdminLayout from "../../layouts/AdminLayout";
import { makeTitleCase, showAlert } from "../../utils/helpers";
import moment from "moment";


const Users = () => {
    const [users, setUsers] = useState(null)
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(20);
    const {isLoading, status} = useQuery(['users', page, limit], getAllUsers, {
      onSuccess:data=>{
        if(!data.status)return showAlert({
          title: "Oops!",
          msg: typeof data.errors[0].msg == "string"
          ? data.errors[0].msg
          : data.errors[0].msg.message,
          icon: "error",
          btnText: "Close",
        });
        // console.log(data.value)
        setUsers(data?.value)
      },
      onError: err => showAlert({
        title: "Error!",
        msg: "An internal error has occurred",
        icon: "error",
        btnText: "Close",
      })
    })
  
    const handlePagination = (type) => {
      // e.preventDefault()
      if (type === "prev") {
        setPage((prev) => Math.ceil(Math.max(prev - 1, 1)));
      } else if (type === "next") {
        setPage((prev) => prev + 1);
      } else {
        setPage(type);
      }
    };
  
  return (
    <AdminLayout>
    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Other Users</h1>
      </div>

      <div className="row">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">Customers</h6>
            </div>
            <div className="card-body">
              <div className="table-responsive">
              {status === "success" && users && (
                <div className="row my-3">
                  <div className="col-sm-12 col-md-5">
                    <div
                      className="dataTables_info"
                      id="dataTable_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing {limit * (page - 1) + 1} to{" "}
                      {page * limit < users?.totalDocument
                        ? page * limit
                        : users?.totalDocument}{" "}
                      of {users?.totalDocument} customers
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-7">
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="dataTable_paginate"
                    >
                      <ul className="pagination" style={{float:"right"}}>
                        <li
                          className={`paginate_button page-item previous ${
                            page === 1 ? "disabled" : ""
                          }`}
                          id="dataTable_previous"
                        >
                          <a
                            href="#"
                            className="page-link"
                            onClick={() => handlePagination("prev")}
                          >
                            Previous
                          </a>
                        </li>
                        {[...Array(users?.totalPages)].map(
                          (val, index) =>{
                            if(!(index < (page - 5)) && !(index > (page + 5))) return (
                              <li
                                className={`paginate_button page-item  ${
                                  page == index + 1 ? "active" : ""
                                }`}
                              >
                                <a
                                  onClick={() => handlePagination(index + 1)}
                                  className="page-link"
                                >
                                  {index + 1}
                                </a>
                              </li>
                            )
                          } 
                        )}
                        <li
                          className={`paginate_button page-item next ${
                            page * limit > users?.totalDocument
                              ? "disabled"
                              : ""
                          }`}
                          id="dataTable_next"
                        >
                          <a
                            onClick={() => handlePagination("next")}
                            className="page-link"
                          >
                            Next
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
                <table className="table table-hover table-stripped table-bordered table-centered">
                <thead>
                  <tr>
                    <th>S/N</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Order Count</th>
                  </tr>
                </thead>
                <tfoot>
                  <tr>
                    <th>S/N</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Order Count</th>
                  </tr>
                </tfoot>

                    <tbody>
                    {isLoading && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: 100,
                      }}
                    >
                      <Spinner animation="border" size="sm" />{" "}
                      Loading...
                    </div>
                  )}
                   {!isLoading && users && users?.users.map((user, index)=>(
                    <tr key={Math.floor(Math.random()*9999)}>
                        <td>{(limit * (page - 1) + 1) + index }</td>
                        <td>{makeTitleCase(user?.customer?.name)}</td>
                        <td>{user?.customer?.email}</td>
                        <td>{user?.customer?.phone}</td>
                        <td></td>
                    </tr>
                   ))}
                      
                    </tbody>
                </table>
                {status === "success" && users && (
                <div className="row my-3">
                  <div className="col-sm-12 col-md-5">
                    <div
                      className="dataTables_info"
                      id="dataTable_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing {limit * (page - 1) + 1} to{" "}
                      {page * limit < users?.totalDocument
                        ? page * limit
                        : users?.totalDocument}{" "}
                      of {users?.totalDocument} customers
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-7">
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="dataTable_paginate"
                    >
                      <ul className="pagination" style={{float:"right"}}>
                        <li
                          className={`paginate_button page-item previous ${
                            page === 1 ? "disabled" : ""
                          }`}
                          id="dataTable_previous"
                        >
                          <a
                            href="#"
                            className="page-link"
                            onClick={() => handlePagination("prev")}
                          >
                            Previous
                          </a>
                        </li>
                        {[...Array(users?.totalPages)].map(
                          (val, index) =>{
                            if(!(index < (page - 5)) && !(index > (page + 5))) return (
                              <li
                                className={`paginate_button page-item  ${
                                  page == index + 1 ? "active" : ""
                                }`}
                              >
                                <a
                                  onClick={() => handlePagination(index + 1)}
                                  className="page-link"
                                >
                                  {index + 1}
                                </a>
                              </li>
                            )
                          } 
                        )}
                        <li
                          className={`paginate_button page-item next ${
                            page * limit > users?.totalDocument
                              ? "disabled"
                              : ""
                          }`}
                          id="dataTable_next"
                        >
                          <a
                            onClick={() => handlePagination("next")}
                            className="page-link"
                          >
                            Next
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
              </div>
            </div>
          </div>
        </div>

    </div>
    </AdminLayout>

  )
}

export default Users