import React, { useState } from "react";
import {
  Modal,
  Row,
  Col,
  Button,
  Tabs,
  Tab,
  Form,
  Spinner,
} from "react-bootstrap";
import { X } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toggleLogout } from "../../store/slices/appSlice";
import { setUserInfo } from "../../store/slices/userSlice";
import { cookieRemove } from "../../utils/cookie";
import { showToast } from "../../utils/helpers";

const LogoutModal = () => {
  let dispatch = useDispatch();
  let location = useLocation();
  let history = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const { showLogoutModal } = useSelector((state) => state.app);
  const handleLogout = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    await cookieRemove("authToken")
    dispatch(setUserInfo(null))
    setIsLoading(false)
    dispatch(toggleLogout())
    showToast("error", "You have been logged out")
    if(location.pathname !== "/checkout"){history("/") }
  }
  return (
    <Modal
      show={showLogoutModal}
        onHide={() => dispatch(toggleLogout())}
      keyboard={false}
      size={"sm"}
      centered
    >
      <div
        style={{
          position: "absolute",
          right: -15,
          top: -15,
          color: "#fff",
          backgroundColor: "#1e1d23ad",
          padding: 5,
          borderRadius: "50%",
          width: 30,
          height: 30,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}
          onClick={()=>dispatch(toggleLogout())}
      >
        <X size={18} />
      </div>
      <Modal.Header>
        <Modal.Title>Confirm Logout</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <Button
          disabled={isLoading}
          onClick={handleLogout}
          className="float-right btn-block btn-lg"
          variant="danger"
          type="submit"
        >
          {isLoading ? (
            <Spinner size="sm" animation="border" color="#fff" />
          ) : (
            "Logout"
          )}
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default LogoutModal;
