import React, {useState} from 'react'
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import { displayAmount } from '../../utils/helpers';

const ProductVariantModal = ({show, variants, close, setVariant}) => {
    const [selectedVariant, setSelectedVariant] = useState(null)
    const handleSetVariant = () => {
        // console.log(selectedVariant); return;
        let val = selectedVariant.split(","),
        variantObject = {
            title:val[0],
            price:Number(val[1])
        }        
        setVariant(variantObject)
        close()
    }
  return (
    <Modal
    show={show}
    onHide={close}
    keyboard={false}
    size={"md"}
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title>
        Choose Size
      </Modal.Title>
    </Modal.Header>

    <Modal.Body>
  
        {
            variants.map((val, index)=>   <div key={`default-${Math.floor(Math.random()*9999)}-${index}`} className="mb-3"><Form.Check 
            type="radio"
            name="productVariant"
            id={`njdei-${index}`}
            value={[val.title,val.price]}
            label={`${val.title} - ${displayAmount(val.price)}`}
            onChange={(e)=>setSelectedVariant(e.target.value)}
          /> 
          </div>)
        }
   
    </Modal.Body>

        <Modal.Footer>
      <Button
      onClick={handleSetVariant}
    type="submit"
      variant="primary"
      >
       Add To Cart
      </Button>
    </Modal.Footer>
        </Modal>
  )
}

export default ProductVariantModal