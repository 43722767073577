import React from "react";
import { Modal, Row, Col, Badge } from "react-bootstrap";
// const promoImage = require("../../assets/images/couponPromo.png");
const warningMsg = require("../../assets/images/merged-popup.jpg");
const CouponModal = ({ show, close }) => {
  return (
    <Modal show={show} onHide={close} keyboard={false} centered size={"lg"}>
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <img src={warningMsg} />
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default CouponModal;
