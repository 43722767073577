import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeFromCart, updateCartItem } from "../../store/slices/orderSlice";
import { displayAmount, makeTitleCase } from "../../utils/helpers";

const CartItem = ({ item, }) => {
  let dispatch = useDispatch();
  const { cart } = useSelector((state) => state.order);
  //get Current cart item
  let currentItem = cart.find((data) => data.id === item.id);
  const basePrice = item.product.basePrice
  const price = item.size ? item?.size?.price : !currentItem
    ? null
    : currentItem.product.sale_price && currentItem.product.sale_price != null
      ? currentItem.product.sale_price
      : currentItem.product.product_price;

  const handleCounterChange = (type) => {
    let payload;
    if (type == "add") {
      payload = {
        ...currentItem,
        quantity: currentItem.quantity + 1,
        total: price * (currentItem.quantity + 1)
      };
    } else if (type == "remove") {
      payload = {
        ...currentItem,
        quantity: currentItem.quantity <= 1 ? 1 : currentItem.quantity - 1,
        total: price * (currentItem.quantity <= 1 ? 1 : currentItem.quantity - 1)
      };
    }
    dispatch(updateCartItem(payload));
  };
  return (
    <tr>
      <td>
        <div className="carttable_product_item">
          <div className="item_image">
            <div
              style={{
                backgroundImage: `url(${item.product.image_url})`,
                width: 120,
                height: 120,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "contain",
              }}
            ></div>
          </div>
          <button
            type="button"
            className="remove_btn"
            onClick={() => dispatch(removeFromCart(item.id))}
          >
            <i className="fal fa-times" />
          </button>
          <h3 className="item_title">{makeTitleCase(item.product.name)}  {item?.size ? `(${item?.size?.title})` : null}</h3>
        </div>
      </td>
      <td>
        <div className="price_text1">{item.hasBlackFridayOffer && <span style={{ color: "#726f6f" }} className="price_text1 mr-1"><del>{displayAmount(basePrice, 2)}</del></span>}{displayAmount(price, 2)}</div>
        {item.hasBlackFridayOffer && <span className="badge badge-dark" style={{ fontSize: 10, }}>25% Black Friday</span>}
      </td>
      <td>
        <div className="cart-plus-minus">
          <div
            className="dec qtybutton"
            onClick={() => handleCounterChange("remove")}
            style={{ fontSize: 20 }}
          >
            -
          </div>
          <input
            className="cart-plus-minus-box"
            type="text"
            name="qtybutton"
            defaultValue={1}
            disabled
            value={currentItem.quantity}
          />
          <div
            className="inc qtybutton"
            onClick={() => handleCounterChange("add")}
            style={{ fontSize: 20 }}
          >
            +
          </div>
        </div>
      </td>
      <td>
        <div className="price_text2">
        {item.hasBlackFridayOffer && <span style={{ color: "#726f6f" }} className="price_text2 mr-1"><del>{displayAmount(basePrice * currentItem.quantity, 2)}</del></span>}
          {displayAmount(price * currentItem.quantity, 2)}
        </div>
        {item.hasBlackFridayOffer && <span className="badge badge-dark" style={{ fontSize: 10, }}>25% Black Friday</span>}
      </td>
    </tr>
  );
};

export default CartItem;
