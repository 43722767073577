import React from 'react'
import WebsiteLayout from '../layouts/WebsiteLayout'

const PrivacyPolicy = () => {
  return (
    <WebsiteLayout>

<section className="copntact_area pt-120 pb-120">
        <div className="container">
          <div className="contact_wrap">


            <div className="row mb-10 justify-content-md-center">
              <div className="col-lg-12 col-md-12 text-center">
                <div className="contact_info mb-40 text-center justify-center" style={{textAlign:"center"}}>
                 <h2> Privacy Policy for Genesis Restaurant</h2>
                </div>
              </div>
            </div>

            <div className="row">
            <h3>Introduction</h3>
<p>
At Genesis Restaurant, we are committed to protecting the privacy of our customers and ensuring that their personal information is treated with the highest level of security and confidentiality. This Privacy Policy outlines how we collect, use, store and share information obtained from our website and mobile application, and explains how we strive to keep our customers’ personal information protected.
</p>

<h3>Information Collection</h3>
<p>
When you use our website or mobile application to place an order, we may collect information such as your name, address, phone number, email address, payment information, and order details. This information is necessary to process your order, communicate with you regarding your order and provide you with the best possible customer service.
</p>

<p>

We may also collect information such as your IP address, browser type, and device type to help us better understand how our customers use our website and mobile application and to improve the overall user experience.
</p>
<h3>Information Use</h3>

The information collected by Genesis Restaurant is used for the following purposes:
<ul>
 <li>To process and fulfill your order</li>
    <li>To communicate with you regarding your order and provide customer service</li>
    <li>To send promotional offers and news updates (if you have opted-in to receive such communications)</li>
    <li>To improve our website and mobile application, and to better understand how our customers use our services</li>
    <li>To detect, prevent and address technical issues</li>
</ul>

<h3>Information Sharing</h3>
<p>
We do not sell or rent personal information to third parties. We may share your personal information with third-party service providers to assist us in processing and fulfilling your order, such as delivery providers and payment processors. These service providers are subject to strict confidentiality obligations and are only permitted to use your personal information as necessary to perform their services for us.
</p>


<h3>Security</h3>
<p>
At Genesis Restaurant, we take the protection of your personal information very seriously. We have implemented appropriate security measures to protect your personal information from unauthorized access, use, disclosure, alteration or destruction. These measures include the use of secure servers, firewalls, and encryption technologies.
</p>

<h3>Access and Correction of Personal Information</h3>
<p>
You have the right to access and correct any personal information we hold about you. To request access to your personal information, please contact us at info@genesisrestaurant.com.ng.
</p>
<h3>Changes to This Privacy Policy</h3>
<p>
We may update this Privacy Policy from time to time to reflect changes to our information practices. If we make any material changes, we will notify you by email (sent to the email address specified in your account) or by means of a notice on this website prior to the change becoming effective.
</p>

<h3>Contact Information</h3>
<p>
If you have any questions or concerns regarding this Privacy Policy, please contact us at info@genesisrestaurant.com.ng.
</p>
<strong>This Privacy Policy was last updated on 12th February 2023.</strong>
            </div>

            </div>
            </div>
            </section>
             </WebsiteLayout>
  )
}

export default PrivacyPolicy