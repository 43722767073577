import { useMutation, useQuery } from '@tanstack/react-query'
import React, {useState} from 'react'
import { Button, Form, Spinner } from 'react-bootstrap'
import { getOutlets, updateStoretimes } from '../../api/admin.api'
import AdminLayout from '../../layouts/AdminLayout'
import { makeTitleCase, showAlert } from '../../utils/helpers'

const StoreTimes = () => {
    const [formdata, setFormdata] = useState([])
    const [hasChanges, setHasChanges] = useState(false)
    const {isLoading:loadingStores, status:getStoreStatus} = useQuery(['stores'], getOutlets, {
        onSuccess: data => {
            if (!data?.status) {
                return showAlert({
                    title: "Oops!",
                    msg: typeof data.errors[0].msg == "string"
                    ? data.errors[0].msg
                    : data.errors[0].msg.message,
                    icon: "error",
                    btnText: "Close",
                  });
              }
            //   console.log(data.value)
              setFormdata(data?.value)

        },
        onError: err => showAlert({
            title: "Error!",
            msg: "An internal error has occurred",
            icon: "error",
            btnText: "Close",
          })
    })

    const {mutate, status, isLoading:isSubmitting} = useMutation(updateStoretimes, {
        onSuccess: data => {
                console.log(data)
                if (!data?.status) {
                    return showAlert({
                        title: "Oops!",
                        msg: typeof data.errors[0].msg == "string"
                        ? data.errors[0].msg
                        : data.errors[0].msg.message,
                        icon: "error",
                        btnText: "Close",
                      });
                  }
                  showAlert({
                    title: "Updated",
                    msg: "Store status have been updated ",
                    icon: "success",
                    btnText: "Close",
                  })
        },
        onError: err => showAlert({
            title: "Error!",
            msg: "An internal error has occurred",
            icon: "error",
            btnText: "Close",
          })
    })

    const handleSubmit = (e) => {
e.preventDefault();
// console.log(formdata)
mutate(formdata)
setHasChanges(false)
    }
    const handleChange = (id, val)=> {
        let otherOutlet = formdata.filter(item=>item._id!==id)
        let outlet = formdata.find(item=>item._id===id);
        outlet = {
            ...outlet,
            closed: !val
        }
        setHasChanges(true)
       return  setFormdata([outlet, ...otherOutlet])
    }

    // const handleChange = (id, val)=> {
    //     // let otherOutlet = formdata.filter(item=>item._id!==id)
    //     let fd = formdata
    //     let outlet = formdata.find(item=>item._id===id);
    //     let itemPosition = formdata.indexOf(outlet)
    //     outlet = {  ...outlet, closed: !val}
    //     fd.splice(itemPosition,1, outlet)
    //     setHasChanges(true)
    //    return  setFormdata(fd)   
    // }
    
  return (
    <AdminLayout>
          <div className="container-fluid">
          <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 className="h3 mb-0 text-gray-800">Open/Close Stores</h1>
            <Button onClick={handleSubmit} variant='primary' size='md' disabled={!hasChanges}>{isSubmitting?"Saving...":"Save Changes"}</Button>
          </div>

          <div className="card shadow mb-4">
          <div className="card-body">

          {loadingStores && <Spinner animation='border' size='lg'/>}

                <div className="table-responsive">
          {!loadingStores && getStoreStatus && formdata.length > 0 && 
           <table
           className="table table-bordered table-stripped table-hover text-center"
           id="dataTable"
           width="100%"
           cellSpacing={0}
         >
           <thead>
             <tr>
               <th>S/N</th>
               <th>Outlet Name</th>
               <th>Status</th>
             </tr>
           </thead>
           <tfoot>
             <tr>
                <th>S/N</th>
               <th>Outlet Name</th>
               <th>Status</th>
             </tr>
           </tfoot>
           <tbody>
            { formdata.map((store, index)=><tr key={Math.floor(Math.random()*99999)}>
                <td>{index + 1}</td>
                <td>{makeTitleCase(store.name)}</td>
                <td>
                <Form.Check
                style={{fontSize:22}}
                 type="switch"
                id="available-checkbox"
                checked={ !store.closed }
                onChange={(e) => {e.preventDefault(); handleChange(store._id, e.target.checked)}}
              />
                </td>
            </tr>) }
           </tbody>
         </table>
          }
          </div>

          </div>
          </div>


          </div>
    </AdminLayout>
  )
}

export default StoreTimes