import { useMutation } from "@tanstack/react-query";
import React, {useState} from "react";
import { Spinner } from "react-bootstrap";
import { ArrowLeft } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { checkCouponCode } from "../api/admin.api";
import CartItem from "../components/WebComponents/CartItem";
import WebsiteLayout from "../layouts/WebsiteLayout";
import { setCart, setStoreCoupon } from "../store/slices/orderSlice";
import { displayAmount, showAlert, showToast } from "../utils/helpers";

const Cart = () => {
  const [coupon, setCoupon] = useState("")
  // const [couponApplied, setCouponApplied] = useState(false)
  let dispatch = useDispatch();
  let history = useNavigate();
  let { cart, deliveryType, selectedOutlet, coupon:couponApplied, selectedState } = useSelector(
    (state) => state.order
  );
  const hasPromo = cart.find(item=>item?.product?.is_promo === true)
 
  const { outlets } = useSelector((state) => state.app);
  const cartTotal = cart.reduce((n, { total }) => n + total, 0);
  const clearCart = (e) => {
    e.preventDefault();
    dispatch(setCart([]));
    showToast("success", "Cart has been cleared");
  };
  selectedOutlet = outlets?.find((item) => item._id === selectedOutlet);
  let deliveryFee = selectedOutlet?.delivering_to[0].amount;

  const { mutate, isLoading } = useMutation(checkCouponCode, {
    onSuccess: data => {
      console.log(data)
      if(!data.status && data?.errors) return showAlert({
         title:"Oops!", msg:data?.errors[0].msg, icon:"error", btnText:"close" 
      })
      showAlert({
        title:"Congratulations!", msg:"Coupon was applied successfully", icon:"success", btnText:"Continue" 
     })
     dispatch(setStoreCoupon(data?.value))

    },
    onError: err => showAlert({
      title:"Error!", msg:"An internal error has occured", icon:"error", btnText:"close" 
   })
  })

 

 const handleCouponCheck = (e) => {
  e.preventDefault();
  console.log(coupon)
  mutate({coupon, totalAmount:cartTotal})
 }
  return (
    <WebsiteLayout>
      <section className="cart_area pt-120 pb-120">
        <div className="container">
          <div className="row mb-5">
            <div className="col-lg-12 col-md-12">
              <button style={{fontSize:16}}  className="btn btn-lg" onClick={() => history(-1)}>
                <ArrowLeft size={16} /> Go Back
              </button>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="cart_table table-responsive">
                <table className="table">
                  <thead className="text-uppercase">
                    <tr>
                      <th>Product</th>
                      <th>Price</th>
                      <th>Quantity</th>
                      <th>subtotal</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cart.map((item, index) => (
                      <CartItem key={`feb-${Math.floor(Math.random()*9999)}-${index}`} item={item} />
                    ))}
                  </tbody>
                </table>
              </div>
              
              <div className="cart_btn_wrap ul_li">
              {hasPromo && selectedState?.name == "abuja" && <p style={{marginTop:15}}>Coupon is disabled because one or more item(s) in your cart is already discounted</p>
}
                {/* {!couponApplied && !hasPromo && selectedState?.name == "abuja" && */}
                <form className="cupon_btn" onSubmit={handleCouponCheck} style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                  <input type="text" style={{width:160}} placeholder="Coupon code" value={coupon} onChange={(e)=>setCoupon(e.target.value)} />
                  <button type="submit" className="thm_btn thm_btn-white">
                   {isLoading?<Spinner size="sm" variant="danger" animation="border" />: "Apply coupon"} 
                  </button>
                </form>
                {/* } */}
                <div className="update_cart ul_li">
                  <a
                    className="thm_btn thm_btn-white"
                    href="#"
                    onClick={clearCart}
                  >
                    clear cart
                  </a>
                  <Link className="thm_btn thm_btn-white" to="/menu/all">
                    Buy More Items
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="row pt-60">
            <div className="col-xl-5 offset-xl-7 col-lg-8 offset-lg-4">
              <div className="cart_tutal_wrap">
                <h3 className="title">Cart Totals</h3>
                <ul>
                  <li>
                    Subtotal <span>{displayAmount(cartTotal, 2)}</span>
                  </li>
                  {couponApplied && (
                    <li style={{ backgroundColor: "#cdffd44d", color:"darkgreen" }}>
                      Coupon Discount <span>- {displayAmount(couponApplied?.discountedAmount, 2)}</span>
                    </li>
                  )}
                  {deliveryType === "delivery" && (
                    <li style={{ color: "#6f7987" }}>
                      Delivery Fee <span>{displayAmount(deliveryFee, 2)}</span>
                    </li>
                  )}

                  <li style={{ fontWeight: 600 }}>
                    Total{" "}
                    <span>
                      {deliveryType === "delivery"
                        ?couponApplied? displayAmount((cartTotal - couponApplied?.discountedAmount) + deliveryFee, 2) :displayAmount(cartTotal + deliveryFee, 2)
                        :couponApplied? displayAmount((cartTotal - couponApplied?.discountedAmount), 2) :displayAmount(cartTotal, 2)}
                    </span>
                  </li>
                </ul>
                <Link to="/checkout" className="thm_btn thm_btn-black">
                  Proceed to checkout
                </Link>
              </div>
            </div>
          </div>

        </div>
      </section>
    </WebsiteLayout>
  );
};

export default Cart;
