import React from "react";
import WebsiteLayout from "../layouts/WebsiteLayout";

const Contact = () => {
  return (
    <WebsiteLayout>
      <section className="copntact_area pt-120 pb-120">
        <div className="container">
          <div className="contact_wrap">
            <div className="row mb-10 justify-content-md-center">
              <div className="col-lg-4 col-md-6">
                <div className="contact_info mb-40">
                  <div className="contact_icon">
                    <i className="fal fa-envelope-open-text" />
                  </div>
                  <div className="contact_text">
                    <h4>Email Address</h4>
                    <p>ecommerce.qsr@genesisgroupng.com</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="contact_info mb-40">
                  <div className="contact_icon">
                    <i className="fal fa-phone" />
                  </div>
                  <div className="contact_text">
                    <h4>Phone Numbers</h4>
                    <p>+2348050499249</p>
                    <p>+2348052198415</p>
                  </div>
                </div>
              </div>
            
            </div>
            <div className="row">
              <form className="contact_form" action="#">
                <div className="row">
                  <div className="col-lg-6">
                    <input type="text" name="fname" placeholder="Your Name" />
                  </div>
                  <div className="col-lg-6">
                    <input
                      type="email"
                      name="email"
                      placeholder="Email Address"
                    />
                  </div>
                  <div className="col-12">
                    <input
                      type="text"
                      name="subject"
                      placeholder="Enter Your Subject :"
                    />
                  </div>
                  <div className="col-12">
                    <textarea
                      name="message"
                      id="message"
                      cols={30}
                      rows={10}
                      placeholder="Your Message :"
                      defaultValue={""}
                    />
                  </div>
                  <div className="col-12">
                    <div className="contact_btn text-center">
                      <a className="thm_btn thm_btn-black" href="#">
                        Send Message
                      </a>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </WebsiteLayout>
  );
};

export default Contact;
