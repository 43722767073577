import React, { useState, useEffect } from "react";
import HomeSection1 from "../components/HomeSections/HomeSection1";
import WebsiteLayout from "../layouts/WebsiteLayout";
import FastDelivery from "../components/WebComponents/FastDelivery";
import HomeModal from "../components/Modals/CouponModal";
import ClientFeedback from "../components/HomeSections/ClientFeedback";
import MobileApp from "../components/HomeSections/MobileApp";
const ad1 = require("../assets/images/ad1.jpg");
const ad2 = require("../assets/images/ad2.jpg");
const ad3 = require("../assets/images/ad3.jpg");
const ad4 = require("../assets/images/ad4.jpg");

const Home = () => {
  const [showModal, setShowModal] = useState(true);

  return (
    <WebsiteLayout>
      <>
        <HomeSection1 />

        {/* <About/> */}
        {/* <PopularDishes/> */}
        {/* <SpecialOffers/> */}
        {/* <Products/> */}
        {/* <RestaurantBanner/> */}
        {/* <ReactOwlCarousel className="" items={1} dots={true} loop autoplay autoplayHoverPause responsive={{
        0:{items:1, }, 600:{ items:2, },1000:{ items:3,}
    }}>
          <img src={ad1} />
          <img src={ad3} />
          <img src={ad2} />
          <img src={ad4} />
          </ReactOwlCarousel>      */}

        <FastDelivery />
        <a href="http://thegenesisvibe.com" target="_blank" style={{display:"block"}}><div
          className="footer_top pt-20"
          style={{
            background: "#e30000",
            height: 180,
            backgroundImage: "url('assets/img/bg/sharethevibe.webp')",
            backgroundPosition:"center",
            backgroundSize:"contain",
            // backgroundRepeat:"no-repeat"
          }}
        ></div></a>
        <MobileApp />
        {/* <ClientFeedback/> */}
        {/* <BlogSlide/> */}
        {/* <InitialOrderChoice/> */}
      </>
      <HomeModal show={showModal} close={() => setShowModal(false)} />
    </WebsiteLayout>
  );
};

export default Home;
