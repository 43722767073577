import React from 'react'
import { Spinner } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import useWindowDimensions from '../useWindowDimensions'

const MenuItemSkeleton = () => {
  let {width} = useWindowDimensions()
  let isMobile = width <= 767 ? true : false
  return (
    <div className="col-lg-3 col-md-4 col-sm-6 grid-item mb-30" style={{width:isMobile?"50%":""}}>
    <div className="shop_single white_bg">
      <div className="thumb text-center">
      <Spinner animation="border"/>
      </div>
      <div className="content text-center">
      </div>
    </div>
  </div>
  )
}

export default MenuItemSkeleton