import React, { useEffect, useState } from 'react'
import AdminLayout from '../../layouts/AdminLayout'
import { useSearchParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { fetchCustomerPurchaseHistory } from '../../api/admin.api';
import { displayAmount, formatStatus, formatStatusBadge, makeTitleCase, showAlert } from '../../utils/helpers';
import { Badge, Button, Spinner } from 'react-bootstrap';
import moment from 'moment';
import ViewOrderModal from '../../components/Modals/ViewOrderModal';

const CustomerProfile = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const phone = searchParams.get("phone")
    const [orders, setOrders] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [showViewModal, setShowViewModal] = useState(false);
    const [userBio, setUserBio] = useState({
        name: "",
        phone: "",
        email: ""
    })

    const { mutate: getPurchaseHistory, isLoading: isGettingOrders } = useMutation(fetchCustomerPurchaseHistory, {
        onError: (error) => {
            showAlert({
                title: "Error!",
                msg: "An internal error has occurred",
                icon: "error",
                btnText: "Close",
            });
        },
        onSuccess: data => {
            console.log(data)
            if (!data.status) {
                return showAlert({
                    title: "Error!",
                    msg: "Unable to get customer information",
                    icon: "error",
                    btnText: "Close",
                });
            }
            setUserBio({
                name: data?.value[0]?.customer?.name,
                phone: data?.value[0]?.customer?.phone,
                email: data?.value[0]?.customer?.email,
            })
            setOrders(data?.value);
        },
    })

    useEffect(() => {
        if (phone) {
            getPurchaseHistory(phone)
        }
    }, [])

    const handleView = (order) => {
        setSelectedOrder(order);
        setShowViewModal(true);
      };
      const handleClose = (type) => {
        switch (type) {
          case "view":
            setShowViewModal(false);
            setSelectedOrder(null);
            break;
          default:
            return;
        }
    
      };
    return (<>
        <AdminLayout>
            <div className="container-xl px-4 mt-4">
                <div className="row">
                    <div className="col-xl-4">
                        {/* Profile picture card*/}
                        <div className="card mb-4 mb-xl-0">
                            <div className="card-body text-center">
                                <img style={{ width: 150 }} className="img-account-profile rounded-circle mb-2" src="https://sb-admin-pro.startbootstrap.com/assets/img/illustrations/profiles/profile-1.png" alt="Profile Picture" />
                                <div className="h3">{makeTitleCase(userBio.name)}</div>
                                <div className="small">{userBio?.email}</div>
                                <div className="small mb-3">{userBio?.phone}</div>

                            </div>
                        </div>
                    </div>
                    <div className="col-xl-8">
                        <div className="card mb-4">
                            <div className="card-header">Order History ({orders.length || 0})</div>
                            <div className="card-body p-0">
                                {/* Billing history table*/}
                                <div className="table-responsive table-billing-history">
                                    <table className="table mb-0">
                                        <thead>
                                            <tr>
                                                <th className="border-gray-200" scope="col">Order#</th>
                                                <th className="border-gray-200" scope="col">Items</th>
                                                <th className="border-gray-200" scope="col">Amount</th>
                                                <th className="border-gray-200" scope="col">Status</th>
                                                <th className="border-gray-200" scope="col">Date</th>
                                                <th className="border-gray-200" scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {isGettingOrders && (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        height: 100,
                                                    }}
                                                >
                                                    <Spinner animation="border" size="sm" />{" "}
                                                    Loading...
                                                </div>
                                            )}

                                            {!isGettingOrders && orders.length > 0 && (
                                                <>
                                                    {orders.map((order, index) => (
                                                        <tr
                                                            key={`${Math.floor(Math.random() * 10000)}-${index}`}
                                                        >
                                                            <td>{order?.orderNumber}</td>
                                                            <td>
                                                                {order?.items.length}{" "}
                                                                {order?.items.length > 1 ? "items" : "item"}
                                                            </td>
                                                            <td>
                                                                <strong>{displayAmount(order?.sumTotal)}</strong>
                                                            </td>

                                                            <td>
                                                                <Badge
                                                                    bg={formatStatusBadge(order?.orderStatus)}
                                                                >
                                                                    {formatStatus(order?.orderStatus)}
                                                                </Badge>
                                                            </td>
                                                            <td>
                                                                {moment(order?.created).format(
                                                                    "MMM Do YYYY, h:mm A"
                                                                )}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    display: "flex",
                                                                    aligItems: "center",
                                                                    justifyContent: "space-between",
                                                                }}
                                                            >
                                                                <Button
                                                                    size="sm"
                                                                    onClick={() => handleView(order)}
                                                                    variant="outline-primary"
                                                                >
                                                                    <i className="fas fa-eye"></i>
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </>
                                            )}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </AdminLayout>
        {selectedOrder !== null && (
        <ViewOrderModal
          order={selectedOrder}
          show={showViewModal}
          close={() => handleClose("view")}
        />
      )}
    </>)
}

export default CustomerProfile
