import React from "react";
import { Link } from "react-router-dom";
import { logo_dark } from "../../utils/assets";

const apple_logo = require("../../assets/images/apple_logo.webp")
// const apple_logo = require("../../assets/img/icon/app_store.png")

const android_logo = require("../../assets/images/android_logo.webp")
// const android_logo = require("../../assets/img/icon/play_store.png")


const Footer = () => {
  return (
    <>
    
    <footer className="footer-area footer_bg" style={{background:"#fbf2f2"}}>
      <div className="footer_top pt-20" style={{background:"#e30000"}}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-6">
            <div className="sec_title">
                  <span style={{color:"#fff"}}>Download our App</span>
                  <h2 style={{color:"#fff", fontSize:38}}>
                  We'll bring your food to you
                  </h2>
                </div>
            </div>
            <div className="col-lg-5 col-md-6" style={{textAlign:"center"}}>
             <a href="https://play.google.com/store/apps/details?id=com.genesis_restaurant" target="_blank"><img src={android_logo} className="m-3" /></a>
             <a href="https://apps.apple.com/us/app/genesis-restaurant/id1671403114" target="_blank">
               <img src={apple_logo} className="m-3" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <a href="http://thegenesisvibe.com" target="_blank" style={{display:"block"}}>
        <div
          className="footer_top pt-20"
          style={{
            background: "#e30000",
            height: 180,
            backgroundImage: "url('assets/img/bg/sharethevibe.webp')",
            backgroundPosition:"center",
            backgroundSize:"contain",
            // backgroundRepeat:"no-repeat"
          }}
        ></div></a>
      
      {/* <ins className="adsbygoogle"
style={{display:"block"}}
data-ad-format="fluid"
data-ad-layout-key="-6t+ed+2i-1n-4w"
data-ad-client="ca-pub-9994167528444366"
data-ad-slot="7343887912"></ins> */}


      <div className="footer_main pt-100 pb-50">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="footer_widget">
                <img src={logo_dark}/>
                {/* <p> We serve great meals </p> */}
                <div className="open_hour_wrap ul_li">
                  <div className="icon">
                    <img src="assets/img/icon/t_hour.png" alt="" />
                  </div>
                  <div className="or_text">
                    <h4 style={{color:"#000"}} >Opening HOurs</h4>
                    <span style={{color:"#000"}} >Mon - Sat (8:30AM - 5:30PM)</span>
                    <span style={{color:"#000"}} >Sundays - (11:00AM - 5:30PM)</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="footer_widget">
                <h3 style={{color:"#000"}} >Contact us</h3>
                <ul className="c_wrap">
                  <li>
                    <span style={{color:"#000"}} >Lagos</span> |  <span style={{color:"#000"}} >Abuja</span> | <span style={{color:"#000"}} >Enugu</span>  | <span style={{color:"#000"}} >Warri</span><br/>
                    <span style={{color:"#000"}} >Port Harcourt</span> | <span style={{color:"#000"}} >Owerri</span> 
                  </li>
                  <li style={{color:"#000"}} >
                    MAIL: ecommerce.qsr@genesisgroupng.com
                  </li>
                  <li style={{color:"#000"}} >
                   PHONE: +2348050499249,  +2348052198415
                  </li>
                </ul>
              </div>
            </div>
        
            <div className="col-lg-4 col-md-6">
              <div className="footer_widget widget_blog">
                <h3 style={{color:"#000"}} >Help &amp; Support</h3>
                <ul className="c_wrap">
                  <li style={{marginBottom:10}}><Link to="/about" style={{color:"#000"}}>About Us</Link></li>
                 <li style={{marginBottom:10}}>  <Link to="/restaurants" style={{color:"#000"}}>Find A Store </Link></li>
                  <li style={{marginBottom:10}} ><Link to="/feedback" style={{color:"#000"}}> Share Your Feedback</Link> </li>
                  <li style={{marginBottom:10}} ><Link to="/" style={{color:"#000"}}>Outdoor Catering</Link> </li>
                  <li style={{marginBottom:10}} ><Link to="/" style={{color:"#000"}}>News &amp; Blog</Link> </li>
                  <li style={{marginBottom:10}} ><Link to="/admin/login" style={{color:"#000"}}>Admin</Link> </li>
                </ul>

              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="footer_bottom">
        <div className="container">
          <div className="copyright_wrap">
            <div className="row align-items-center">
              <div className="col-lg-3 col-md-3 copyright text-md-start">
                <p> 
                  <Link to="/terms-of-service" style={{color:"#000"}}>  Terms of Service </Link>{" "}
                 | {" "} <Link to="/privacy-policy" style={{color:"#000"}}> Privacy Policy </Link>
                </p>
              </div>
              <div className="col-lg-6 col-md-9">
                <div className="copyright text-md-center">
                  <p style={{color:"#000"}}>&copy; Copyright {new Date().getFullYear()} Genesis Foods Nigeria Limited</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-12">
                <div className="social_links text-lg-end">
                  <a href="https://www.facebook.com/GenesisQSR" target="_blank">
                    <i className="fab fa-facebook-f" />
                  </a>
                  <a href="https://www.instagram.com/genesisrestaurantng/" target="_blank">
                    <i className="fab fa-instagram" />
                  </a>
               
                  <a href="https://wa.me/2348050499249?text=I'm%20coming%20from%20your%20website.%20I%20want%20to%20order%20food." target="_blank">
                    <i className="fab fa-whatsapp" />
                  </a>

                  <a href="https://twitter.com/genesisqsr" target="_blank">
                    <i className="fab fa-twitter" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </>);
};

export default Footer;
